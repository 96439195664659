import { UserPermissions } from './models/user-permissions.model';
import { Injectable } from '@angular/core';
import { timer ,  Observable } from 'rxjs';
import { Notification } from './models/notification.model';
import { NotificationService } from './common/services/notification.service';
import { config } from '../config/pages-config';
import { ActivatedRoute, Router } from '@angular/router';
import { FIFTEENMIN } from './common/constants/defines';
import { TranslateService } from '@ngx-translate/core';




@Injectable()
export class AppService {
    userPermissions: Array<UserPermissions>;
    constructor(
        private notificationService: NotificationService,
        private router: Router,
        private translateService: TranslateService,
    ) {

    }

    public loginExpiredModal(): void {
        const time: Observable<number> = timer(FIFTEENMIN, FIFTEENMIN);
        time.subscribe(x => {
            const notification: Notification = new Notification();
            this.translateService.get('Renew-Token').subscribe(data => {
            notification.bodyContent = data.message;
            notification.primaryButtonText = data.buttonText;
            })
            notification.primaryButtonStyle = 'btn registration';
            notification.primaryButtonClick = ()=> {
            this.notificationService.notificationModal.hide();
            this.router.navigate([config.login.name])
            };
            this.notificationService.createNotification(notification);
        })   
    }
}
