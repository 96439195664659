import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { API_URLS } from '../constants/routes-config';

@Injectable()
export class WalletInfoCSVService {

  constructor(private http: HttpClient) { }

  /**Call backend api to export wallet info to CSV and download it */
  exportToCSV(startSaveDate?: string, endSaveDate?: string, startExpiryDate?: string, endExpiryDate?: string): void {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Accept', 'application/x-ms-excel');
    const apiPath: string = `${API_URLS.AnyDownload.BASE_PATH}${API_URLS.ExportWalletInfoInCSV.DOWNLOAD_CSV}`
      .replace('{0}', startSaveDate).replace('{1}', endSaveDate).replace('{2}', startExpiryDate).replace('{3}', endExpiryDate);
    this.http.get(apiPath, {
      headers: headers,
      responseType: 'blob' as 'json',
      observe: 'response'
    }).subscribe((response: HttpResponse<Blob>) => {
      const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
      const blob: Blob = new Blob([response.body], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const objectUrl: string = URL.createObjectURL(blob);
      const contentHeader: string = response.headers.get('Content-Disposition');
      const fileName: string = contentHeader ? (contentHeader.split('filename=')[1]).replace(/['"]+/g, '') : 'WalletInfo-Export.csv';
      a.href = objectUrl;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
    });
  }

}
