import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, FormControl, Validators } from '@angular/forms';
import { URLConfig } from '../../models/url.model';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { config } from './../../../config/pages-config';
import { IDropdown } from '../../common/components/sp-dropdown/IDropdown.interface';
import { UrlConfigService } from '../../common/services/url-config.service';
import { TranslateService } from '@ngx-translate/core';
import { UrlType } from '../../common/enums/url-type.enum';
import { environment } from '../../../environments/environment';
import { NotificationService } from '../../common/services/notification.service';
import { Notification } from './../../models/notification.model';
import { MultiSelect } from '../../models/multi-select.model';
import * as pageConfig from './../../../config/pages-config';
import { DeepLinkNewPage } from '../../models/add-new-page-deepLink.model';
import { AddNewPageDeepLinkService } from '../../common/services/add-new-page-deep-link.service';
import { PageItem } from '../../../app/models/pageItem.model';

@Component({
  selector: 'sp-add-new-page-deeplink',
  templateUrl: './add-new-page-deeplink.component.html',
  styleUrls: ['./add-new-page-deeplink.component.scss']
})
export class AddNewPageDeeplinkComponent implements OnInit {
  public showCloseIcon = false;
  public showError = false;
  public configsFormEdit: FormGroup;
  public configsForm: FormGroup;
  public servicesList: MultiSelect[] = [];
  public servicesListSelected: MultiSelect[] = [];
  public servicesListSelectedEdit: MultiSelect[] = [];
  isHide: boolean;
  filterChar: string;
  pattern: string = '^(\\d*)\\.(\\d*)\\.(\\d+)$';
  pages: DeepLinkNewPage[] = [];
  @ViewChild('modal', { static: true }) public modal: ModalDirective;
  editedItem: DeepLinkNewPage;




  constructor(private fb: FormBuilder, public URLConfig: UrlConfigService,
    private addnewpageDeepLinkservice: AddNewPageDeepLinkService,
    private notificationService: NotificationService, private translateService: TranslateService) {
    this.configsForm = this.fb.group({
      screenName: ['',[Validators.required]],
      screenWebURL: [''],
      appNativeURL: [''],
      desktopURL: ['',[Validators.required]],
      taggingName: [''],
      includesFromiOs:['6.16.0',[Validators.required,Validators.pattern(this.pattern)]],
      includesToiOs:['40.0.0',[Validators.required,Validators.pattern(this.pattern)]],
      includesFromWeb:['0.0.0',[Validators.required,Validators.pattern(this.pattern)]],
      includesToWeb:['40.0.0',[Validators.required,Validators.pattern(this.pattern)]],
      includesFromAndriod:['6.16.0',[Validators.required,Validators.pattern(this.pattern)]],
      includesToAndroid:['40.0.0',[Validators.required,Validators.pattern(this.pattern)]]
      
    });
    this.configsFormEdit = this.fb.group({
      screenName: ['',[Validators.required]],
      screenWebURL: [''],
      appNativeURL: [''],
      desktopURL: ['',[Validators.required]],
      taggingName: [''],
      includesFromiOs:['',[Validators.required,Validators.pattern(this.pattern)]],
      includesToiOs:['',[Validators.required,Validators.pattern(this.pattern)]],
      includesFromWeb:['',[Validators.required,Validators.pattern(this.pattern)]],
      includesToWeb:['',[Validators.required,Validators.pattern(this.pattern)]],
      includesFromAndriod:['',[Validators.required,Validators.pattern(this.pattern)]],
      includesToAndroid:['',[Validators.required,Validators.pattern(this.pattern)]]
    })
  }

  ngOnInit() {
    // getting all the pages that have a deepLink
    this.getPages();
    this.servicesList = pageConfig.config.servicesList;
    this.servicesListSelected = [];
  }
  getPages() {
    this.pages = [];
    this.addnewpageDeepLinkservice.getAllURLS().subscribe(() => {
      this.pages = this.addnewpageDeepLinkservice.pages
    })
  }

  // when URL is Added check first if not any value is exsited before, then add
  // if succsed reset form and show succses notifaction , 
  // if faild handel error and show notification that is faild

  addURL() {
    this.showError = false;
    const isNameExists = !!this.pages.find(el => el.name === this.configsForm.controls['screenName'].value);
    let isappNativeURLExists;
    const appNativeURL = this.configsForm.controls['appNativeURL'].value;
    if (appNativeURL) {
      isappNativeURLExists = !!this.pages.find(el => el.nativeUrl === appNativeURL);
    }
    let isNameTagExists;
    const taggingName = this.configsForm.controls['taggingName'].value;
    if (taggingName) {
      isNameTagExists = !!this.pages.find(el => el.tagName === taggingName)
    }
    let iswebURLExists;
    const screenWebURL = this.configsForm.controls['screenWebURL'].value;
    if (screenWebURL) {
      iswebURLExists = !!this.pages.find(el => el.destination === screenWebURL)
    }
    let isdesktopURL;
    const desktopURL = this.configsForm.controls['desktopURL'].value;
    if (desktopURL) {
      !!this.pages.find(el => el.desktopUrl === desktopURL)
    }
    if (isNameExists || isNameTagExists || isappNativeURLExists || iswebURLExists || isdesktopURL) {
      this.showError = true;
    }
    else {
      const item: DeepLinkNewPage = new DeepLinkNewPage();
      item.name = this.configsForm.controls['screenName'].value;
      item.tagName = this.configsForm.controls['taggingName'].value;
      item.nativeUrl = this.configsForm.controls['appNativeURL'].value;
      item.destination = this.configsForm.controls['screenWebURL'].value;
      item.webUrl = this.configsForm.controls['screenWebURL'].value;
      item.desktopUrl = this.configsForm.controls['desktopURL'].value;
      const servicesList = this.servicesListSelected.map(el => el.itemName);
      item.serviceTypes = servicesList;
      item.hideFromDeepLink = this.isHide || false;
      item.included = [
        {
          "osType": "ios",
          "version": {
              "from": this.configsForm.controls['includesFromiOs'].value,
              "to": this.configsForm.controls['includesToiOs'].value
          }
      },
      {
          "osType": "android",
          "version": {
              "from": this.configsForm.controls['includesFromAndriod'].value,
              "to": this.configsForm.controls['includesToAndroid'].value
          }
      },
      {
          "osType": "web",
          "version": {
              "from": this.configsForm.controls['includesFromWeb'].value,
              "to": this.configsForm.controls['includesToWeb'].value
          }
      }
      ];
      const pageItem : PageItem  ={
        page: {...item}
      };
      this.addnewpageDeepLinkservice.addURL(pageItem).subscribe(() => {
        this.resetForm();
        this.getPages();
        this.successHandling()
      }, error => {
        this.errorHendling();
      });
    }

  }

  // disable form when no form control are empty
  disabledForm(): boolean {
    const controls = this.configsForm.controls;
    const condition_1 = controls['screenName'].value &&
      controls['taggingName'].value &&
      controls['appNativeURL'].value &&
      controls['screenWebURL'].value &&
      controls['desktopURL'].value &&
      controls['includesFromiOs'].value  &&
      controls['includesToiOs'].value  &&
      controls['includesFromWeb'].value  &&
      controls['includesToWeb'].value  &&
      controls['includesFromAndriod'].value  && 
      controls['includesToAndroid'].value &&
      this.servicesListSelected.length > 0 &&
      this.configsForm.valid 
    return !((condition_1 && this.servicesList))
  }

  // a function to show error notification when request is faild
  private errorHendling(isDelete = false) {
    const notification = new Notification();
    const modal = this;
    this.translateService.
      get('home.messages')
      .subscribe(data => {
        notification.bodyTitle = data['error-title'];
        notification.bodyContent = data[isDelete ? 'menu-item-deleting-error' : 'error-text'];
        notification.primaryButtonText = data['buttons']['sure'];
      });
    notification.primaryButtonStyle = 'btn registration';
    notification.primaryButtonClick = function () {
      modal.notificationService.notificationModal.hide();
    };
    this.notificationService.createNotification(notification);
  }

  // clear all form controls
  resetForm() {
    const controls = this.configsForm.controls;
    controls['screenName'].setValue('');
    controls['taggingName'].setValue('');
    controls['appNativeURL'].setValue('');
    controls['screenWebURL'].setValue('');
    controls['desktopURL'].setValue('');
  }

  // a function to show success notification when request is successed

  private successHandling() {
    const notification = new Notification();
    const modal = this;
    this.translateService.
      get('home.successNotification')
      .subscribe(data => {
        notification.bodyContent = data['success-text'];
        notification.primaryButtonText = data['buttons']['cancel'];
      });
    notification.primaryButtonStyle = 'btn registration';
    notification.primaryButtonClick = function () {
      modal.notificationService.notificationModal.hide();
    };
    this.notificationService.createNotification(notification);
  }

  // when delet a page show a notification to confirm the deletion
  // if sure is pressed find the id and call the delet api then show success notifaction
  // if successed it will be deleted if not it will show a faild notification        
  deleteURL(page) {
    const notification = new Notification();
    this.translateService.
      get('home.messages')
      .subscribe(data => {
        notification.bodyTitle = data['delete-message-title'];
        notification.bodyContent = data['delete-text'];
        notification.primaryButtonText = data['buttons']['sure'];
        notification.secondaryButtonText = data['buttons']['cancel'];
      });
    notification.primaryButtonClick = () => {
      this.addnewpageDeepLinkservice.deleteURL(page.id).subscribe(() => {
        this.successHandling()
        this.getPages();
      },
        () => {
          this.errorHendling(true);
        });
      this.notificationService.notificationModal.hide();
    };
    notification.secondaryButtonClick = () => {
      this.notificationService.notificationModal.hide();
    };
    this.notificationService.createNotification(notification);
  }
  // to edit an existing page a modal will show with selected item
  // but first set the edit form to the selected itemss
  editView(page: DeepLinkNewPage) {
    this.showError = false;
    this.editedItem = page;
    this.setValues(page);
    this.modal.show();
  }

  // set control form items with selected edited item
  setValues(page?: DeepLinkNewPage) {
    const controls = this.configsFormEdit.controls;
    if (page) {
      controls['screenName'].setValue(page.name);
      controls['taggingName'].setValue(page.tagName);
      controls['appNativeURL'].setValue(page.nativeUrl);
      controls['screenWebURL'].setValue(page.destination);
      controls['desktopURL'].setValue(page.desktopUrl);
      controls['includesFromiOs'].setValue(page.included[0]?.version.from);
      controls['includesToiOs'].setValue(page.included[0]?.version.to);
      controls['includesFromWeb'].setValue(page.included[2]?.version.from);
      controls['includesToWeb'].setValue(page.included[2]?.version.to);
      controls['includesFromAndriod'].setValue(page.included[1]?.version.from);
      controls['includesToAndroid'].setValue(page.included[1]?.version.to);
      if (page.serviceTypes.length > 0) {
        this.servicesListSelectedEdit = page.serviceTypes.map((el, index) => {
          return {
            id: index,
            itemName: el
          };
        });
      } else {
        this.servicesListSelectedEdit = []
      }

      this.isHide = page.hideFromDeepLink;
    }
  }
  hide() {
    this.resetForm();
    this.modal.hide();
  }
  // when URL is updeted check first if not any value is exsited before, then update
  // if succsed hide the modal and show success notification, 
  // if faild handel error and show notification that is faild
  updatePage() {
    const otherPages = this.pages.filter(el => el !== this.editedItem);
    const isNameExists = !!otherPages.find(el => el.name === this.configsFormEdit.controls['screenName'].value);
    let isappNativeURLExists;
    const appNativeURL = this.configsFormEdit.controls['appNativeURL'].value;
    if (appNativeURL) {
      isappNativeURLExists = !!otherPages.find(el => el.nativeUrl === appNativeURL);
    }
    let isNameTagExists;
    const taggingName = this.configsFormEdit.controls['taggingName'].value;
    if (taggingName) {
      isNameTagExists = !!otherPages.find(el => el.tagName === taggingName)
    }
    let iswebURLExists;
    const screenWebURL = this.configsFormEdit.controls['screenWebURL'].value;
    if (screenWebURL) {
      iswebURLExists = !!otherPages.find(el => el.destination === screenWebURL)
    }
    let isdesktopURL;
    const desktopURL = this.configsFormEdit.controls['desktopURL'].value;
    if (desktopURL) {
      !!otherPages.find(el => el.desktopUrl === desktopURL)
    }
    if (isNameExists || isNameTagExists || isappNativeURLExists || iswebURLExists || isdesktopURL) {
      this.showError = true;
    }
    else {
      const item = new DeepLinkNewPage;
      item.id = this.editedItem.id;
      item.name = this.configsFormEdit.controls['screenName'].value;
      item.tagName = this.configsFormEdit.controls['taggingName'].value;
      item.nativeUrl = this.configsFormEdit.controls['appNativeURL'].value;
      item.destination = this.configsFormEdit.controls['screenWebURL'].value;
      item.webUrl = this.configsFormEdit.controls['screenWebURL'].value;
      item.desktopUrl = this.configsFormEdit.controls['desktopURL'].value;
      const servicesList = this.servicesListSelectedEdit.map(el => el.itemName);
      item.serviceTypes = servicesList;
      item.hideFromDeepLink = this.isHide;
      item.included = [
        {
          "osType": "ios",
          "version": {
              "from": this.configsFormEdit.controls['includesFromiOs'].value,
              "to": this.configsFormEdit.controls['includesToiOs'].value
          }
      },
      {
          "osType": "android",
          "version": {
              "from": this.configsFormEdit.controls['includesFromAndriod'].value,
              "to": this.configsFormEdit.controls['includesToAndroid'].value
          }
      },
      {
          "osType": "web",
          "version": {
              "from": this.configsFormEdit.controls['includesFromWeb'].value,
              "to": this.configsFormEdit.controls['includesToWeb'].value
          }
      }
      ];
      this.addnewpageDeepLinkservice.updateURL(item).subscribe(() => {
        this.hide();
        this.getPages();
        this.successHandling();
      }, () => {
        this.errorHendling();
      });
    }
  }
  onSelectAll(isEdit = false) {
    if (isEdit) {
      this.servicesListSelectedEdit = [...this.servicesList];
    } else {
      this.servicesListSelected = [...this.servicesList];
    }
  }
  onDeSelectAll(isEdit = false) {
    if (isEdit) {
      this.servicesListSelectedEdit = [];
    } else {
      this.servicesListSelected = [];
    }
  }
}
