import { IDropdown } from './../../common/components/sp-dropdown/IDropdown.interface';
import { config } from './../../../config/pages-config';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { URLConfig } from './../../models/url.model';
import { UrlConfigService } from './../../common/services/url-config.service';
import { FormBuilder, FormGroup, FormArray, FormControl } from '@angular/forms';
import { Component, OnInit, ViewChild, AfterContentChecked, ChangeDetectorRef } from '@angular/core';
import { UrlType } from '../../common/enums/url-type.enum';
import { environment } from '../../../environments/environment';
import { NotificationService } from '../../common/services/notification.service';
import { Notification } from './../../models/notification.model';
import { TranslateService } from '@ngx-translate/core';
import { AddNewPageDeepLinkService } from '../../common/services/add-new-page-deep-link.service';
import { DeepLinkNewPage } from '../../models/add-new-page-deepLink.model';

@Component({
  selector: 'sp-url-config',
  templateUrl: './url-config.component.html',
  styleUrls: ['./url-config.component.scss']
})
export class UrlConfigComponent implements OnInit {
  filterChar: string;
  isNewURL: boolean;
  isOldURL: boolean;
  isEditMode: boolean;
  autoValue: string;
  showError: boolean;
  editURL: URLConfig;
  originalURL: URLConfig;
  parameterName: string;
  parameterValue: string;
  urls: URLConfig[] = [];
  parameters: FormArray = new FormArray([]);
  editParameters: FormArray = new FormArray([]);
  public showCloseIcon = false;
  @ViewChild('modal', { static: true }) public modal: ModalDirective;
  webSectionNames: IDropdown[];
  webSectionNamesEdit: string[];
  public configsForm: FormGroup;
  editSelectedKey: string;
  shortUrlParameter: FormGroup;
  public choosePackageUrl = config.appNotInstalled;
  selectedKey: string;
  isWebPortalChecked: boolean;
  isWebPortalCheckedEdit: boolean;
  webSection: string;
  webSectionEdit: string;

  storeOnly: boolean;
  webPortalSection: string;
  webAppURL: string;
  isShowLinkParm: Boolean;
  selectedURL: URLConfig;
  urlParamters: any = [];
  selectedPage: DeepLinkNewPage;
  constructor(private fb: FormBuilder, public URLConfig: UrlConfigService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private addnewpageDeepLinkservice: AddNewPageDeepLinkService, ) {
    this.webSectionNames = [];
    this.webSectionNamesEdit = [];
    this.addnewpageDeepLinkservice.getAllURLS().subscribe(() => {
      this.addnewpageDeepLinkservice.pages.filter(el => !el.hideFromDeepLink).map(el => {
        this.webSectionNames.push({ text: el.name, value: el.name });
        this.webSectionNamesEdit.push(el.name);

      });
    })

    this.URLConfig.urls.map((el) => {
      // To be removed later
      // if (el.urlType.toLocaleLowerCase() !== UrlType.firebase.toLocaleLowerCase()) {
      //   this.urls.push(el);
      // }
    });
    const parameters = new FormArray([]);
    const isWebOnly = false;
    const titleName = '';
    this.configsForm = this.fb.group({
      webSection: [''],
      URLCheck: [''],
      newURL: [''],
      oldURL: [''],
      parameterName: [''],
      parameterValue: [''],
      parameters,
      isWebOnly,
      titleName,
      desktopURL: ['']
    });
    this.isOldURL = true;
    this.isNewURL = false;
  }
  ngOnInit() {
    this.configsForm.controls['webSection'].setValue('');
    this.storeOnly = false;
    this.webAppURL = environment.webAppURL;

  }
  checkUrl(isOld: boolean): void {
    if (isOld) {
      this.isOldURL = true;
      this.isNewURL = false;
      this.configsForm.controls['newURL'].setValue('');
    } else {
      this.isOldURL = false;
      this.isNewURL = true;
      this.configsForm.controls['oldURL'].setValue('');
    }
  }
  deleteURL(url: URLConfig): void {
    const notification = new Notification();
    this.translateService.
      get('home.messages')
      .subscribe(data => {
        notification.bodyTitle = data['delete-message-title'];
        notification.bodyContent = data['delete-text'];
        notification.primaryButtonText = data['buttons']['sure'];
        notification.secondaryButtonText = data['buttons']['cancel'];
      });
    notification.primaryButtonClick = () => {
      this.URLConfig.deleteURL(url).subscribe(() => {
        const oldURL: URLConfig = this.urls.find(el => el.id === url.id);
        if (oldURL) {
          const index = this.urls.indexOf(oldURL);
          this.urls.splice(index, 1);
        }
      },
        () => {
          this.errorHendling(true);
        });
      this.notificationService.notificationModal.hide();
    };
    notification.secondaryButtonClick = () => {
      this.notificationService.notificationModal.hide();
    };
    this.notificationService.createNotification(notification);
  }
  // set Values in Edit Modal Form
  setValues(value?) {
    if (value) {
      this.editURL = JSON.parse(JSON.stringify(value));
    }
    const webSectionValue = this.webSectionEdit;
    this.configsForm.controls['webSection'].setValue(webSectionValue);
    this.webSectionEdit = this.configsForm.controls['webSection'].value;
    this.editSelectedKey = this.editURL.destination.includes('play.google.com') ? config.goToTypes.appStore : config.goToTypes.webPortal;
    this.configsForm.controls['isWebOnly'].setValue(this.editURL.alwaysWeb);
    this.configsForm.controls['titleName'].setValue(this.editURL.name)

    const sourceLink = this.editURL.sourceLink;
    if (sourceLink.includes(environment.webAppURL)) {
      this.isNewURL = true;
      this.isOldURL = false;
      this.configsForm.controls['newURL'].setValue(sourceLink.replace(environment.webAppURL, ''));
      this.configsForm.controls['desktopURL'].setValue(this.editURL.desktopUrl);
      /** set parameters */
      const linkURL = this.getSpecificParameterFromURL(this.editURL.destination, 'link');
      let iterator = this.getURLIteration(linkURL);
      let next = iterator.next();
      this.configsForm.controls['parameters'] = new FormArray([]);
      while (!next.done) {
        (<FormArray>this.configsForm.get('parameters')).push(
          new FormGroup({
            ParameterName: new FormControl(next.value[0]),
            ParameterValue: new FormControl(next.value[1])
          }));
        next = iterator.next();
      }
    } else {
      this.isOldURL = true;
      this.isNewURL = false;
      this.configsForm.controls['oldURL'].setValue(this.editURL.sourceLink)
    }
    this.isWebPortalCheckedEdit = this.editURL.alwaysWeb;
  }
  editView(value: URLConfig): void {
    /** set the object reference to be reflected in the list */
    this.originalURL = value;
    // edit view call set values and show modal popUp
    this.isEditMode = true;
    this.setValues(value);
    this.modal.show();
  }
  // display parameter which is part of destination
  setParameters(destination: string): Array<any> {
    const resultParam = [];
    const myURL = new window.URL(destination);
    const iterator = myURL.searchParams['entries']() as Iterator<any>;
    let next = iterator.next();
    const excludedParameters = ['link', 'apn', 'afl', 'ibi', 'ifl', 'ofl']
    this.configsForm.controls['parameters'] = new FormArray([]);
    while (!next.done) {
      if (next.value && !excludedParameters.includes(next.value[0]))
        resultParam.push(
          new FormGroup({
            ParameterName: new FormControl(next.value[0]),
            ParameterValue: new FormControl(next.value[1])
          })
        );
      next = iterator.next();
    }
    return resultParam;
  }
  hide() {
    this.resetForm();
    this.modal.hide();
  }
  addURL() {
    if (this.configsForm.controls['oldURL'].value || this.configsForm.controls['newURL'].value) {
      const url = new URLConfig();
      this.prepareURLProperties(url);
      this.URLConfig.addURL(url).subscribe(
        id => {
          this.resetForm();
          url.id = id;
          this.urls.push(url);
        },
        () => {
          this.errorHendling();
        });
    }
  }
  resetForm() {
    this.configsForm.reset();
    this.isWebPortalChecked = false;
    this.configsForm.controls['webSection'].setValue('');
    this.configsForm.controls['desktopURL'].setValue('');
    this.configsForm.get('parameters')['controls'] = [];
    this.webSection = ''
    this.isOldURL = true;
    this.isNewURL = false;
    this.selectedKey = '';
    this.storeOnly = false;
    const paramArray = <FormArray>(this.configsForm.controls['parameters']);
    paramArray.removeAt(0);
    this.editSelectedKey = '';
    this.isEditMode = false;
  }
  // upate url values
  updateURL() {
    if (this.editURL) {
      if (this.configsForm.controls['oldURL'].value || this.configsForm.controls['newURL'].value) {
        const urlData = new URLConfig();
        this.prepareURLProperties(urlData);
        if (!this.showError) {
          urlData.id = this.editURL.id;
          this.URLConfig.updateURL(urlData).subscribe(() => {
            const decodedDestination = decodeURIComponent(urlData.destination);
            this.reverseDestinationAndFillURL(decodedDestination)
            this.originalURL.altUrl = this.selectedURL.altUrl;
            this.originalURL.alwaysWeb = this.selectedURL.alwaysWeb;
            this.originalURL.desktopUrl = this.selectedURL.desktopUrl;
            this.originalURL.destination = this.selectedURL.destination;
            this.originalURL.name = this.selectedURL.name;
            this.originalURL.sectionWeb = this.selectedURL.sectionWeb;
            this.originalURL.sourceLink = this.selectedURL.sourceLink;
            this.originalURL.urlType = this.selectedURL.urlType;
            this.hide();
          }, () => {
            this.errorHendling();
          });
        }
      }
    }
  }
  addParameter() {
    (<FormArray>this.configsForm.get('parameters')).push(
      new FormGroup({
        'ParameterName': new FormControl(this.configsForm.controls['parameterName'].value),
        'ParameterValue': new FormControl(this.configsForm.controls['parameterValue'].value)
      })
    );

    this.configsForm.controls['parameterName'].setValue('')
    this.configsForm.controls['parameterValue'].setValue('')
  }
  removeParameter(index: number) {
    (<FormArray>this.configsForm.get('parameters')).removeAt(index);
  }
  copyToClipbored(inputValue: string) {
    const textArea = document.createElement('textarea');
    textArea.value = environment.webAppURL + inputValue;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
  }
  checkStore(selectedValue, form: FormGroup) {
    this.selectedPage = this.addnewpageDeepLinkservice.pages.find(el => el.name.toLowerCase() === selectedValue.toLowerCase())
    this.webSection = this.selectedKey;
    form.controls['webSection'].setValue(selectedValue);
    const store = this.addnewpageDeepLinkservice.pages.find(el => el.name === form.controls['webSection'].value);
    if (store) {
      if (!store.destination) {
        this.storeOnly = true;
        this.selectedKey = config.goToTypes.appStore;
      } else {
        this.storeOnly = false;
      }
    }

  }
  prepareURLProperties(url) {
    const urls = this.isEditMode ? this.urls.filter(el => el.id !== this.editURL.id) : this.urls;
    const isNameExists = !!urls.find(el => el.name === this.configsForm.controls['titleName'].value);
    const isOldExists = !!urls.find(el => el.sourceLink === environment.webAppURL + this.configsForm.controls['oldURL'].value);
    const isNewExists = !!urls.find(el => el.sourceLink === environment.webAppURL + this.configsForm.controls['newURL'].value);
    if (this.configsForm.controls['oldURL'].value && !isOldExists && !isNameExists) {
      this.showError = false;
      url.name = this.configsForm.controls['titleName'].value;
      url.sourceLink = this.configsForm.controls['oldURL'].value;
      const destination = this.addnewpageDeepLinkservice.pages.find(el => el.name === this.configsForm.controls['webSection'].value);
      if (destination) {

        url.destination = destination.nativeUrl;
      }
    } else
      if (this.configsForm.controls['newURL'].value && !isNewExists && !isNameExists) {
        this.showError = false;
        url.name = this.configsForm.controls['titleName'].value;
        url.alwaysWeb = this.configsForm.controls['isWebOnly'].value;
        url.altUrl = this.isEditMode ? this.editSelectedKey : this.selectedKey;
        url.sourceLink = environment.webAppURL + this.configsForm.controls['newURL'].value;

        /** Set destination link */
        let fireBase;
        const form = this.configsForm;
        const queryStrings = <FormArray>(this.configsForm.get('parameters'));
        const section = this.addnewpageDeepLinkservice.pages.find(el => el.name === form.controls['webSection'].value);
        const params = this.getParameters(queryStrings);
        const web_deeplink = this.getWebURL(section, params);
        const native_deepLink = this.getNativeURL(section, params);

        // check firebase URL and set on destination
        if (section && form.controls['isWebOnly'].value === false) {
          /** desktopURL field is OPTIONAL, but if it has a value it should be set instead of the desktopURL that included in selectedPage */
          let desktopURL = this.configsForm.controls['desktopURL'].value;
          if (this.isEditMode && this.selectedPage && this.selectedPage.desktopUrl) {
            desktopURL = this.selectedPage.desktopUrl;
          }
          fireBase = `${environment.firebaseDomain}/?link=${encodeURIComponent(native_deepLink)}`;
          if ((this.editSelectedKey || this.selectedKey).toLowerCase() === config.goToTypes.appStore.toLowerCase()) {
            // tslint:disable-next-line:max-line-length
            url.destination = `${fireBase}&efr=1&apn=${environment.nativeBundleId}&afl=https://play.google.com/store/apps/details?id=es.vodafone.mobile.mivodafone&ibi=${environment.nativeBundleId}&ifl=https://itunes.apple.com/es/app/mi-vodafone/id455655421?mt=8&ofl=${encodeURIComponent(desktopURL)}`;
          } else {
            // tslint:disable-next-line:max-line-length
            url.destination = `${fireBase}&efr=1&apn=${environment.nativeBundleId}&ibi=${environment.nativeBundleId}&ofl=${encodeURIComponent(desktopURL)}&ifl=${encodeURIComponent(web_deeplink)}&afl=${encodeURIComponent(web_deeplink)}`;
          }
        } else {
          url.destination = `${web_deeplink}`;
        }
      } else {
        this.showError = true;
      }
  }
  getParameters(queryStrings: FormArray): string {
    let params: string = '';
    queryStrings.controls.forEach(formGroup => {
      if (formGroup.value.ParameterName && formGroup.value.ParameterValue) {
        params = params + `${formGroup.value.ParameterName}=${formGroup.value.ParameterValue}&`;
      }
    });
    if (params && params.length > 2) {
      params = params.substr(0, params.length - 1);
    }
    return params;
  }
  getWebURL(section: any, params: string) {
    return section ? params ? `${section.webUrl.replace('{0}', environment.webAppURL)}?${params}` :
      `${section.webUrl.replace('{0}', environment.webAppURL)}` : '';
  }
  getNativeURL(section: any, params: string) {
    return params ? `${section.nativeUrl}?${params}` : `${section.nativeUrl}`;
  }

  showLinkParam(): void {
    this.isShowLinkParm = !this.isShowLinkParm;
  }
  resetParameters(destination: string): void {
    this.urlParamters = [];
    if (destination) {
      this.urlParamters = this.setParameters(destination)
    }
    this.isShowLinkParm = false;
  }
  disabledForm(): boolean {
    const controls = this.configsForm.controls;
    const condition_1 = controls['titleName'].value && controls['webSection'].value;
    return !((condition_1 && controls['oldURL'].value) ||
      (condition_1 && controls['newURL'].value) && (this.selectedKey || this.editSelectedKey || controls['isWebOnly'].value));
  }

  selectUrl(url: URLConfig): void {
    if (this.selectedURL !== url) {
      this.selectedURL = url;
      const decodedDestination = decodeURIComponent(this.selectedURL.destination);
      this.selectedURL.destination = decodedDestination;
      this.resetParameters(this.getSpecificParameterFromURL(decodedDestination, 'link'));
      if (!this.selectedURL.desktopUrl) {
        this.reverseDestinationAndFillURL(decodedDestination);
      }
    }
  }

  reverseDestinationAndFillURL(decodedDestination: string) {
    let deeplinkRoute;
    let section;
    /** Check if always open web */
    if (!decodedDestination.includes('apn=')) {
      this.selectedURL.alwaysWeb = true;
      deeplinkRoute = decodedDestination.split('mves/')[1].split('?')[0];
      section = this.addnewpageDeepLinkservice.pages.find(el => el.destination === `{0}${deeplinkRoute}`);
      this.selectedURL.desktopUrl = '';
    } else {
      this.selectedURL.alwaysWeb = false;
      deeplinkRoute = this.getSpecificParameterFromURL(decodedDestination, 'link').split('?')[0];
      section = this.addnewpageDeepLinkservice.pages.find(el => el.nativeUrl === deeplinkRoute);
      this.selectedURL.desktopUrl = this.getSpecificParameterFromURL(decodedDestination, 'ofl');
    }

    if (section) {
      this.webSectionEdit = section.name;
      this.selectedURL.sectionWeb = section.name;
    } else {
      this.webSectionEdit = '';
    }
    this.configsForm.controls['isWebOnly'].setValue(this.selectedURL.alwaysWeb);
    this.editSelectedKey = decodedDestination.includes('play.google.com') ? config.goToTypes.appStore : config.goToTypes.webPortal;
    const selectedKeyItem = this.choosePackageUrl.find(el => el.value === this.editSelectedKey);
    this.selectedURL.altUrl = selectedKeyItem ? selectedKeyItem.text : '';
  }

  private getURLIteration(url: string) {
    return new window.URL(url).searchParams['entries']() as Iterator<any>;
  }
  private getSpecificParameterFromURL(url: string, parameterName: string) {
    let result = '';
    const myURL = new window.URL(url);
    let iterator = myURL.searchParams['entries']() as Iterator<any>;
    let next = iterator.next();
    while (!next.done && !result) {
      if (next.value[0] === parameterName) {
        result = next.value[1];
      }
      next = iterator.next();
    }
    return result;
  }
  private errorHendling(isDelete = false) {
    const notification = new Notification();
    const modal = this;
    this.translateService.
      get('home.messages')
      .subscribe(data => {
        notification.bodyTitle = data['error-title'];
        notification.bodyContent = data[isDelete ? 'menu-item-deleting-error' : 'error-text'];
        notification.primaryButtonText = data['buttons']['sure'];
      });
    notification.primaryButtonStyle = 'btn registration';
    notification.primaryButtonClick = function () {
      modal.notificationService.notificationModal.hide();
    };
    this.notificationService.createNotification(notification);
  }
}
