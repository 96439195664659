import { Component, OnInit, ViewChild } from '@angular/core';
import { MultiSelect } from '../../models/multi-select.model';
import {
  ISchamanConfig,
  SchamanConfigModel,
  IConfigTV,
  TechnologyModel,
  DecoModel,
} from '../../models/schaman-config.model';
import * as pageConfig from './../../../config/pages-config';
import { IDropdown } from '../../common/components/sp-dropdown/IDropdown.interface';
import * as _ from 'lodash';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SchamanService } from '../../common/services/schaman.service';
import { ListItem } from '../../common/components/multi-select-dropdown/multiselect.model';
import { SCHAMAN_MODAL } from '../../common/constants/defines';

@Component({
  selector: 'sp-schaman-config',
  templateUrl: './schaman-config.component.html',
  styleUrls: ['./schaman-config.component.scss'],
})
export class SchamanConfigComponent implements OnInit {
  regex = '^([0-1][0-9]|2[0-3]):[0-5][0-9]$';
  error = 'error';
  isRequired = true;
  configsForm: FormGroup;
  config: ISchamanConfig = SchamanConfigModel;
  digitalSiteData: MultiSelect[] = [];
  digitalSiteSelected: MultiSelect[] = [];
  tvItemsSelected: MultiSelect[] = [];
  technologyData: MultiSelect[] = [];
  tmtas: string[];
  noTmtas: string[];
  tmtasSelected: MultiSelect[] = [];
  noTmtasSelected: MultiSelect[] = [];
  technologySelected: MultiSelect[] = [];
  netWifiHelpValues: IDropdown[] = [
    { text: 'Pantalla Pasos', value: '1' },
    { text: 'Pantalla Video', value: '2' },
    { text: 'Pantallas al 50%', value: '3' },
  ];
  netWifiHelpSelectedValue: any;
  isLoading = false;
  done = false;
  innerText = '';
  serviceTypeValues: IDropdown[] = [
    {text: 'I', value: 'I' },
    {text: 'TV', value: 'TV' },
    {text: 'Fix', value: 'Fix' },
  ];
  technologyValues: IDropdown[] = [
    {text: 'NEBAL', value: 'NEBAL' },
    {text: 'NEBAF', value: 'NEBAF' },
    {text: 'NEBAC', value: 'NEBAC' },
    {text: 'XDSL', value: 'XDSL' },
    {text: 'FTTH', value: 'FTTH' },
    {text: 'CABLE', value: 'CABLE' },
  ];
  segmentValues: IDropdown[] = [
    {text: 'Residencial', value: 'Residencial' },
    {text: 'Microempresa', value: 'Microempresa' },
  ];
  rangeDayOfWeekValues: IDropdown[] = [
    {text: 'L-V', value: 'L-V' },
    {text: 'S-D', value: 'S-D' },
  ];
  dayNightValues: IDropdown[] = [
    {text: 'Día', value: 'Día' },
    {text: 'Noche', value: 'Noche' },
  ];
  serviceTypeSelectedValue = 'I';
  technologySelectedValue = 'NEBAL';
  segmentSelectedValue = 'Residencial';
  rangeDayOfWeekSelectedValue = 'L-V';
  dayNightSelectedValue = 'Día';
  tailCod = '';
  codOfTail = '';
  tailInUse: any;
  @ViewChild('modal', { static: true }) public modal: ModalDirective;

  constructor(private schamanService: SchamanService) {
    this.configsForm = new FormGroup({
      inicioHorarioChatAveria: new FormControl(
        '10:00',
        Validators.compose([
          Validators.required,
          Validators.pattern(this.regex),
        ])
      ),
      finHorarioChatAveria: new FormControl(
        '10:00',
        Validators.compose([
          Validators.required,
          Validators.pattern(this.regex),
        ])
      ),
      inicioHorarioChatAveriaTV: new FormControl(
        '10:00',
        Validators.compose([
          Validators.required,
          Validators.pattern(this.regex),
        ])
      ),
      finHorarioChatAveriaTV: new FormControl(
        '10:00',
        Validators.compose([
          Validators.required,
          Validators.pattern(this.regex),
        ])
      ),
      tailCod: new FormControl(),
    });
  }

  ngOnInit(): void {
    this.digitalSiteData = pageConfig.config.digitalSite;
    this.technologyData = pageConfig.config.technology;
    this.schamanService
      .getConfig()
      .toPromise()
      .then((shamanConfig: ISchamanConfig) => {
        this.config = { ...shamanConfig };
        this.digitalSiteData.forEach((DSDatum) => {
          shamanConfig.digitalSite.forEach((data) => {
            if (DSDatum.itemName === data) {
              this.digitalSiteSelected.push(DSDatum);
            }
          });
          shamanConfig.grifoHFC.tmtas.forEach((data) => {
            if (DSDatum.itemName === data) {
              this.tmtasSelected.push(DSDatum);
            }
          });
          shamanConfig.grifoHFC.noTmtas.forEach((data) => {
            if (DSDatum.itemName === data) {
              this.noTmtasSelected.push(DSDatum);
            }
          });
        });
        this.technologyData.forEach((TDatum) => {
          shamanConfig.technology.forEach((data) => {
            if (TDatum.itemName === data) {
              this.technologySelected.push(TDatum);
            }
          });
        });
        for (let index = 0; index < this.config.grifoTV.length; index++) {
          const element: IConfigTV = this.config.grifoTV[index];
          const numbers: (
            | '1'
            | '2'
            | '3'
            | '0'
            | '4'
            | '5'
            | '6'
            | '7'
            | '8'
            | '9'
          )[] = [...element.digitalSite];
          element.digitalSite = [] as any;
          numbers.forEach((number) => {
            this.digitalSiteData.forEach((data) => {
              if (data.itemName === number) {
                element.digitalSite.push(data as any);
              }
            });
          });
        }
        this.netWifiHelpSelectedValue = {
          text: this.config.netWiFiHelp,
          value: this.config.netWiFiHelp,
        };
      })
      .catch((err) => {
        throw err['message'];
      });
  }

  switchChatAveria(): void {
    this.config.chatAveria = !this.config.chatAveria;
  }

  onSelectAllDigitalSite(): void {
    this.digitalSiteSelected = [...this.digitalSiteData] as any;
  }

  onDeSelectAllDigitalSite(): void {
    this.digitalSiteSelected = [];
  }

  onSelectAllTechnology(): void {
    this.technologySelected = [...this.technologyData] as any;
  }

  onDeSelectAllTechnology(): void {
    this.technologySelected = [];
  }

  onSelectAllDigitalSiteTV(e: ListItem[], i: number): void {
    this.config.grifoTV[i].digitalSite = [...e] as any;
  }

  onDeSelectAllDigitalSiteTV(i: number): void {
    this.config.grifoTV[i].digitalSite = [] as any;
  }

  onSelectAllTmtas(): void {
    this.tmtasSelected = [...this.digitalSiteData] as any;
  }

  onDeSelectAllTmtas(): void {
    this.tmtasSelected = [];
  }

  onSelectAllNoTmtas(): void {
    this.noTmtasSelected = [...this.digitalSiteData] as any;
  }

  onDeSelectAllNoTmtas(): void {
    this.noTmtasSelected = [];
  }

  submit(): void {
    const newConfig: ISchamanConfig = _.cloneDeep(this.config);
    this.tmtas = [];
    this.noTmtas = [];
    newConfig.grifoHFC = {} as any;
    newConfig.digitalSite = [] as any;
    newConfig.technology = [] as any;
    this.digitalSiteSelected.forEach((element) => {
      newConfig.digitalSite.push(element.itemName as any);
    });
    this.technologySelected.forEach((element) => {
      newConfig.technology.push(element.itemName as any);
    });
    this.tmtasSelected.forEach(element => {
      this.tmtas.push(element.itemName);
    });
    this.noTmtasSelected.forEach(element => {
      this.noTmtas.push(element.itemName);
    });
    newConfig.grifoHFC = {
      tmtas: this.tmtas as any,
      noTmtas: this.noTmtas as any,
    };
    for (let index = 0; index < newConfig.grifoTV.length; index++) {
      const element: IConfigTV = newConfig.grifoTV[index];
      const numbers: IConfigTV['digitalSite'][] = [];
      element.digitalSite.forEach((ele: any) => {
        numbers.push(ele['itemName']);
      });
      element.digitalSite = numbers as any;
    }
    this.modifyTail();
    const objectToSend: ISchamanConfig = {
      netWiFiHelp: newConfig.netWiFiHelp,
      chatAveria: newConfig.chatAveria,
      digitalSite: newConfig.digitalSite,
      technology: newConfig.technology,
      inicioHorarioChatAveria: newConfig.inicioHorarioChatAveria,
      finHorarioChatAveria: newConfig.finHorarioChatAveria,
      grifoTV: newConfig.grifoTV,
      finHorarioChatAveriaTV: newConfig.finHorarioChatAveriaTV,
      inicioHorarioChatAveriaTV: newConfig.inicioHorarioChatAveriaTV,
      grifoHFC: newConfig.grifoHFC,
      colas: this.config.colas,
    };
    this.isLoading = true;
    this.schamanService
      .sendConfig(objectToSend)
      .toPromise()
      .then(() => {
        this.innerText = SCHAMAN_MODAL.ok;
        this.done = true;
        this.isLoading = false;
      })
      .catch((err) => {
        this.innerText = SCHAMAN_MODAL.ko;
        this.done = true;
        this.isLoading = false;
        throw err['message'];
      });
  }

  isTechnology(technology: string): boolean {
    return TechnologyModel.includes(technology);
  }

  isDeco(deco: string): boolean {
    return DecoModel.includes(deco);
  }

  hideModal(): void {
    this.modal.hide();
    setTimeout(() => {
      this.innerText = '';
      this.done = false;
    }, 500);
  }

  searchTail(): void {
    const JOIN_CODES: Object = {
      'L-VDía': 'codDiario',
      'L-VNoche': 'codDiarioNoche',
      'S-DDía': 'codFinDeSemana',
      'S-DNoche': 'codFinDeSemanaNoche',
    };
    this.codOfTail = JOIN_CODES[this.rangeDayOfWeekSelectedValue + this.dayNightSelectedValue];
    this.config.colas.forEach(element => {
      if (
        this.serviceTypeSelectedValue === element.serviceType &&
        this.technologySelectedValue === element.tecnologia &&
        this.segmentSelectedValue === element.segmento
      ) {
        this.tailInUse = element;
        switch (this.codOfTail) {
          case 'codDiario':
            this.tailCod = element.codDiario;
            break;
          case 'codDiarioNoche':
            this.tailCod = element.codDiarioNoche;
            break;
          case 'codFinDeSemana':
            this.tailCod = element.codFinDeSemana;
            break;
          case 'codFinDeSemanaNoche':
            this.tailCod = element.codFinDeSemanaNoche;
            break;
          default:
            break;
        }
      }
    });
  }

  modifyTail(): void {
    switch (this.codOfTail) {
      case 'codDiario':
        this.tailInUse.codDiario = this.tailCod;
        break;
      case 'codDiarioNoche':
        this.tailInUse.codDiarioNoche = this.tailCod;
        break;
      case 'codFinDeSemana':
        this.tailInUse.codFinDeSemana = this.tailCod;
        break;
      case 'codFinDeSemanaNoche':
        this.tailInUse.codFinDeSemanaNoche = this.tailCod;
        break;
      default:
        break;
    }
  }
}
