<div class="radio custom-radio" [ngClass]="{'vodafone-one':one}" *ngIf="dataSource && dataSource.length">
  <ul>
      <div *ngFor="let data of dataSource">
      <li *ngIf="!data.module">
          <div class="item-content form-group" >

              <!-- Radio Button -->
              <input *ngIf="!isCheckBox" (click)="onClick(data)" type="radio" id="{{data.id}}" [name]="groupName" [value]="data.name" (change)="updateForm(data)" [checked]="selectedItem?.id == data?.id">

              <!-- Checkbox -->
              <input *ngIf="isCheckBox" (click)="onClick(data)" type="checkbox" id="{{data.id}}" [name]="groupName" [value]="data.name" (change)="updateForm(data)" [checked]="selectedItemsIDs?.includes(data?.id)">
              <!-- title for sidemenu & name for chatconfig -->
              <label class="block" for="{{data.id}}" [ngClass]="{'is-parent': isCheckBox && !data.module}" [innerHTML]="data.title || data.name">   </label>
              <label *ngIf="!data.parentId && data.iconName" class="block" for="{{data.id}}">
                  <span class="icon " [ngClass]="data.iconName"></span>
              </label>
              <span *ngIf="delete && !(isCheckBox && data.module)" class="updated icon icon-delete" (click)="onDeleteClick(data)"></span>
              <span *ngIf="edit && !(isCheckBox && !data.module)" class="updated icon icon-edit" (click)="onEditClick(data)"></span>
          </div>

      </li>
    </div>
  </ul>
</div>
