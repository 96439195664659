import { SpModalComponent } from '../components/sp-modal/sp-modal.component';
import { Notification } from '../../models/notification.model';
import { Injectable } from "@angular/core";
@Injectable()
export class NotificationService {
    public notificationModal: SpModalComponent;
    constructor() { }
    public createNotification(notification: Notification) {
        this.notificationModal.bodyContent = notification.bodyContent;
        this.notificationModal.bodyIcon = notification.bodyIcon;
        this.notificationModal.bodyTitle = notification.bodyTitle;
        this.notificationModal.closeButtonClick = notification.closeButtonClick;
        this.notificationModal.primaryButtonClick = notification.primaryButtonClick;
        this.notificationModal.primaryButtonStyle = notification.primaryButtonStyle;
        this.notificationModal.primaryButtonText = notification.primaryButtonText;
        this.notificationModal.secondaryButtonClick = notification.secondaryButtonClick;
        this.notificationModal.secondaryButtonStyle = notification.secondaryButtonStyle;
        this.notificationModal.secondaryButtonText = notification.secondaryButtonText;
        this.notificationModal.modelImg = notification.modelImg;
        this.notificationModal.show();
    }
}
