<div class="toggle-container" *ngIf="config">
  <div class="form-container">
    <form [formGroup]="configsForm" (ngSubmit)="modal.show()">
      <div>
        <h2>{{ "home.schaman-config.chat" | translate }}</h2>
        <div class="toggle">
          <h4>{{ "home.schaman-config.active-chat" | translate }}</h4>
          <div class="toggle-btn">
            <sp-toggle-button
              (option)="switchChatAveria($event)"
              [check]="config.chatAveria"
            ></sp-toggle-button>
          </div>
        </div>
      </div>
      <br />
      <div class="row">
        <div class="col-lg-6">
          <label class="bonita-label">{{
            "home.schaman-config.internet-open-time" | translate
          }}</label>
          <sp-text
            [(ngModel)]="config.inicioHorarioChatAveria"
            formControlName="inicioHorarioChatAveria"
            textType="text"
            [disabled]="!config.chatAveria"
          ></sp-text>
          <label
            class="error"
            *ngIf="configsForm.controls.inicioHorarioChatAveria.invalid"
          >
            {{ "home.schaman-config.input-error" | translate }}
          </label>
        </div>
        <div class="col-lg-6">
          <label class="bonita-label">{{
            "home.schaman-config.internet-close-time" | translate
          }}</label>
          <sp-text
            [(ngModel)]="config.finHorarioChatAveria"
            formControlName="finHorarioChatAveria"
            textType="text"
            [disabled]="!config.chatAveria"
          ></sp-text>
          <label
            class="error"
            *ngIf="configsForm.controls.finHorarioChatAveria.invalid"
          >
            {{ "home.schaman-config.input-error" | translate }}
          </label>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <label class="bonita-label">{{
            "home.schaman-config.tv-open-time" | translate
          }}</label>
          <sp-text
            [(ngModel)]="config.inicioHorarioChatAveriaTV"
            formControlName="inicioHorarioChatAveriaTV"
            textType="text"
            [disabled]="!config.chatAveria"
          ></sp-text>
          <label
            class="error"
            *ngIf="configsForm.controls.inicioHorarioChatAveriaTV.invalid"
          >
            {{ "home.schaman-config.input-error" | translate }}
          </label>
        </div>
        <div class="col-lg-6">
          <label class="bonita-label">{{
            "home.schaman-config.tv-close-time" | translate
          }}</label>
          <sp-text
            [(ngModel)]="config.finHorarioChatAveriaTV"
            formControlName="finHorarioChatAveriaTV"
            textType="text"
            [disabled]="!config.chatAveria"
          ></sp-text>
          <label
            class="error"
            *ngIf="configsForm.controls.finHorarioChatAveriaTV.invalid"
          >
            {{ "home.schaman-config.input-error" | translate }}
          </label>
        </div>
      </div>
      <hr />
      <div>
        <h2>{{ "home.schaman-config.internet" | translate }}</h2>
        <div class="selectors">
          <div class="col-lg-6">
            <label class="bonita-label">{{ "home.schaman-config.digital-site" | translate }}</label>
            <angular2-multiselect
              class="multiselect"
              [data]="digitalSiteData"
              [selectedItems]="digitalSiteSelected"
              (onSelectAll)="onSelectAllDigitalSite()"
              (onDeSelectAll)="onDeSelectAllDigitalSite()"
            >
            </angular2-multiselect>
          </div>
          <div class="col-lg-6">
            <label class="bonita-label">{{ "home.schaman-config.technologies" | translate }}</label>
            <angular2-multiselect
              class="multiselect"
              [data]="technologyData"
              [selectedItems]="technologySelected"
              (onSelectAll)="onSelectAllTechnology()"
              (onDeSelectAll)="onDeSelectAllTechnology()"
            >
            </angular2-multiselect>
          </div>
        </div>
      </div>
      <br />
      <div class="internet-option">
        <label class="bonita-label">{{
          "home.schaman-config.video-screen" | translate
        }}</label>
        <sp-dropdown
          (change)="config.netWiFiHelp = $event.target.value"
          [selectedValue]="config.netWiFiHelp"
          [items]="netWifiHelpValues"
        >
        </sp-dropdown>
      </div>
      <div class="selectors">
        <div class="col-lg-6">
          <label class="bonita-label">{{"home.schaman-config.tmtas" | translate}}</label>
          <angular2-multiselect
            class="multiselect"
            [data]="digitalSiteData"
            [selectedItems]="tmtasSelected"
            (onSelectAll)="onSelectAllTmtas()"
            (onDeSelectAll)="onDeSelectAllTmtas()"
          >
          </angular2-multiselect>
        </div>
        <div class="col-lg-6">
          <label class="bonita-label">{{"home.schaman-config.noTmtas" | translate}}</label>
          <angular2-multiselect
            class="multiselect"
            [data]="digitalSiteData"
            [selectedItems]="noTmtasSelected"
            (onSelectAll)="onSelectAllNoTmtas()"
            (onDeSelectAll)="onDeSelectAllNoTmtas()"
          >
          </angular2-multiselect>
        </div>
      </div>
      <hr />
      <div class="tvconfig">
        <div>
          <h2>{{ "home.schaman-config.tv" | translate }}</h2>
          <div class="col-lg-12 items">
            <div class="col-lg-5">
              <h3>{{ "home.schaman-config.technologies" | translate }}</h3>
              <div *ngFor="let item of config.grifoTV; let i = index">
                <div class="table" *ngIf="isTechnology(item.codigoServicio)">
                  <label class="bonita-label"> {{ item.codigoServicio }} </label>
                  <angular2-multiselect
                    class="multiselect"
                    [data]="digitalSiteData"
                    [selectedItems]="item.digitalSite"
                    (onSelectAll)="onSelectAllDigitalSiteTV($event, i)"
                    (onDeSelectAll)="onDeSelectAllDigitalSiteTV(i)"
                  >
                  </angular2-multiselect>
                </div>
              </div>
            </div>
            <div class="col-lg-5">
              <h3>{{ "home.schaman-config.decos" | translate }}</h3>
              <div *ngFor="let item of config.grifoTV; let i = index">
                <div class="table" *ngIf="isDeco(item.codigoServicio)">
                  <label class="bonita-label"> {{ item.codigoServicio }} </label>
                  <angular2-multiselect
                    class="multiselect"
                    [data]="digitalSiteData"
                    [selectedItems]="item.digitalSite"
                    (onSelectAll)="onSelectAllDigitalSiteTV($event, i)"
                    (onDeSelectAll)="onDeSelectAllDigitalSiteTV(i)"
                  >
                  </angular2-multiselect>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="colas">
        <div>
          <h2>{{ "home.schaman-config.tails" | translate }}</h2>
          <div class="col-lg-12 items">
            <div class="col-lg-5">
              <div>
                <label class="bonita-label">{{"home.schaman-config.serviceType" | translate}}</label>
                <sp-dropdown
                  class=""
                  [items]="serviceTypeValues"
                  [selectedValue]="serviceTypeSelectedValue"
                  (change)="serviceTypeSelectedValue = $event.target.value"
                >
                </sp-dropdown>
              </div>
              <div>
                <label class="bonita-label">{{"home.schaman-config.technology" | translate}}</label>
                <sp-dropdown
                  class=""
                  [items]="technologyValues"
                  [selectedValue]="technologySelectedValue"
                  (change)="technologySelectedValue = $event.target.value"
                >
                </sp-dropdown>
              </div>
              <div>
                <label class="bonita-label">{{"home.schaman-config.segment" | translate}}</label>
                <sp-dropdown
                  class=""
                  [items]="segmentValues"
                  [selectedValue]="segmentSelectedValue"
                  (change)="segmentSelectedValue = $event.target.value"
                >
                </sp-dropdown>
              </div>
            </div>
            <div class="col-lg-5">
              <div>
                <label class="bonita-label">{{"home.schaman-config.rangeDayOfWeek" | translate}}</label>
                <sp-dropdown
                  class=""
                  [items]="rangeDayOfWeekValues"
                  [selectedValue]="rangeDayOfWeekSelectedValue"
                  (change)="rangeDayOfWeekSelectedValue = $event.target.value"
                >
                </sp-dropdown>
              </div>
              <div>
                <label class="bonita-label">{{"home.schaman-config.dayNight" | translate}}</label>
                <sp-dropdown
                  class=""
                  [items]="dayNightValues"
                  [selectedValue]="dayNightSelectedValue"
                  (change)="dayNightSelectedValue = $event.target.value"
                >
                </sp-dropdown>
              </div>
              <div class="search-btn">
                <img src='../../../assets/icons/search-64.svg' (click)="searchTail()">
              </div>
            </div>
          </div>
          <div class="col-lg-12 items colas-center">
            <label class="bonita-label">{{
              "home.schaman-config.tail" | translate
            }}</label>
            <sp-text
              [(ngModel)]="tailCod"
              formControlName="tailCod"
              textType="text"
            ></sp-text>
          </div>
        </div>
      </div>
      <br />
      <div class="row submit-btn-container">
        <button
          [disabled]="configsForm.valid ? false : true"
          class="submit-btn"
          type="submit"
        >
          {{ "home.schaman-config.send" | translate }}
        </button>
      </div>
    </form>
  </div>
</div>

<div
  bsModal
  #modal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="mySmallModalLabel"
  aria-hidden="true"
  (onHidden)="hideModal()"
>
  <div class="modal-dialog">
    <div class="modal-content" style="padding: 2%">
      <div class="modal-text">
        <div *ngIf="!isLoading">
          <h1>
            {{
              innerText ? innerText : ("home.schaman-config.alert" | translate)
            }}
          </h1>
        </div>
        <div *ngIf="isLoading">
          <h1>{{ "home.schaman-config.waiting" | translate }}</h1>
          <div class="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
      <hr />
      <div class="buttons-container" [class.isLoading]="isLoading">
        <input
          *ngIf="!done"
          [disabled]="isLoading ? true : false"
          type="button"
          (click)="hideModal()"
          value="Cancelar"
          class="button gray two"
        />
        <input
          [disabled]="isLoading ? true : false"
          type="button"
          (click)="done ? hideModal() : submit()"
          [value]="
            done
              ? ('home.schaman-config.accept' | translate)
              : ('home.schaman-config.send' | translate)
          "
          class="button red two"
        />
      </div>
    </div>
  </div>
</div>
