<div class="admin-top">
  <br />
  <p>{{'home.chat-config.title'|translate}}</p>
  <hr />
</div>
<div class="row div-body">
  <form>
    <div class="row dropdown-group-2 col-12 offset-md-2 col-lg-6 col-md-10 offset-md-1">

      <angular2-multiselect class="multiselect" [data]="userTypesList" label="{{'home.chat-config.page-one.filter-field.0'|translate}}"
        (onSelect)="onItemSelect($event,'user')" (onDeSelect)="onItemDeSelect($event,'user')" [selectedItems]="userTypesListSelected"
        (onSelectAll)="onSelectAll($event,'user')" (onDeSelectAll)="onDeSelectAll($event,'user') "></angular2-multiselect>

    </div>
    <div class="row dropdown-group-2 col-12 offset-md-2 col-lg-6 col-md-10 offset-md-1">
      <!-- <angular2-multiselect class="multiselect" [data]="serviceTypesList" label="{{'home.chat-config.page-one.filter-field.1'|translate}}"
        (onSelect)="onItemSelect($event,'service')" (onDeSelect)="onItemDeSelect($event,'service')" [selectedItems]="serviceTypesListSelected"
        (onSelectAll)="onSelectAll($event,'service')" (onDeSelectAll)="onDeSelectAll($event,'service') "></angular2-multiselect> -->
    </div>
    <div class="row dropdown-group-2 col-12 offset-md-2 col-lg-6 col-md-10 offset-md-1">
      <!-- <angular2-multiselect class="multiselect" [data]="profileTypesList" label="{{'home.chat-config.page-one.filter-field.2'|translate}}"
        (onSelect)="onItemSelect($event,'profile')" (onDeSelect)="onItemDeSelect($event,'profile')" [selectedItems]="profileTypesListSelected"
        (onSelectAll)="onSelectAll($event,'profile')" (onDeSelectAll)="onDeSelectAll($event,'profile') "></angular2-multiselect> -->
    </div>
    <div class="container col-12 offset-md-2 col-lg-6 col-md-10 offset-md-1">

      <div class="header">
        <div class="icons-group">
          <button><span class="icon icon-add-or-plus" (click)="addNew()"></span></button>
          <button [ngClass]="{'opacity':!selectedItemsIDs.length}" [disabled]="!selectedItemsIDs.length" (click)="editMultiItems()"><span
              class="icon icon-edit"></span></button>
              <button [ngClass]="{'opacity':!selectedItemsIDs.length}" [disabled]="!selectedItemsIDs.length" (click)="deleteMultiItems()"><span
                class="icon icon-delete"></span></button>
        </div>

      </div>
      <div *ngIf="filteredPages?.length == 0" class="empty-body">
        <span>
          {{'home.chat-config.page-one.info-text'|translate}}
        </span>
      </div>
      <div *ngIf="filteredPages?.length > 0" class="body">
        <sp-radio-button [isCheckBox]="true" [selectedItemsIDs]="isSelectAllChecked()" [dataSource]="[{id:'0', title: 'Select All', iconName: 'icon-tick-outline', module:'all'}]"
          (radioClick)="radioSelectAllClick()"> </sp-radio-button>
        <div>
          <sp-radio-button [isCheckBox]="true" [selectedItemsIDs]="selectedItemsIDs" [edit]="'true'" [dataSource]="filteredPages"
            [groupName]="'chat'" (editClick)="editItem($event)" (radioClick)="radioClick($event)">
          </sp-radio-button>
        </div>

      </div>
    </div>
  </form>

  <div class="container col-12 offset-md-2 col-lg-6 col-md-10 offset-md-1">
  <button class="button turkuaz two marginTop" (click)="download()">  {{'home.chat-config.page-one.export-btn'|translate}}</button>
  </div>

</div>
<sp-chat-config-new-item #notification (submitted)="refreshMenu($event)"></sp-chat-config-new-item>