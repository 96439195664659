import { Component, OnInit } from '@angular/core';
import { ConfigurationService } from '../../common/services/configuration.service';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { ProductConfigurationModel } from '../../models/product-configuration.model';
import { NotificationService } from '../../common/services/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { Notification } from '../../models/notification.model';

@Component({
  selector: 'sp-product-config',
  templateUrl: './product-config.component.html',
  styleUrls: ['./product-config.component.scss']
})
export class ProductConfigComponent implements OnInit {

  public productForm: FormGroup;
  public currentConfig: ProductConfigurationModel[];

  constructor(
    private configService: ConfigurationService,
    private fb: FormBuilder,
    private notificationService: NotificationService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.currentConfig = this.configService.getProductConfiguration();
    this.productForm = new FormGroup({
      productArray: this.fb.array(this.createProductFormGroup())
    });
  }

  private createProductFormGroup() {
    const fbProducts: FormGroup[] = [];
    this.currentConfig.forEach(product => {
      fbProducts.push(this.fb.group({
        ios: product.ios,
        android: product.android,
        web: product.web,
        dxl: product.dxl,
        featureID: product.featureID,
        filterType: product.filterType,
        filterValue: product.filterValue,
        operator: product.operator,
        adobe: product.adobe,
      }));
    });
    return fbProducts;
  }

  private generateNewConfiguration(): ProductConfigurationModel[] {
    const newConfiguration: ProductConfigurationModel[] = [];
    const productArray = this.productForm.get('productArray') as FormArray;
    productArray.controls.forEach(productFG => {
      const product = productFG as FormGroup;
      newConfiguration.push({
        ios: product.controls.ios.value,
        android: product.controls.android.value,
        web: product.controls.web.value,
        dxl: product.controls.dxl.value,
        featureID: product.controls.featureID.value,
        filterType: product.controls.filterType.value,
        filterValue: product.controls.filterValue.value,
        operator: product.controls.operator.value,
        adobe: product.controls.adobe.value,
      });
    });
    return newConfiguration;
  }

  submit() {
    const newConfiguration: ProductConfigurationModel[] = this.generateNewConfiguration();
    this.configService.patchConfiguration({'productConfiguration': newConfiguration}).subscribe(
      () => {
        this.translate.get('home.messages').subscribe(msg => {
          const notification = new Notification();
          notification.bodyTitle = msg['save-configuration'];
          notification.primaryButtonText = msg.buttons.close;
          notification.primaryButton = true;
          notification.primaryButtonClick = () => this.notificationService.notificationModal.hide();
          this.notificationService.createNotification(notification);
        });
      },
      () => {
        this.translate.get('home.messages').subscribe(msg => {
          const notification = new Notification();
          notification.bodyTitle = msg['fail-msg'];
          notification.primaryButtonText = msg.buttons.close;
          notification.primaryButton = true;
          notification.primaryButtonClick = () => this.notificationService.notificationModal.hide();
          this.notificationService.createNotification(notification);
        });
      }
    );
  }

}
