<div class="col-lg-12 offset-md-1 col-md-10 offset-md-1 col-12 toggle-container">
  <div class="form-container">
    <form [formGroup]="form" (ngSubmit)="submit()">
        <div class="row">
            <label class="bonita-label col-2">{{'home.bonita-config.form.movil.label'|translate}}:</label>
            <div class="col-4">
                <sp-text  [isRequired]="true" formControlName="mobileFlow" placeholder="{{'home.bonita-config.form.movil.flow-placeholder'|translate}}"></sp-text>
              </div>
              <div class="col-4">
                  <sp-text  [isRequired]="true" formControlName="mobileVersion" placeholder="{{'home.bonita-config.form.movil.Version-placeholder'|translate}}"></sp-text>
                </div>
            
          </div>
          <div class="row">
              <label class="bonita-label col-2">{{'home.bonita-config.form.internet.label'|translate}}:</label>
              <div class="col-4">
                  <sp-text  [isRequired]="true" formControlName="internetFlow" placeholder="{{'home.bonita-config.form.internet.flow-placeholder'|translate}}"></sp-text>
                </div>
                <div class="col-4">
                    <sp-text  [isRequired]="true" formControlName="internetVersion" placeholder="{{'home.bonita-config.form.internet.Version-placeholder'|translate}}"></sp-text>
                  </div>
              
            </div>
            <div class="row">
                <label class="bonita-label col-2">{{'home.bonita-config.form.fixed.label'|translate}}:</label>
                <div class="col-4">
                    <sp-text  [isRequired]="true" formControlName="fixedFlow" placeholder="{{'home.bonita-config.form.fixed.flow-placeholder'|translate}}"></sp-text>
                  </div>
                  <div class="col-4">
                      <sp-text  [isRequired]="true" formControlName="fixedVersion" placeholder="{{'home.bonita-config.form.fixed.Version-placeholder'|translate}}"></sp-text>
                    </div>
                
              </div>
              <div class="row">
                  <label class="bonita-label col-2">{{'home.bonita-config.form.tv.label'|translate}}:</label>
                  <div class="col-4">
                      <sp-text  [isRequired]="true" formControlName="tvFlow" placeholder="{{'home.bonita-config.form.tv.flow-placeholder'|translate}}"></sp-text>
                    </div>
                    <div class="col-4">
                        <sp-text  [isRequired]="true" formControlName="tvVersion" placeholder="{{'home.bonita-config.form.tv.Version-placeholder'|translate}}"></sp-text>
                      </div>
                  
                </div>
                <div class="row submit-btn-container">
                  <button [disabled]="form.invalid" class="submit-btn" type="submit">Save</button>
                </div>

    </form>
  </div>
</div>