import { Notification } from './../../../models/notification.model';
import { SidemenuConfigService } from '../../services/sidemenu-config.service';
import { IDropdown } from '../sp-dropdown/IDropdown.interface';
import { ISideMenuItem, AccountType } from '../../../models/sidemenuItem.model';
import { Component, OnInit, ViewEncapsulation, Input, ViewChild, AfterContentChecked, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { SidemenuQuery } from '../../constants/defines';
import * as pageConfig from '../../../../config/pages-config';
import * as _ from 'lodash';
import { SpIconAutoCompleteComponent } from '../sp-icon-auto-complete/icon-auto-complete.component';
import { NotificationService } from '../../services/notification.service';
import { MultiSelect } from '../../../models/multi-select.model';

@Component({
  selector: 'sp-sidemenu-new-item',
  templateUrl: './sidemenu-new-item.component.html',
  styleUrls: ['./sidemenu-new-item.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SidemenuNewItemComponent implements OnInit, AfterContentChecked {
  title: string;
  @ViewChild('modal', { static: true }) public modal: ModalDirective;
  @ViewChild('iconInput', { static: true }) public iconInput: SpIconAutoCompleteComponent;
  @Input() isEdit = false;
  @Input() content: ISideMenuItem;
  isValid: boolean;
  isExternal = false;
  isContengency = false;
  isOldPortal = false;
  isECare = false;
  defaultRoute = '';
  internalPath: string;
  isLight = false;
  isEnabled = true;
  selectedIcon: string;
  selectedGroup: string;
  description: string;
  isItemChild: boolean;
  isAddedOnce: boolean;
  siteStatusList: MultiSelect[] = [];
  customerSegmentList: MultiSelect[] = [];
  platformList: MultiSelect[] = [];
  consumerList: MultiSelect[] = [];
  employeeList: MultiSelect[] = [];
  authorizedList: MultiSelect[] = [];
  employeesmeList: MultiSelect[] = [];

  siteStatusListSelected: MultiSelect[] = [];
  customerSegmentListSelected: MultiSelect[] = [];
  platformListSelected: MultiSelect[] = [];
  consumerListSelected: MultiSelect[] = [];
  employeeListSelected: MultiSelect[] = [];
  authorizedListSelected: MultiSelect[] = [];
  employeeSMEListSelected: MultiSelect[] = [];


  groupsList: IDropdown[] = [];
  directToList: IDropdown[] = [];
  internalRoutesList: IDropdown[] = [];
  dropdownSettings = {};
  innervalue: string;
  selectedDirection: string;
  @Output() submitted = new EventEmitter<boolean>();
  // to disable usertype/ service section
  showServicesSection = true;
  //disable statuses
  p2AndP3SiteStatuses: MultiSelect[];
  p1SiteStatuses: MultiSelect[];

  form: FormGroup;
  constructor(private _fb: FormBuilder, private sidemenuConfigService: SidemenuConfigService,
    private translateService: TranslateService, private notificationService: NotificationService) {
    this.form = _fb.group(
      {
        itemName: [''],
        technicalName: [''],
        externalPath: [''],
        fromVersionFirst: [''],
        fromVersionSecond: [''],
        fromVersionThird: [''],
        toVersionFirst: [''],
        toVersionSecond: [''],
        toVersionThird: ['']
      });
  }

  omit_special_char(charCode) {
    const key = charCode;
    return ((key > 64 && key < 91) || (key > 96 && key < 123) || key === 8 || (key >= 48 && key <= 57));
  }
  omit_char(event) {
    const value = event.target.value;
    const num = value.match(/^[0-9][0-9]?$/);
    if (num === null) {
      if (+event.target.value < 0) {
        event.target.value = value.toString().substring(1);
      }
    }
  }

  onPaste(e) {
    e.preventDefault();
    const newval = e.clipboardData.getData('text/plain').toString();
    let myVal = '';
    for (let i = 0; i < newval.length; i++) {
      myVal += this.omit_special_char(newval[i].charCodeAt(0)) ? newval[i] : '';
    }
    this.form.controls[SidemenuQuery.technicalName].setValue(myVal);
  }
  onDrop(e) {
    e.preventDefault();
    const newval = e.dataTransfer.getData('text');
    let myVal = '';
    for (let i = 0; i < newval.length; i++) {
      myVal += this.omit_special_char(newval[i].charCodeAt(0)) ? newval[i] : '';
    }
    this.form.controls[SidemenuQuery.technicalName].setValue(myVal);
  }
  ngOnInit() {
    this.groupsList = pageConfig.config.groupsList;
    this.directToList = pageConfig.config.directToList;
    this.internalRoutesList = pageConfig.config.internalRoutesList;

    this.siteStatusList = pageConfig.config.siteStatusList;
    this.customerSegmentList = pageConfig.config.customerSegmentList;
    this.platformList = pageConfig.config.platformList;
    this.consumerList = pageConfig.config.consumerList;
    this.employeeList = pageConfig.config.employeeList;
    this.authorizedList = pageConfig.config.authorizedList;
    this.employeesmeList = pageConfig.config.employeesmeList;
    this.p1SiteStatuses = pageConfig.config.p1SiteStatuses;
    this.p2AndP3SiteStatuses = pageConfig.config.p2AndP3SiteStatuses;

  }

  submit() {
    if (this.isValid && !this.isAddedOnce) {
      this.isAddedOnce = true;
      if (!this.isEdit) {
        const newItem: ISideMenuItem = {};
        newItem.title = this.form.controls[SidemenuQuery.itemName].value;
        newItem.name = this.form.controls[SidemenuQuery.technicalName].value;
        newItem.description = this.description;
        newItem.iconName = this.selectedIcon;
        newItem.navigation = {};
        newItem.navigation.profile = this.isLight ? SidemenuQuery.light : SidemenuQuery.complete;

        if (this.isExternal) {
          newItem.navigation.type = SidemenuQuery.external;
          newItem.navigation.path = this.form.controls[SidemenuQuery.externalPath].value;
        } else if (this.isContengency) {
          newItem.navigation.type = SidemenuQuery.contingency;
          newItem.navigation.path = pageConfig.config.contingency.route;
        } else if (this.isOldPortal) {
          newItem.navigation.type = SidemenuQuery.oldPortal;
          newItem.navigation.path = this.form.controls[SidemenuQuery.externalPath].value;
        } else if (this.isECare) {
          newItem.navigation.type = SidemenuQuery.eCare;
          newItem.navigation.path = this.form.controls[SidemenuQuery.externalPath].value;
        } else {
          // newItem.navigation.profile get it from routes file (internal route + profile)
          newItem.navigation.type = SidemenuQuery.internal;
          newItem.navigation.path = this.internalPath;
        }

        newItem.category = this.selectedGroup;
        newItem.account = {};
        newItem.account.status = [];
        this.siteStatusListSelected.map(siteStatus => {
          newItem.account.status.push(siteStatus.itemName);
        });
        newItem.segments = [];
        this.customerSegmentListSelected.map(segment => {
          newItem.segments.push(segment.itemName);
        });
        newItem.account.accountTypes = [
          new AccountType('Consumer'),
          new AccountType('Authorized'),
          new AccountType('Employee'),
          new AccountType('Employee SME')
        ];
        if (this.consumerListSelected.length > 0) {
          this.consumerListSelected.map(consumerService => {
            newItem.account.accountTypes[0].serviceTypes.push({
              type: consumerService.itemName
            });
          });
        }

        if (this.authorizedListSelected.length > 0) {
          this.authorizedListSelected.map(authorizedService => {
            newItem.account.accountTypes[1].serviceTypes.push({
              type: authorizedService.itemName
            });
          });
        }

        if (this.employeeListSelected.length > 0) {
          this.employeeListSelected.map(employeeService => {
            newItem.account.accountTypes[2].serviceTypes.push({
              type: employeeService.itemName
            });
          });
        }

        if (this.employeeSMEListSelected.length > 0) {
          this.employeeSMEListSelected.map(employeesmeService => {
            newItem.account.accountTypes[3].serviceTypes.push({
              type: employeesmeService.itemName
            });
          });
        }

        let fromVersion = null;
        let toVersion = null;

        if (this.form.controls[SidemenuQuery.fromVersionFirst].valid) {
          fromVersion = this.form.controls[SidemenuQuery.fromVersionFirst].value + '.'
            + this.form.controls[SidemenuQuery.fromVersionSecond].value + '.'
            + this.form.controls[SidemenuQuery.fromVersionThird].value;
        }
        if (this.form.controls[SidemenuQuery.toVersionFirst].valid) {
          toVersion = this.form.controls[SidemenuQuery.toVersionFirst].value + '.'
            + this.form.controls[SidemenuQuery.toVersionSecond].value + '.'
            + this.form.controls[SidemenuQuery.toVersionThird].value;
        }

        newItem.apps = [];
        this.platformListSelected.map(platform => {
          newItem.apps.push({
            platform: platform.itemName,
            from: fromVersion,
            to: toVersion
          });
        });

        newItem.enabled = this.isEnabled;

        this.sidemenuConfigService.addItem(newItem).subscribe(
          () => {
            this.hide();
            this.submitted.emit(true);
          },
          error => {
            this.isAddedOnce = false;
            const notification = new Notification();
            const modal = this;
            this.translateService.
              get('home.messages')
              .subscribe(data => {
                notification.bodyTitle = data['delete-message-title'];
                notification.bodyContent = data['error-text'];
                notification.primaryButtonText = data['buttons']['sure'];
              });
            notification.primaryButtonStyle = 'btn registration';
            notification.primaryButtonClick = function () {
              modal.notificationService.notificationModal.hide();
            };
            this.notificationService.createNotification(notification);
          });

      } else {
        this.content.title = this.form.controls[SidemenuQuery.itemName].value;
        this.content.name = this.form.controls[SidemenuQuery.technicalName].value;
        this.content.description = this.description;
        this.content.iconName = this.selectedIcon;
        this.content.navigation.profile = this.isLight ? SidemenuQuery.light : SidemenuQuery.complete;

        if (this.isExternal) {
          this.content.navigation.type = SidemenuQuery.external;
          this.content.navigation.path = this.form.controls[SidemenuQuery.externalPath].value;
        } else if (this.isContengency) {
          this.content.navigation.type = SidemenuQuery.contingency;
          this.content.navigation.path = pageConfig.config.contingency.route;
        } else if (this.isOldPortal) {
          this.content.navigation.type = SidemenuQuery.oldPortal;
          this.content.navigation.path = this.form.controls[SidemenuQuery.externalPath].value;
        } else if (this.isECare) {
          this.content.navigation.type = SidemenuQuery.eCare;
          this.content.navigation.path = this.form.controls[SidemenuQuery.externalPath].value;
        } else {
          // newItem.navigation.profile get it from routes file (internal route + profile)
          this.content.navigation.type = SidemenuQuery.internal;
          this.content.navigation.path = this.internalPath;
        }

        this.content.account.status = [];
        this.siteStatusListSelected.map(siteStatus => {
          this.content.account.status.push(siteStatus.itemName);
        });
        this.content.segments = [];
        this.customerSegmentListSelected.map(segment => {
          this.content.segments.push(segment.itemName);
        });
        this.content.account.accountTypes = [
          new AccountType('Consumer'),
          new AccountType('Authorized'),
          new AccountType('Employee'),
          new AccountType('Employee SME')
        ];
        if (this.consumerListSelected.length > 0) {
          this.consumerListSelected.map(consumerService => {
            this.content.account.accountTypes[0].serviceTypes.push({
              type: consumerService.itemName
            });
          });
        }

        if (this.authorizedListSelected.length > 0) {
          this.authorizedListSelected.map(authorizedService => {
            this.content.account.accountTypes[1].serviceTypes.push({
              type: authorizedService.itemName
            });
          });
        }

        if (this.employeeListSelected.length > 0) {
          this.employeeListSelected.map(employeeService => {
            this.content.account.accountTypes[2].serviceTypes.push({
              type: employeeService.itemName
            });
          });
        }
        if (this.employeeSMEListSelected.length > 0) {
          this.employeeSMEListSelected.map(employeesmeService => {
            this.content.account.accountTypes[3].serviceTypes.push({
              type: employeesmeService.itemName
            });
          });
        }

        let fromVersion = null;
        let toVersion = null;

        if (this.form.controls[SidemenuQuery.fromVersionFirst].valid) {
          fromVersion = this.form.controls[SidemenuQuery.fromVersionFirst].value + '.'
            + this.form.controls[SidemenuQuery.fromVersionSecond].value + '.'
            + this.form.controls[SidemenuQuery.fromVersionThird].value;
        }
        if (this.form.controls[SidemenuQuery.toVersionFirst].valid) {
          toVersion = this.form.controls[SidemenuQuery.toVersionFirst].value + '.'
            + this.form.controls[SidemenuQuery.toVersionSecond].value + '.'
            + this.form.controls[SidemenuQuery.toVersionThird].value;
        }
        this.content.apps = [];
        this.platformListSelected.map(platform => {
          this.content.apps.push({
            platform: platform.itemName,
            from: fromVersion,
            to: toVersion
          });
        });

        this.content.enabled = this.isEnabled;

        this.sidemenuConfigService.editItem(this.content, this.selectedGroup).subscribe(
          () => {
            this.hide();
            this.submitted.emit(true);
          },
          error => {
            this.isAddedOnce = false;
            const notification = new Notification();
            const modal = this;
            this.translateService.
              get('home.messages')
              .subscribe(data => {
                notification.bodyTitle = data['delete-message-title'];
                notification.bodyContent = data['error-text'];
                notification.primaryButtonText = data['buttons']['sure'];
              });
            notification.primaryButtonStyle = 'btn registration';
            notification.primaryButtonClick = function () {
              modal.notificationService.notificationModal.hide();
            };
            this.notificationService.createNotification(notification);
          });

      }
    }
  }

  // check if selected status is p2 or p3 and hide services section
  onItemSelect(item, type) {
    if (type === 'siteStatus') {
      this.checkShowOrHideSection3();
    }
  }
  // check after deselect selected items don't contain p2 or p3 status
  checkShowOrHideSection3() {
    const isExsits = _.intersectionBy(this.p2AndP3SiteStatuses, this.siteStatusListSelected, 'itemName').length > 0
      && _.intersectionBy(this.p1SiteStatuses, this.siteStatusListSelected, 'itemName').length === 0;
    if (isExsits) {
      this.hideServicesSectionFn();
    } else {
      this.showServicesSectionFn();
    }
  }
  onItemDeSelect(item, type) {
    if (type === 'siteStatus') {
      this.checkShowOrHideSection3();
    }
  }

  onSelectAll(item, type) {
    switch (type) {
      case pageConfig.config.dropDownTypes.siteStatus:
        this.siteStatusListSelected = item.slice();
        this.checkShowOrHideSection3();
        break;
      case pageConfig.config.dropDownTypes.customerSegment: this.customerSegmentListSelected = item.slice(); break;
      case pageConfig.config.dropDownTypes.consumer: this.consumerListSelected = item.slice(); break;
      case pageConfig.config.dropDownTypes.authorized: this.authorizedListSelected = item.slice(); break;
      case pageConfig.config.dropDownTypes.employeeSME: this.employeeSMEListSelected = item.slice(); break;
      case pageConfig.config.dropDownTypes.employee: this.employeeListSelected = item.slice(); break;
      case pageConfig.config.dropDownTypes.platform: this.platformListSelected = item.slice(); break;
    }
  }
  onDeSelectAll(item, type) {
    switch (type) {
      case pageConfig.config.dropDownTypes.siteStatus:
        this.siteStatusListSelected = [];
        this.showServicesSectionFn();
        break;
      case pageConfig.config.dropDownTypes.customerSegment: this.customerSegmentListSelected = []; break;
      case pageConfig.config.dropDownTypes.consumer: this.consumerListSelected = []; break;
      case pageConfig.config.dropDownTypes.authorized: this.authorizedListSelected = []; break;
      case pageConfig.config.dropDownTypes.employeeSME: this.employeeSMEListSelected = []; break;
      case pageConfig.config.dropDownTypes.employee: this.employeeListSelected = []; break;
      case pageConfig.config.dropDownTypes.platform: this.platformListSelected = []; break;
    }
  }
  hideServicesSectionFn() {
    this.showServicesSection = false;
    this.authorizedListSelected = [];
    this.employeeSMEListSelected = [];
    this.consumerListSelected = [];
    this.employeeListSelected = [];
  }
  showServicesSectionFn() {
    this.showServicesSection = true;
  }
  public show(edit = false, content = null): void {
    this.isEdit = edit;
    this.modal.show();
    this.isAddedOnce = false;
    this.selectedDirection = SidemenuQuery.internal;
    this.directToChanged(this.selectedDirection);
    // load data
    if (!this.isEdit) {
      this.content = null;
      this.translateService.
        get('home.menu-config.page-two.create-title')
        .subscribe(data => {
          this.title = data;
        });
      /** add new item */
      this.form.controls[SidemenuQuery.itemName].setValue('');
      this.form.controls[SidemenuQuery.technicalName].setValue('');
      this.form.controls[SidemenuQuery.externalPath].setValue('');
      this.form.controls[SidemenuQuery.fromVersionFirst].setValue('');
      this.form.controls[SidemenuQuery.fromVersionSecond].setValue('');
      this.form.controls[SidemenuQuery.fromVersionThird].setValue('');
      this.form.controls[SidemenuQuery.toVersionFirst].setValue('');
      this.form.controls[SidemenuQuery.toVersionSecond].setValue('');
      this.form.controls[SidemenuQuery.toVersionThird].setValue('');
      this.selectedGroup = this.groupsList.length > 0 ? this.groupsList[0].value : '';
      this.internalPath = this.internalRoutesList.length > 0 ? this.internalRoutesList[0].value : '';
      this.description = '';
      this.isEnabled = true;
      this.selectedIcon = '';
      this.selectedDirection = SidemenuQuery.internal;
      this.siteStatusListSelected = [];
      this.customerSegmentListSelected = [];
      this.consumerListSelected = [];
      this.platformListSelected = [];
      this.authorizedListSelected = [];
      this.employeeSMEListSelected = [];
      this.employeeListSelected = [];
      this.isLight = false;
    } else {
      this.resetFlags();
      this.content = content;
      this.checkShowOrHideSection3();
      this.translateService.
        get('home.menu-config.page-two.edit-title')
        .subscribe(data => {
          this.title = data;
        });
      /** edit current item */
      this.isLight = this.content.navigation.profile.toLowerCase() === SidemenuQuery.light.toLowerCase();
      this.form.controls[SidemenuQuery.itemName].setValue(this.content.title);
      this.form.controls[SidemenuQuery.technicalName].setValue(this.content.name);
      if (this.content.navigation.type.toLowerCase() === SidemenuQuery.external.toLowerCase()) {
        this.isExternal = true;
        this.selectedDirection = SidemenuQuery.external;
        this.form.controls[SidemenuQuery.externalPath].setValue(this.content.navigation.path);
      } else if (this.content.navigation.type.toLowerCase() === SidemenuQuery.contingency.toLowerCase()) {
        this.selectedDirection = SidemenuQuery.contingency;
        this.isContengency = true;
      } else if (this.content.navigation.type.toLowerCase() === SidemenuQuery.oldPortal.toLowerCase()) {
        this.selectedDirection = SidemenuQuery.oldPortal;
        this.form.controls[SidemenuQuery.externalPath].setValue(this.content.navigation.path);
        this.isOldPortal = true;
      } else if (this.content.navigation.type.toLowerCase() === SidemenuQuery.eCare.toLowerCase()) {
        this.selectedDirection = SidemenuQuery.eCare;
        this.form.controls[SidemenuQuery.externalPath].setValue(this.content.navigation.path);
        this.isECare = true;
      } else {
        this.isExternal = false;
        this.selectedDirection = SidemenuQuery.internal;
        this.internalPath = this.content.navigation.path;
      }
      if (this.content.apps[0].from) {
        const from = this.content.apps[0].from;
        const fromV = from.split('.');
        this.form.controls[SidemenuQuery.fromVersionFirst].setValue(fromV[0]);
        this.form.controls[SidemenuQuery.fromVersionSecond].setValue(fromV[1]);
        this.form.controls[SidemenuQuery.fromVersionThird].setValue(fromV[2]);
      }
      if (this.content.apps[0].to) {
        const to = this.content.apps[0].to;
        const toV = to.split('.');
        this.form.controls[SidemenuQuery.toVersionFirst].setValue(toV[0]);
        this.form.controls[SidemenuQuery.toVersionSecond].setValue(toV[1]);
        this.form.controls[SidemenuQuery.toVersionThird].setValue(toV[2]);
      }
      this.selectedGroup = this.content.category;
      this.description = this.content.description;
      this.isEnabled = this.content.enabled;
      this.selectedIcon = this.content.iconName;

      this.siteStatusListSelected = [];
      this.siteStatusList.map(siteStatus => {
        this.content.account.status.map(y => {
          if (siteStatus.itemName.toLowerCase() === y.toLowerCase()) {
            this.siteStatusListSelected.push(siteStatus);
          }
        });
      });

      this.customerSegmentListSelected = [];
      this.customerSegmentList.map(segment => {
        this.content.segments.map(y => {
          if (segment.itemName.toLowerCase() === y.toLowerCase()) {
            this.customerSegmentListSelected.push(segment);
          }
        });
      });

      this.platformListSelected = [];
      this.platformList.map(platform => {
        this.content.apps.map(y => {
          if (platform.itemName.toLowerCase() === y.platform.toLowerCase()) {
            this.platformListSelected.push(platform);
          }
        });
      });

      this.authorizedListSelected = [];
      this.authorizedList.map(authorizedService => {
        this.content.account.accountTypes.map(y => {
          if (y.type.toLowerCase() === SidemenuQuery.authorized.toLowerCase()) {
            y.serviceTypes.map(a => {
              if (authorizedService.itemName.toLowerCase() === a.type.toLowerCase()) {
                this.authorizedListSelected.push(authorizedService);
              }
            });
          }
        });
      });


      this.employeeSMEListSelected = [];
      this.employeesmeList.map(employeesmeService => {
        this.content.account.accountTypes.map(y => {
          if (y.type.toLowerCase() === SidemenuQuery.employeeSME.toLowerCase()) {
            y.serviceTypes.map(a => {
              if (employeesmeService.itemName.toLowerCase() === a.type.toLowerCase()) {
                this.employeeSMEListSelected.push(employeesmeService);
              }
            });
          }
        });
      });

      this.consumerListSelected = [];
      this.consumerList.map(consumerService => {
        this.content.account.accountTypes.map(y => {
          if (y.type.toLowerCase() === SidemenuQuery.consumer.toLowerCase()) {
            y.serviceTypes.map(c => {
              if (consumerService.itemName.toLowerCase() === c.type.toLowerCase()) {
                this.consumerListSelected.push(consumerService);
              }
            });
          }
        });
      });

      this.employeeListSelected = [];
      this.employeeList.map(employeeService => {
        this.content.account.accountTypes.map(y => {
          if (y.type.toLowerCase() === SidemenuQuery.employee.toLowerCase()) {
            y.serviceTypes.map(b => {
              if (employeeService.itemName.toLowerCase() === b.type.toLowerCase()) {
                this.employeeListSelected.push(employeeService);
              }
            });
          }
        });
      });

      this.checkShowOrHideSection3();

    }
  }

  public hide(): void {
    this.isEdit = false;
    this.modal.hide();
  }


  ngAfterContentChecked(): void {
    if (this.isEdit) {
      this.translateService.
        get('home.menu-config.page-two.edit-title')
        .subscribe(data => {
          this.title = data;
        });

    } else {
      this.translateService.
        get('home.menu-config.page-two.create-title')
        .subscribe(data => {
          this.title = data;
        });
    }
    if ((!this.showServicesSection || (this.showServicesSection && (this.authorizedListSelected.length > 0 ||
      this.consumerListSelected.length > 0 ||
      this.employeeSMEListSelected.length > 0 ||
      this.employeeListSelected.length > 0))) &&
      this.customerSegmentListSelected.length > 0 &&
      this.siteStatusListSelected.length > 0 &&
      this.platformListSelected.length > 0 &&
      this.form.value[SidemenuQuery.itemName] &&
      this.form.value[SidemenuQuery.technicalName] &&
      this.versionsAreValid() &&
      this.description &&
      this.selectedGroup &&
      this.iconInput.query
    ) {
      if (this.isExternal || this.isOldPortal) {
        if (this.form.value[SidemenuQuery.externalPath]) {
          this.isValid = true;
        } else {
          this.isValid = false;
        }
      } else {
        this.isValid = true;
      }
    } else {
      this.isValid = false;
    }
  }

  versionsAreValid() {
    let result = false;
    if (// all are filled
      (this.form.value[SidemenuQuery.fromVersionFirst] &&
        this.form.value[SidemenuQuery.fromVersionSecond] &&
        this.form.value[SidemenuQuery.fromVersionThird]) &&
      (this.form.value[SidemenuQuery.toVersionFirst] &&
        this.form.value[SidemenuQuery.toVersionSecond] &&
        this.form.value[SidemenuQuery.toVersionThird])) {
      const from = {
        major: +(this.form.value[SidemenuQuery.fromVersionFirst]),
        minor: +(this.form.value[SidemenuQuery.fromVersionSecond]),
        build: +(this.form.value[SidemenuQuery.fromVersionThird])
      };
      const to = {
        major: +(this.form.value[SidemenuQuery.toVersionFirst]),
        minor: +(this.form.value[SidemenuQuery.toVersionSecond]),
        build: +(this.form.value[SidemenuQuery.toVersionThird])
      };
      if ((from.major < to.major)
        || (from.major === to.major && from.minor < to.minor)
        || (from.major === to.major && from.minor === to.minor && from.build <= to.build)) {
        result = true;
      }
    } else if (// from or to is filled
      (this.form.value[SidemenuQuery.fromVersionFirst] &&
        this.form.value[SidemenuQuery.fromVersionSecond] &&
        this.form.value[SidemenuQuery.fromVersionThird]) ||
      (this.form.value[SidemenuQuery.toVersionFirst] &&
        this.form.value[SidemenuQuery.toVersionSecond] &&
        this.form.value[SidemenuQuery.toVersionThird])
    ) {
      result = true;
    }
    return result;
  }

  hideModal() {
    this.modal.hide();
    this.showServicesSection = true;
  }
  private resetFlags() {
    this.isExternal = false;
    this.isContengency = false;
    this.isOldPortal = false;
    this.isECare = false;
    this.form.controls[SidemenuQuery.externalPath].setValue('');
  }
  directToChanged(selected) {
    this.resetFlags();
    this.selectedDirection = selected;
    switch (selected.toLowerCase()) {
      case SidemenuQuery.contingency.toLowerCase():
        this.isContengency = true; break;
      case SidemenuQuery.external.toLowerCase():
        this.isExternal = true; break;
      case SidemenuQuery.oldPortal.toLowerCase():
        this.isOldPortal = true; break;
      case SidemenuQuery.eCare.toLowerCase():
        this.isECare = true; break;
    }
  }
}
