import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class HomeService {

  constructor(private translate: TranslateService) { }

  flagCheckedChanged(event: boolean, keyName: string, allConfigs: any[]) {
    allConfigs[keyName] = event;
  }
  getFlagText(flagName) {
    let result = '';
    this.translate.get(`home.general-config.${flagName}.text`).subscribe(data => {
      result = data;
    });
    return result;
  }
  getFlagTooltip(flagName: string, allConfigs: any[]) {
    let result = '';
    this.translate.get(`home.general-config.${flagName}.tooltip`).subscribe(tooltip => {
      result = allConfigs[flagName] ? tooltip.on : tooltip.off;
    });
    return result;
  }
}
