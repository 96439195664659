import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { environmentLoader as environmentLoaderPromise } from './environments/environmentLoader';
import { LoggerLevel } from './app/common/enums/loggerLevel.enum';
import { API_URLS } from './app/common/constants/routes-config';

// El fichero de entorno se carga dinamicamente desde los ASSETS para que no sea dependiente de la imagen DOCKER montada
environmentLoaderPromise.then(env => {
  console.log("Environment Load in main.ts");

  //environment = Object.assign({}, env);
  Object.assign(environment, env.environment);
  Object.assign(API_URLS, env.API_URLS);

  if (environment.production) {
    enableProdMode();
    environment.level=LoggerLevel.OFF;
  }
  else
  {
    environment.level=LoggerLevel.TRACE;
  }

  console.log(API_URLS.Login.SAML_PROVIDER);
  
  platformBrowserDynamic().bootstrapModule(AppModule);
});

environmentLoaderPromise.catch(function () {
     console.log("Environment Load Error in main.ts");
});
