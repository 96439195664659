<div class="admin-top">
  <br />
  <p>{{'home.menu-config.title'|translate}}</p>
  <hr />
</div>
<div *ngIf="showError" class="row">
  <div class="error-url col-lg-8 offset-md-1 col-md-10 offset-md-1 col-12">
    <span class="icon-warning"></span> Cannot enter the same Data twice</div>
</div>
<div class="friendly-url">
  <form (ngSubmit)="addURL($event)" [formGroup]="configsForm">
    <div class="row">
      <div class="col-lg-8 offset-md-1 col-md-10 offset-md-1 col-12 toggle-container">
        <div class="admin-top">
          <p>Link Name</p>
          <div class="container">
            <sp-text formControlName="LinkName"></sp-text>
          </div>
        </div>
        <div class="admin-top">
          <p>Firebase Link</p>
          <div class="container">
            <sp-text formControlName="FirebaseLink"></sp-text>
          </div>
        </div>
        <div class="admin-top">
          <p>Enhanced Link</p>
          <div class="new-url">{{appUrl}}</div>
          <label class="container">
            <label class="container typeahead">
              <input formControlName="EnhancedLink" id="enhancedText" class="form-control copyToClipboredInput"
                #copyInput>
              <span (click)="copyToClipbored(copyInput.value)" class="copyToClipboredIcon">
              </span>
            </label>
          </label>
        </div>
        <div class="clearfix"></div>

      </div>
      <div class="configuration-btn col-lg-8 offset-md-1 col-md-10 offset-md-1 col-12">
        <button [disabled]="!(configsForm.controls['FirebaseLink'].value && configsForm.controls['EnhancedLink'].value&&configsForm.controls['LinkName'].value)"
          [ngClass]="{'gray-add':!(configsForm.controls['FirebaseLink'].value && configsForm.controls['EnhancedLink'].value&&configsForm.controls['LinkName'].value)}"
          type="submit">Save</button>
      </div>
      <div *ngIf="showEditError" class="row">
        <div class="error-url col-lg-8 offset-md-1 col-md-10 offset-md-1 col-12">
          <span class="icon-warning"></span> Cannot enter the same Data twice</div>
      </div>
    </div>
  </form>
  <div *ngIf="urls&&urls.length>0">
    <div class="col-lg-8 offset-md-1 col-md-10 offset-md-1 col-12 toggle-container table-urls">
      <div>

        <input class="form-control search-url" [(ngModel)]="filterChar" />
        <div class="clearfix"></div>
      </div>

      <div class="row" *ngFor="let url of urls|filter:filterChar">
        <div>
          <hr class="table-hr" />
          <div class="links-card col-md-10">
            <div class="url-desc">
              <span class="desc">Link Name :</span>
              <span class="url-text">{{url.name}}</span>
              <div class="clearfix"></div>

            </div>
            <div class="url-desc">
              <span class="desc">Firebase Link :</span>
              <span class="setting-config">{{url.destination}}</span>
              <div class="clearfix"></div>
            </div>
            <div class="url-desc">
              <span class="desc">Enhanced Link :</span>
              <span class="url-text">{{url.sourceLink}}</span>
              <div class="clearfix"></div>
            </div>
          </div>
          <div class="links-card col-md-2">
            <div class="url-desc">
              <span class="icon icon-close" (click)="delete(url)"></span>
              <span class="icon icon-edit" (click)="edit(url)"></span>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>

<div bsModal #modal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
  aria-hidden="true" [config]="{keyboard: false,backdrop : 'static'}">
  <div class="small modal-dialog allow-events">
    <div class="modal-content">
      <div class="modal-header" *ngIf="showCloseIcon">
        <button type="button" class="close pull-right" aria-label="Close" (click)="hide()">
          <span class='icon icon-close modal-close'></span>
        </button>
      </div>
    </div>
    <div class="modal-body modal-edit">
      <div>
        <div class="col-12 title-edit">
          Edit
          <div class="close-edit" (click)="hide()">
            <span class="icon-close"></span>
          </div>
          <hr class="hr-modal" />

          <div class="clearfix"></div>
        </div>
        <div class="description-form col-12">
          <form (ngSubmit)="EditURL()" [formGroup]="editForm">
            <div class="form-group edit-btn">
              <div class="admin-top">
                <p>Link Name</p>
                <label class="container">
                  <input required placeholder="Edit Name" formControlName="NameEdit" class="form-control edit-form-control">
                </label>
              </div>
              <div class="admin-top">
                <p>Firebase Link</p>
                <label class="container">
                  <input required placeholder="Edit Firebase Link" value="{{editURL?.destination}}" formControlName="FirebaseLinkEdit"
                    class="form-control edit-form-control">
                </label>
              </div>
              <div class="admin-top">
                <p>Enhanced Link</p>
                <label class="container">
                  <input required placeholder="Edit Enhanced Link" value="{{editURL?.sourceLink}}" formControlName="EnhancedLinkEdit"
                    class="form-control edit-form-control">
                </label>
              </div>
            </div>
            <div>
            </div>
            <div class="row modal-buttons">
              <div class="form-group col-10 offset-1 col-lg-6 offset-md-3 col-md-8 offset-md-2">
                <button class="button  two" [ngClass]="{'gray-add':!editForm.valid,'red':editForm.valid}" [disabled]="!editForm.valid">Save</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>