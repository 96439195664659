import { Component, ViewChild, Input } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'sp-modal',
  templateUrl: './sp-modal.component.html',
  styleUrls: ['./sp-modal.component.scss']
})
export class SpModalComponent {
  @ViewChild('modal', { static: true }) public modal: ModalDirective;
  /**
     *  modal body Title (defult empty string)
     */
  @Input()
  public bodyTitle: String = '';
  /**
  *  modal body Content  (defult empty string)
  */
  @Input()
  public bodyContent: String = '';

  /**
   * Indicate whether modal has primaryButton or not (defult not)
   */
  @Input()
  public primaryButton = false;
  /**
   * Indicate whether modal has secondaryButton or not (defult not)
   */
  @Input()
  public secondaryButton = false;
  @Input()
  public showCloseIcon = false;
  /**
   *  modal primaryButton text (defult empty string)
   */
  @Input()
  public primaryButtonText: String = '';
  /**
   *  modal secondaryButton text (defult empty string)
   */
  @Input()
  public secondaryButtonText: String = '';
  /**
   *  modal primaryButton style (defult empty string)
   */
  @Input()
  public primaryButtonStyle: String = '';
  /**
   *  modal secondaryButton style (defult empty string)
   */
  @Input()
  public secondaryButtonStyle: String = '';
  /**
   *  modal body Icon url (defult empty string)
   */
  @Input()
  public bodyIcon: String = '';
  /**
   *  modal primaryButtonClick event will equal to function and invoked on button click
   */
  @Input()
  public primaryButtonClick: Function;
  /**
   *  modal secondaryButtonClick event will equal to function and invoked on button click
   */
  @Input()
  public secondaryButtonClick: Function;
  /**
 *
 */
  @Input()
  public closeButtonClick: Function;

  @Input() modelImg: string;

  /**
   * Pointer to Button openModal (ElementRef)
   */
  public show(): void {
    this.modal.show();
  }

  public hide(): void {
    this.modal.hide();
    if (typeof this.closeButtonClick === 'function') {
      this.closeButtonClick();
    }
  }

}
