
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { JSON_PATHS, CONFIG } from '../constants/defines';
import * as JsonQuery from 'jsonpath';
import * as Routes from '../constants/routes-config';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { StorageService } from './storage.service';
import { GDPRConfig } from '../../models/gdbr-config.model';
import { BonitaTypes } from '../enums/bonita-types.enum';
import { LatestBuildModel } from '../../models/latest-build.model';
import { TopUpDiscriminationModel } from '../../models/top-up-discrimination.model';
import { ProductConfigurationModel } from '../../models/product-configuration.model';

/**
 * Configuration Service Used to load Configuration file that Url set in environment and returns
 *  configuration variable that contains configuration
 */
@Injectable()
export class ConfigurationService {

  private configuration: any;
  private gdprConfiguration: GDPRConfig[];
  private bonitaConfigurations: any;
  private latestBuildsConfigurations: LatestBuildModel;
  private topUpDiscriminationConfig: TopUpDiscriminationModel;
  private productConfigurations: ProductConfigurationModel[];


  /**
   * constructor inject http service in to it
   */
  constructor(private http: HttpClient, private storageService: StorageService) { }
  /**
   * Load function responsible for load configuration file from server config path based on environment
   */
  public load(): Promise<any> {
    const config = new Promise((resolve, reject) => {
      setTimeout(() => {
        let headers = new HttpHeaders();
        headers = headers.append('Accept', 'application/json');
        headers = headers.append('Authorization', 'Basic ' + btoa(environment.CLIENT_ID));

        const config = {
          headers: headers
        };
        this.http.get(Routes.API_URLS.CONFIGURATIONS.CONFIG_GET.replace('{type}', 'All'), config).subscribe(response => {
          this.configuration = this.transformConfig(response);
          this.gdprConfiguration = response['gdprConfigurations'];
          this.bonitaConfigurations = response['bonitaConfiguration'];
          this.productConfigurations = response['productConfiguration'];
          this.latestBuildsConfigurations = {
            ios: JsonQuery.value(response, JSON_PATHS.forceUpdate.ios),
            android: JsonQuery.value(response, JSON_PATHS.forceUpdate.android)
          };
          this.topUpDiscriminationConfig = new TopUpDiscriminationModel();
          this.topUpDiscriminationConfig.topupNewPCI = JsonQuery.value(response, JSON_PATHS.topUpDescrimination.topupNewPCI)
          this.topUpDiscriminationConfig.topupNewPCIMsisdnPattern = JsonQuery.value(response, JSON_PATHS.topUpDescrimination.topupNewPCIMsisdnPattern)
          this.topUpDiscriminationConfig.smartPayMSIDNPattern = JsonQuery.value(response, JSON_PATHS.topUpDescrimination.smartPayMSIDNPattern)
          resolve(this.configuration);
        });
      });
    });
    /**
     * promise all wait until all promises returns then return results
     */
    return Promise.all([config]);
  }
  /**
   * Get Configuration variable loaded from load function
   */
  getConfigurations() {
    return this.configuration;
  }
  getGDPRConfigurations() {
    return this.gdprConfiguration;
  }
  getTopUpDiscriminationConfig() {
    return this.topUpDiscriminationConfig;
  }
  getLatestBuilds() {
    return this.latestBuildsConfigurations;
  }
  getBonitaConfigurations() {
    if (this.bonitaConfigurations) {
      return {
        mobile: this.bonitaConfigurations.find(config => config.type && config.type.toLowerCase() === BonitaTypes.Movil.toString().toLowerCase()),
        internet: this.bonitaConfigurations.find(config => config.type && config.type.toLowerCase() === BonitaTypes.Internet.toString().toLowerCase()),
        fixed: this.bonitaConfigurations.find(config => config.type && config.type.toLowerCase() === BonitaTypes.Fixed.toString().toLowerCase()),
        tv: this.bonitaConfigurations.find(config => config.type && config.type.toLowerCase() === BonitaTypes.TV.toString().toLowerCase()),
      }
    }
    else {
      return {}
    }


  }
  getProductConfiguration(): ProductConfigurationModel[] {
    return this.productConfigurations;
  }
  /**
   * Patch Configuration method that update configuration on server with new value
   * @param configuration the object that we need to change on server with the new value
   * @returns bool that indicate patch success or fails
   */
  patchConfiguration(configuration: object): Observable<any> {
    if (configuration != null && Object.keys(configuration).length > 0) {
      /** SWAT-611_Logic-change-in-unavailability-toggle */
      configuration = { ...configuration };
      /** change tobi flag for the toggles configurations only (don't send it with other configurations like PCI, topup, ..etc) */
      if (Object.keys(configuration).find(key => key === 'tobiChatUnAvailability')) {
        configuration['tobiChatUnAvailability'] = !configuration['tobiChatUnAvailability'];
      }
      if(Object.keys(configuration).find(key => key === 'transactionalConfig') && Object.keys(configuration['transactionalConfig']).find(key => key === 'transactionalJourneysAvailable')) {
        (configuration['transactionalConfig'])['transactionalJourneysAvailable'] = configuration['transactionalJourneysAvailable'];
      }
      /** change paymentUnavailability flag for the toggles configurations only (don't send it with other configurations like PCI, topup, ...etc) */
      if (Object.keys(configuration).find(key => key === 'paymentUnavailability')) {
        configuration['paymentUnavailability'] = !configuration['paymentUnavailability'];
      }
      let headers = new HttpHeaders({ 'Content-Type': 'application/merge-patch+json' });
      headers = headers.append('Authorization', 'Bearer ' + this.storageService.accessToken);
      return this.http.patch(Routes.API_URLS.CONFIGURATIONS.CONFIG_PATCH,
        JSON.stringify(configuration), { headers: headers }).pipe(map(response => {
          return response;
        }));
    }
  }

  /**
   * Map the JSON object returned from the backend
   * to Configuration model
   */
  private transformConfig(config: any): any {
    const configurationModel = {};
    if (config) {
      for (const flag of JSON_PATHS.Configurations) {
        configurationModel[flag] = config[flag] || false;
      }
      /** SWAT-611_Logic-change-in-unavailability-toggle */
      configurationModel['tobiChatUnAvailability'] = !configurationModel['tobiChatUnAvailability'];
      configurationModel['transactionalJourneysAvailable'] = (config['transactionalConfig'])['transactionalJourneysAvailable'];
      configurationModel['flowAvailability'] = (config['transactionalConfig'])['flowAvailability'];
      configurationModel['transactionalConfig'] = config['transactionalConfig'];
      /** CIKS-784 Deactivate Payments section from Admin Tool */
      configurationModel['paymentUnavailability'] = !configurationModel['paymentUnavailability'];
    }
    return configurationModel;
  }

}
