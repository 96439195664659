export class DeepLinkNewPage {
    public id?: string;
    public name?: string;
    public tagName?: string;
    public nativeUrl?: string;
    public desktopUrl?: string;
    public destination?: string;
    public webUrl?: string;
    public hideFromDeepLink?: boolean;
    public serviceTypes?: string[];
    public included?: Array<any>;
}
