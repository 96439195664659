import { FormBuilder } from '@angular/forms';
import { ConfigurationService } from './../../common/services/configuration.service';
import { FormGroup } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { JSON_PATHS, PAGES } from '../../common/constants/defines';
import { NotificationService } from '../../common/services/notification.service';
import { Notification } from '../../models/notification.model';
import { Error } from '../../models/error.model';
import { ErrorHandlingService } from '../../common/services/errorHandling.service';
import { HomeService } from '../home.service';
@Component({
  selector: 'sp-general-config-business',
  templateUrl: './general-config-business.component.html',
  styleUrls: ['./general-config-business.component.scss']
})
export class GeneralConfigBusinessComponent implements OnInit {
  allConfigs: any;
  error: Error;
  flagsNames: string[];

  constructor(private configurationService: ConfigurationService, private homeService: HomeService,
    private notificationService: NotificationService,
    private errorHandling: ErrorHandlingService) { }

  ngOnInit() {
    this.allConfigs = this.configurationService.getConfigurations();
    this.flagsNames = Object.keys(this.allConfigs);
    this.flagsNames.splice(this.flagsNames.indexOf('networkLogin'), 1);
    this.flagsNames.splice(this.flagsNames.indexOf('appAvailability'), 1);
    this.flagsNames.splice(this.flagsNames.indexOf('enterprisesLoginAvailability'), 1);
    this.flagsNames.splice(this.flagsNames.indexOf('ocsFlag'), 1);
    this.flagsNames.splice(this.flagsNames.indexOf('billingFlag'), 1);
    this.flagsNames.splice(this.flagsNames.indexOf('biztalkAvailabilityFlag'), 1);
    this.flagsNames.splice(this.flagsNames.indexOf('clarifyFlag'), 1);
    this.flagsNames.splice(this.flagsNames.indexOf('genevaFlag'), 1);
    this.flagsNames.splice(this.flagsNames.indexOf('tobiChatUnAvailability'), 1);
    this.flagsNames.splice(this.flagsNames.indexOf('topupBackAvailable'), 1);
    this.flagsNames.splice(this.flagsNames.indexOf('webNewImplFlagPCI'), 1);
    this.flagsNames.splice(this.flagsNames.indexOf('androidAppVersionPCI'), 1);
    this.flagsNames.splice(this.flagsNames.indexOf('iosAppVersionPCI'), 1);
    this.flagsNames.splice(this.flagsNames.indexOf('transactionalJourneysAvailable'), 1);
    this.flagsNames.splice(this.flagsNames.indexOf('pegaAvailable'), 1);
    this.flagsNames.splice(this.flagsNames.indexOf('flowAvailability'), 1);
    this.flagsNames.splice(this.flagsNames.indexOf('transactionalConfig'), 1);
  }

  saveAllSettings() {
    const form = this;
    this.configurationService.patchConfiguration(this.allConfigs).subscribe(respone => {
      const notification = new Notification();
      notification.primaryButtonClick = function () {
        form.notificationService.notificationModal.hide();
      };
      notification.bodyContent = 'Configuration Saved Successfully';
      notification.bodyTitle = 'Update Configuration';
      notification.primaryButtonText = 'Close';
      notification.primaryButtonStyle = 'btn registration';
      this.notificationService.createNotification(notification);
    },
      error => {
        this.error = this.errorHandling.lookUpError(PAGES.CONFIG, '1001');
        if (this.error) {
          console.log('Enabled Error: ', this.error);
          const notification = new Notification();
          notification.primaryButtonClick = function () {
            form.notificationService.notificationModal.hide();
          };
          notification.bodyContent = this.error.description;
          notification.bodyTitle = this.error.title;
          notification.primaryButtonText = this.error.confirmButtonText;
          notification.primaryButtonStyle = 'btn registration';
          this.notificationService.createNotification(notification);
        }
      });
  }

  flagCheckedChanged(event: boolean, keyName: string) {
    this.homeService.flagCheckedChanged(event, keyName, this.allConfigs);
  }
  getFlagText(flagName) {
    return this.homeService.getFlagText(flagName);
  }
  getFlagTooltip(flagName) {
    return this.homeService.getFlagTooltip(flagName, this.allConfigs);
  }
}
