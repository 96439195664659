import { chatQuery } from './../../common/constants/defines';
import { groupBy, Dictionary, sortBy } from 'lodash';
import { ChatConfig } from './../../models/chat-config.model';
import { ChatConfigService } from './../../common/services/chat-config.service';
import { ChatConfigNewItemComponent } from './../../common/components/chat-config-new-item/chat-config-new-item.component';
import { NotificationService } from './../../common/services/notification.service';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Notification } from '../../models/notification.model';
import * as pageConfig from '../../../config/pages-config';
import { MultiSelect } from '../../models/multi-select.model';

@Component({
    selector: 'sp-chat-config',
    templateUrl: './chat-config.component.html',
    styleUrls: ['../menu-config/menu-config.component.scss']
})
export class ChatConfigComponent implements OnInit {
    @ViewChild('notification', { static: true }) notification: ChatConfigNewItemComponent;
    userTypesList: MultiSelect[] = [];
    userTypesListSelected: MultiSelect[] = [];
    serviceTypesList: MultiSelect[] = [];
    serviceTypesListSelected: MultiSelect[] = [];
    profileTypesList: MultiSelect[] = [];
    profileTypesListSelected: MultiSelect[] = [];
    selectedItemsIDs: string[] = [];
    filteredPages: ChatConfig[];

    constructor(private chatConfigService: ChatConfigService) { }

    ngOnInit() {
        this.userTypesList = pageConfig.config.userTypeList;
        this.serviceTypesList = pageConfig.config.serviceTypeList;
        this.profileTypesList = pageConfig.config.profileTypeList;

        this.userTypesListSelected = this.userTypesList.slice();
        this.serviceTypesListSelected = this.serviceTypesList.slice();
        this.profileTypesListSelected = this.profileTypesList.slice();

        this.refreshMenu(true);
    }

    isSelectAllChecked() {
        if (this.selectedItemsIDs.length === this.filteredPages.length)
            return ['0'];
        return [];
    }
    radioSelectAllClick() {
        if (this.selectedItemsIDs.length === this.filteredPages.length) {
            this.selectedItemsIDs = [];
        } else {
            this.selectedItemsIDs = [];
            this.selectedItemsIDs.push(...this.filteredPages.map(el => el.id));
        }
    }
    radioClick(item: ChatConfig) {
        /** item has null at first time, so must be checked firstly */
        if (item) {
            if (item.module) {
                /** get its mparent module */
                const parentModule = this.filteredPages.find(module => module.name === item.module);
                if (this.selectedItemsIDs.includes(item.id)) {
                    this.selectedItemsIDs.splice(this.selectedItemsIDs.indexOf(item.id), 1);
                    if (parentModule) {
                        /** splice its parent module if exists */
                        if (this.selectedItemsIDs.includes(parentModule.id)) {
                            this.selectedItemsIDs.splice(this.selectedItemsIDs.indexOf(parentModule.id), 1);
                        }
                    }
                } else {
                    this.selectedItemsIDs.push(item.id)
                    if (parentModule) {
                        /** add its parent module if not exists in case all children selected */
                        const allChildren = this.filteredPages.filter(page => page.module === parentModule.name);
                        const isAllChildrenSelected = !allChildren.find(child => !this.selectedItemsIDs.includes(child.id));
                        if (isAllChildrenSelected) {
                            this.selectedItemsIDs.push(parentModule.id);
                        }
                    }
                }
            } else {
                const childItems = this.filteredPages.filter(el => el.module === item.name);
                const childIDs = childItems.map(el => el.id);
                if (this.selectedItemsIDs.includes(item.id)) {
                    this.selectedItemsIDs = this.selectedItemsIDs.filter(selectedId => selectedId !== item.id &&
                        !childIDs.includes(selectedId));
                } else {
                    this.selectedItemsIDs.push(item.id, ...childIDs);
                }
            }
        }
    }
    addNew() {
        this.notification.show();
    }
    editItem(item) {
        this.notification.show([item]);
    }
    editMultiItems() {
        this.notification.show(this.filteredPages.filter(el => el.module && this.selectedItemsIDs.includes(el.id)).slice());
    }

    /** we can delet module if every page inside the module deleted 
     * this function delete every page inside module
     * then refresh menu
     */
    deleteMultiItems(): void {
        let selecteditems: ChatConfig[] = this.filteredPages.filter(el => el.module && this.selectedItemsIDs.includes(el.id)).slice();
        selecteditems.forEach(el => {
            this.deleteItem(el)
        });
        this.refreshMenu(true);
        this.selectedItemsIDs = [];
    }
    deleteItem(item: any): void {
        this.chatConfigService.deleteItem(item).subscribe()
    }
    /** this flag tell us that call API or not? */
    public refreshMenu(callingAPI = false) {
        if (callingAPI) {
            this.chatConfigService.loadChatConfiguration().subscribe((configs: ChatConfig[]) => {
                this.filterItems();
            });
        } else {
            this.filterItems();
        }
    }
    private filterItems() {
        const isUserTypesSelectedAll = this.userTypesListSelected.length === this.userTypesList.length;
        const isServiceTypesSelectedAll = this.serviceTypesListSelected.length === this.serviceTypesList.length;
        const isProfileTypesSelectedAll = this.profileTypesListSelected.length === this.profileTypesList.length;
        /** incase that all criteria selected --> display all pages */
        if (isUserTypesSelectedAll && isServiceTypesSelectedAll && isProfileTypesSelectedAll) {
            this.filteredPages = this.chatConfigService.chatConfigItems.slice();
        } else {
            this.filteredPages = this.chatConfigService.chatConfigItems.slice();
            /** check if user types have any unselected type */
            if (!isUserTypesSelectedAll) {
                const selectedUsers = this.userTypesListSelected.map(el => el.itemName).join();
                this.filteredPages = this.filteredPages.slice().filter(el => {
                    return el.criteria.find(criteria => (criteria.userType && selectedUsers.toLowerCase().includes(criteria.userType.toLowerCase()))
                        || (!criteria.userType && selectedUsers.toLowerCase().includes(chatQuery.unassigned.toLowerCase())))
                });
            }
            /** check if service types have any unselected type */
            if (!isServiceTypesSelectedAll) {
                const selectedServices = this.serviceTypesListSelected.map(el => el.itemName).join();
                this.filteredPages = this.filteredPages.filter(el => {
                    return el.criteria.find(criteria => !!this.serviceTypesListSelected.find(service => {
                        return !!criteria.serviceTypes.find(type => type.toLowerCase() === service.itemName.toLowerCase())
                            || (!criteria.serviceTypes.length && selectedServices.includes(chatQuery.unassigned.toLowerCase()))
                    }));
                })
            }
            /** check if profile types have any unselected type */
            if (!isProfileTypesSelectedAll) {
                const selectedProfiles = this.profileTypesListSelected.map(el => el.itemName).join();
                this.filteredPages = this.filteredPages.slice().filter(el => {
                    return el.criteria.find(criteria => (criteria.profileType && selectedProfiles.toLowerCase().includes(criteria.profileType.toLowerCase()))
                        || (!criteria.profileType && selectedProfiles.toLowerCase().includes(chatQuery.unassigned.toLowerCase())))
                });
            }
        }

        this.filteredPages = sortBy(this.filteredPages, chatQuery.module.toLowerCase());
        /** Add parents as pages */
        const groupedPages: Dictionary<ChatConfig[]> = groupBy(this.filteredPages, chatQuery.module.toLowerCase());
        const keys = Object.keys(groupedPages);
        for (let keyIndex in keys) {
            this.filteredPages.splice(
                this.filteredPages.indexOf(this.filteredPages.find(page => page.id === groupedPages[keys[keyIndex]][0].id)),
                0,
                new ChatConfig(keyIndex + 1, groupedPages[keys[keyIndex]][0].module));
        }
    }

    onItemSelect(item: MultiSelect, type) {
        this.filterItems();
    }
    onItemDeSelect(item: MultiSelect, type: string) {
        switch (type) {
            case pageConfig.config.filterCriteria.user:
                this.userTypesListSelected = this.userTypesListSelected.filter(el => el.id !== item.id); break;
            case pageConfig.config.filterCriteria.service:
                this.serviceTypesListSelected = this.serviceTypesListSelected.filter(el => el.id !== item.id); break;
            case pageConfig.config.filterCriteria.profile:
                this.profileTypesListSelected = this.profileTypesListSelected.filter(el => el.id !== item.id); break;
        }
        this.filterItems();
    }
    onSelectAll(item: MultiSelect, type: string) {
        switch (type) {
            case pageConfig.config.filterCriteria.user: this.userTypesListSelected = this.userTypesList.slice(); break;
            case pageConfig.config.filterCriteria.service: this.serviceTypesListSelected = this.serviceTypesList.slice(); break;
            case pageConfig.config.filterCriteria.profile: this.profileTypesListSelected = this.profileTypesList.slice(); break;
        }
        this.filterItems();
    }
    onDeSelectAll(item: MultiSelect, type: string) {
        switch (type) {
            case pageConfig.config.filterCriteria.user: this.userTypesListSelected = []; break;
            case pageConfig.config.filterCriteria.service: this.serviceTypesListSelected = []; break;
            case pageConfig.config.filterCriteria.profile: this.profileTypesListSelected = []; break;
        }
        this.filterItems();
    }

    download() {
        this.chatConfigService.exportToExcel();
    }
}
