import { environment } from '../../../environments/environment';

export const CONFIG = {
    // authentication scopes append in authentication url
    //CLIENT_ID: environment.CLIENT_ID,    --> environment will be used
    SCOPES: ['ES_APP_CONFIGURATION_ALL',
        'ES_ADMIN_CONFIG_ALL',
        'DXL_ES_APP_SETTINGS',
        'ES_ADMIN_DOWNLOAD_ALL'
        //,'ES_GROUP_SETTINGS_ALL'
    ],
    COUNTRY_CODE: 'ES'
};
export const GRANT_TYPE = {
    PASSWORD: 'password',
    REFRESH_TOKEN: 'refresh_token'
};
export const LOCAL_STORAGE_PREFIX = 'mcare-LocalStore.key';
export const LOCAL_STORAGE_KEYS = {
    ACCESS_TOKEN: 'accessToken',
    ACCESS_TOKEN_EXP: 'accessTokenExpirationTime',
    JWT: 'jwt',
    REFRESH_TOKEN: 'refreshToken',
    REFRESH_TOKEN_EXP: 'refreshTokenExpirationTime',
    SETTINGS: 'settings',
    USERNAME: 'username'
};
export const JSON_PATHS = {
    USERPROFILE: {
        NAME: '$.userProfile.name',
        IS_ADMIN: '$.userProfile.isAdmin',
        GROUPS: '$.groups',
        ROLES: '$.roles'
    },
    Configurations: [// All these flags names must be the same with flags names returned from back-end
        'networkLogin',
        'appAvailability',
        'enterprisesLoginAvailability',
        'billingFlag',
        'biztalkAvailabilityFlag',
        'clarifyFlag',
        'genevaFlag',
        'ocsFlag',
        'showBillsLink',
        'chatEnabled',
        'webNewImplFlagPCI',
        'androidAppVersionPCI',
        'iosAppVersionPCI',
        'tobiChatUnAvailability',
        'topupBackAvailable',
        'transactionalJourneysAvailable',
        'showEverythingIsOk',
        'showHappy',
        'pegaAvailable',
        'showOneNumberSSO',
        'showSecureNetSSO',
        'paymentUnavailability'
    ],
    LOGIN: {
        SUCCESS: {
            JWT: '$.jws',
            ACCESS_TOKEN: '$.access_token',
            REFRESH_TOKEN: '$.refresh_token',
            REFRESH_TOKEN_EXPIRES: '$.refresh_token_expires',
            ACCESS_TOKEN_EXPIRES: '$.refresh_token_expires',
            TOKEN_TYPE: '$.token_type',
            EXPIRES_AT: '$.expires_at',
            EXPIRES_IN: '$.expires_in',


        }
    },
    MENUITEM: {
        ID: '$.id',
        NAME: '$.name',
        TITLE: '$.title',
        ENABLED: '$.enabled',
        DESC: '$.description',
        ICON_NAME: 'iconName',
        CATEGORY: '$.category',
        ORDER: '$.order',
        APPS: '$.apps',
        NAVIGATION: '$.navigation',
        ACCOUNT: '$.account',
        SEGMENT: '$.segments',
        PARENT_ID: 'parentId'
    },
    URLITEMS: {
        ITEMS: '$.items',
        ID: '$.id',
        NAME: '$.name',
        SOURCELINK: '$.sourceLink',
        DESTINATION: '$.destination',
        GOTOMARKET: '$.gotomarket',
        STATUS: '$.status',
        EXPIRATION: '$.modifications.expiration',
        SERVICETYPES: '$.serviceTypes',
        INCLUDE: '$.included'
    },
    ADDNEWPAGEDEEPLINK: {
        PAGES: '$.pages',
        ID: '$.id',
        NAME: '$.name',
        TAGNAME: '$.tagName',
        NATIVEURL: '$.nativeUrl',
        WEBURL: '$.webUrl',
        DESTINATION: '$.destination',
        DESKTOPURL: '$.desktopUrl',
        SERVICETYPES: '$.serviceTypes',
        HIDEFROMDEEPLINK: '$.hideFromDeepLink',
        INCLUDED: '$.included'
    },
    KEYVALUECONFIG: {
        CATEGORIES: '$.categories',
        ID: '$.id',
        NAME: '$.name',
        KEY: '$.key',
        VALUE: '$.value',
    },
    CHATCONFIG: {
        ID: '$.id',
        NAME: '$.name',
        TAGGINGNAME: '$.taggingName',
        MODULE: '$.module',
        CRITERIA: '$.criteria'
    },
    USERPERMISSIONS: {
        ITEMS: '$.items',
        GROUPNAME: '$.groupName',
        PERMISSIONS: '$.permissions',
    },
    forceUpdate: {
        ios: '$.latestVersion.ios',
        android: '$.latestVersion.android',
    },
    topUpDescrimination: {
        topupNewPCI: '$.topupNewPCI',
        topupNewPCIMsisdnPattern: '$.topupNewPCIMsisdnPattern',
        smartPayMSIDNPattern: '$.smartPayMSIDNPattern'
    }
};

export const PAGES = {
    LOGIN: 'login',
    CONFIG: 'home.generalConfig'
};

export const SidemenuQuery = {
    accountTypes: 'accountTypes',
    serviceTypes: 'serviceTypes',
    iconName: 'iconName',
    itemName: 'itemName',
    technicalName: 'technicalName',
    external: 'External',
    internal: 'Internal',
    contingency: 'Contingency',
    oldPortal: 'OldPortal',
    eCare: 'ECare',
    externalPath: 'externalPath',
    light: 'Light',
    complete: 'Complete',
    consumer: 'Consumer',
    authorized: 'Authorized',
    employee: 'employee',
    employeeSME: 'Employee SME',
    fromVersionFirst: 'fromVersionFirst',
    fromVersionSecond: 'fromVersionSecond',
    fromVersionThird: 'fromVersionThird',
    toVersionFirst: 'toVersionFirst',
    toVersionSecond: 'toVersionSecond',
    toVersionThird: 'toVersionThird'

};
export const locationHeader = {
    Location: 'Location'
};

export const appsettingsApi = {
    url: 'es/v1/appSettings/settings'
};

export const shortURL = {
    url: 'm.vodafone.es/'
};
export const shortURLForDeeplink = {
    url: 'https://m.vodafone.es/mves/'
};
export const chatQuery = {
    pageName: 'pageName',
    unassigned: 'unassigned',
    module: 'module'
};

export const platform = [
    { text: "Android", value: 'android' },
    { text: "IOS", value: 'ios' },
    { text: "All", value: 'all' }
]

export const osType = {
    android: 'android',
    ios: 'ios',
    all: 'all'
};

export const patterns = {
    nonNegative: '^\\d+$',
    numbersAndCommasOnly:'^[0-9,]*$',
    urlParameters: '^\\?([_A-Za-z0-9-+.,]+=[_A-Za-z0-9-+.,%]+)(&[_A-Za-z0-9-+.,]+=[_A-Za-z0-9-+.,%]+)*$',
};

export const rowStatus = {
    status: 'expired'
};

export const paymentTypes = [
    { text: "Top-up Payments", value: 'TOPUP' },
    { text: "Bill Payments", value: 'BILL' },
    { text: "All", value: 'ALL' }
]

export const universalDeeplinksHeaders = [
    "ID",
    "Name",
    "URL corta",
    "Pantalla",
    "Ir al market",
    "Estado",
    "Fecha expiración",
]

export const universalLinkStatus = {
    ACT: "ACT",
    DES: "DES"
}

export const EXPIRATION_DATE = '2037-12-31';
export const FORM_TYPE_ACTION = {
    ADD: "add",
    EDIT: "edit"
}
export const FIFTEENMIN = 600000;

export const SCHAMAN_MODAL = {
    ok: 'Datos enviados con exito',
    ko: 'Ha ocurrido un eror, los datos no han sido enviados.'
};
