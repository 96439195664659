import { ISideMenuItem } from './../../../models/sidemenuItem.model';
import { Component, OnInit, Input, forwardRef, AfterContentChecked, EventEmitter, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, FormControl, NG_VALIDATORS } from '@angular/forms';
import { ChatConfig } from '../../../models/chat-config.model';
/**Just empty definition for a function */
const noop = () => {
};
/**custom validation function call in every change */
export function createRadioButtonValidator(required: boolean) {
  return (c: FormControl) => {
    if (required) {
      return c.value ? null : {};
    }
    else {
      return null;
    }
  };
}
@Component({
  selector: 'sp-radio-button',
  templateUrl: './sp-radio-button.component.html',
  styleUrls: ['./sp-radio-button.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SpRadioButtonComponent),
    multi: true
  },
  {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => SpRadioButtonComponent),
    multi: true
  }]
})
export class SpRadioButtonComponent implements OnInit, ControlValueAccessor, AfterContentChecked {
  /**Placeholders for the callbacks which are later providesd by the Control Value Accessor */
  onTouchedCallback: () => void = noop;
  onChangeCallback: (_: any) => void = noop;
  /**array of choices that will bind in radio buttons */
  @Input() dataSource: ISideMenuItem[] | ChatConfig[];
  /**group name of radio button group */
  @Input() groupName: string;
  /**flag to identify whether we should choose radio button or not */
  @Input() required: boolean;
  /**flag to identify whether there is a default value checked or not */
  @Input() selectedItem: ISideMenuItem | ChatConfig;
  /**for checkbox multi select */
  @Input() selectedItemsIDs: string[];
  /**error message */
  @Input() message: string;
  /**value to show delet icon */
  @Input() delete: boolean;
  /** value to show edit icon */
  @Input() edit: boolean;
  /**empty function that will hold the validation function */
  validateFn: any;
  /**the inner value of radio button */
  innerValue: any;
  @Input() one: boolean;
  @Input() isCheckBox: boolean;
  @Output() radioClick: EventEmitter<ISideMenuItem | ChatConfig> = new EventEmitter();
  @Output() editClick: EventEmitter<ISideMenuItem | ChatConfig> = new EventEmitter();
  @Output() deleteClick: EventEmitter<ISideMenuItem | ChatConfig> = new EventEmitter();
  /**function that will call only when initialize to updat
   * refresh the inner value in case of it is not like the old inner value.
   * @param  {any} The text value.
   */
  writeValue(value: any): void {
    if (value) {
      this.innerValue = value;
    }
  }
  /**function that will be called with every change in form*/
  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;

  }
  /**function that will be called with every touch of form control*/
  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }
  /**function that will call onChangeCallback to validat the form after any change*/
  ngAfterContentChecked() {
    this.onChangeCallback(this.innerValue);
  }

  /**
   * function that will update the inner value with the current choice
   * @param choice
   */
  updateForm(choice: ISideMenuItem | ChatConfig) {
    this.innerValue = choice;
  }
  /**this function will be called after any change to validate the form */
  validate(c: FormControl) {
    return this.validateFn(c);
  }
  /**function that will run when initialize the component */
  ngOnInit() {
    if (this.selectedItem) {
      if (this.dataSource.length > 0) {
        this.innerValue = this.selectedItem;
      }
    }
    this.validateFn = createRadioButtonValidator(this.required);
  }
  onClick(value) {
    this.radioClick.emit(value);
  }
  onDeleteClick(value) {
    this.deleteClick.emit(value);
  }
  onEditClick(value) {
    this.editClick.emit(value);
  }
}
