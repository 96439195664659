import { Component } from '@angular/core';

@Component({
  selector: 'sp-footer',
  templateUrl: './sp-footer.component.html',
  styleUrls: ['./sp-footer.component.scss']
})
export class SpFooterComponent {

  constructor() { }


}
