
import {of as observableOf,  Observable } from 'rxjs';

import {map,  mergeMap } from 'rxjs/operators';
import { UrlConfigService } from './../../common/services/url-config.service';
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { AddNewPageDeepLinkService } from '../../common/services/add-new-page-deep-link.service';

@Injectable()
export class URLConfigResolver implements Resolve<any> {

    constructor(private urlConfig: UrlConfigService,private addnewpageDeepLinkservice: AddNewPageDeepLinkService) { }
    resolve() {
            return this.urlConfig.getAllURLS().pipe(mergeMap(() => {
                return this.addnewpageDeepLinkservice.getAllURLS().pipe(map(res=>{
                    return observableOf(true);
                }))
            }));
    }
}
