import { Component, ElementRef, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'sp-icon-auto-complete',
  templateUrl: './icon-auto-complete.component.html',
  styleUrls: ['./icon-auto-complete.component.scss']
})
export class SpIconAutoCompleteComponent {
  @Input() label: string;
  @Input() id = '';
  @Input() public query = '';
  @Output('onSelect')
  onSelect: EventEmitter<string> = new EventEmitter<string>();
  @Input() list = [
    'icon-accessories',
    'icon-add-or-plus',
    'icon-apps',
    'icon-arrow-down',
    'icon-arrow-left',
    'icon-arrow-right',
    'icon-arrow-up',
    'icon-attachment',
    'icon-bill-or-report',
    'icon-birthday-greeting',
    'icon-block',
    'icon-blogs64',
    'icon-broadband-or-wifi',
    'icon-business',
    'icon-business-phone',
    'icon-calendar',
    'icon-camera',
    'icon-chat',
    'icon-chatbot-robot',
    'icon-cherries-points',
    'icon-chevron-down',
    'icon-chevron-left',
    'icon-chevron-right',
    'icon-chevron-up',
    'icon-clock-or-timed',
    'icon-close',
    'icon-cloud-download',
    'icon-cloud-upload',
    'icon-comment',
    'icon-community-or-foundation',
    'icon-completed',
    'icon-confidential',
    'icon-contact-us',
    'icon-country-or-international',
    'icon-customers-or-users',
    'icon-dashboard',
    'icon-data',
    'icon-delete',
    'icon-delivery',
    'icon-desktop',
    'icon-dislike',
    'icon-document',
    'icon-download',
    'icon-edit',
    'icon-enterprise',
    'icon-entertainment',
    'icon-error-circle',
    'icon-error-simple',
    'icon-fiber',
    'icon-filter',
    'icon-folder',
    'icon-grid-view',
    'icon-group',
    'icon-info-icon',
    'icon-healthy-living',
    'icon-help-circle',
    'icon-hide-password',
    'icon-home',
    'icon-info-circle',
    'icon-insurance',
    'icon-landline-or-call',
    'icon-like',
    'icon-list-view',
    'icon-location',
    'icon-log-in',
    'icon-log-out',
    'icon-mail-new',
    'icon-mail-read',
    'icon-mbb',
    'icon-menu',
    'icon-microphone',
    'icon-minus-or-less',
    'icon-mobile',
    'icon-more',
    'icon-my-vodafone-or-profile',
    'icon-need-help',
    'icon-network-signal',
    'icon-notification',
    'icon-notification',
    'icon-pause-circle',
    'icon-payg',
    'icon-payment',
    'icon-photos',
    'icon-pie-chart',
    'icon-ping',
    'icon-play-arrow',
    'icon-play-circle',
    'icon-postpaid',
    'icon-prepaid',
    'icon-print',
    'icon-privacy-or-confidential',
    'icon-ratings-or-favourite',
    'icon-refresh',
    'icon-reply',
    'icon-reports',
    'icon-roaming',
    'icon-search',
    'icon-security',
    'icon-settings',
    'icon-setup',
    'icon-share',
    'icon-shopping-checkout',
    'icon-shopping-trolley',
    'icon-sim',
    'icon-social-chat',
    'icon-social-facebook',
    'icon-social-google-plus',
    'icon-social-instagram',
    'icon-social-linkedin',
    'icon-social-paypal',
    'icon-social-pinterest',
    'icon-social-twitter',
    'icon-social-youtube',
    'icon-sound',
    'icon-sound-off',
    'icon-stop',
    'icon-stop-circle',
    'icon-store-offers',
    'icon-student',
    'icon-sync',
    'icon-tablet',
    'icon-tethering',
    'icon-text',
    'icon-tick-or-solved',
    'icon-tick-outline',
    'icon-top-up',
    'icon-topics',
    'icon-tv',
    'icon-upgrade',
    'icon-upload',
    'icon-video',
    'icon-viewed',
    'icon-virus-protection',
    'icon-voice-of-vodafone-alerts',
    'icon-warning',
    'icon-website',
    'icon-weight',
    'icon-wifi',
    'icon-zoom-in',
    'icon-zoom-out',
    'icon-cup',
    'icon-engineer',
    'icon-converged-proposition'
  ];
  public filteredList = [];
  public elementRef;
  public arrow = 'icon-chevron-down';

  constructor(myElement: ElementRef) {
    this.elementRef = myElement;
  }

  toggle() {
    if (this.arrow === 'icon-chevron-up') {
      this.filteredList = [];
      this.arrow = 'icon-chevron-down';
    } else {
      this.filteredList = this.list;
      this.arrow = 'icon-chevron-up';
    }
    document.getElementById('list').focus();
  }

  filter() {
    if (this.query !== '') {
      this.filteredList = this.list.filter(function (el) {
        return el.toLowerCase().indexOf(this.query.toLowerCase()) > -1;
      }.bind(this));
    } else {
      this.filteredList = this.arrow === 'icon-chevron-up' ? this.list : [];
    }
  }

  select(item) {
    this.query = item;
    this.onSelect.emit(item);
    this.filteredList = [];
    this.arrow = 'icon-chevron-down';
  }
}
