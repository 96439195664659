import { Component, Output, EventEmitter, Input, forwardRef, SimpleChange, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'sp-check-box',
  templateUrl: './sp-check-box.component.html',
  styleUrls: ['./sp-check-box.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SpCheckBoxComponent),
    multi: true
  }]
})
export class SpCheckBoxComponent implements ControlValueAccessor  {

  /**event fired when click on check box */
  @Output()
  option: EventEmitter<boolean> = new EventEmitter();
/**label */
 @Input()
  label = '';
/**id for checkbox */
 @Input()
  id = '';
/**name for checkbox */
 @Input()
  name;
/**flag show if check box checked or not */
 @Input()
 check: boolean;
 /**flag show if check box disabled or not */
 @Input()
 isDisabled: boolean;
 /**
  * toggles the check that represents the toggle button current value
  * then emits the value
  */
  writeValue(obj: any): void {
  }
  registerOnChange(fn: any): void {
  }
  registerOnTouched(fn: any): void {
  }
  setDisabledState?(isDisabled: boolean): void {
  }
toggle() {
   this.check = !this.check;
   this.option.emit(this.check);
}

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['isDisabled']) {
      this.isDisabled = changes['isDisabled'].currentValue
    }
  }

}
