import { AppService } from './../app.service';
import { UserPermissions } from './../models/user-permissions.model';
import { config } from './../../config/pages-config';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Notification } from '../models/notification.model';
import { NotificationService } from '../common/services/notification.service';
import { GroupNames } from '../common/enums/group-names.enum';
import { StorageService } from '../common/services/storage.service';
@Component({
  selector: 'sp-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  home: string = config.home.name;
  isAdminUser: boolean;
  constructor(private router: Router, private route: ActivatedRoute, private notificationService: NotificationService,
    private storageService: StorageService, private appService: AppService) { }
  ngOnInit(): void {
    this.isAdminUser = this.storageService.userProfile && this.storageService.userProfile.isAdmin;
    if (this.storageService.userProfile && this.storageService.userProfile.groups.length === 1) {
      if (this.storageService.userProfile.groups[0].toLowerCase() === GroupNames.AOToken) {
        this.router.navigate([config.generalconfigao.name], { relativeTo: this.route });
      } else {
        this.router.navigate([config.generalconfigbusiness.name], { relativeTo: this.route });
      }
    } else {
      this.router.navigate([config.generalconfigbusiness.name], { relativeTo: this.route });
    }
     this.appService.userPermissions = new Array<UserPermissions>();
    // commented as it is not part of relase 5.6
    // this.userPermissionsService.getUserPermissions().subscribe(res => {
    //   this.storageService.userProfile.groups.filter(groupName => {
    //     switch (groupName) {
    //       case GroupNames.AdministradorToken:
    //         this.appService.userPermissions.push(this.userPermissionsService.userPermissions.find(permissions => {
    //           return permissions.groupName.toLowerCase() === GroupNames.Administrator.toLowerCase();
    //         }));
    //         break;
    //       case GroupNames.NegocioToken:
    //         this.appService.userPermissions.push(this.userPermissionsService.userPermissions.find(permissions => {
    //           return permissions.groupName.toLowerCase() === GroupNames.Negocio.toLowerCase();
    //         }));
    //         break;
    //       case GroupNames.AOToken:
    //         this.appService.userPermissions.push(this.userPermissionsService.userPermissions.find(permissions => {
    //           return permissions.groupName.toLowerCase() === GroupNames.AO.toLowerCase();
    //         }));
    //         break;

    //       default:
    //         break;
    //     }

    //   });

    // }, error => {
    //   this.router.navigate([config.login.route]);
    //   this.authenticateService.logout();
    // });

  }
  getPermissions(PageName) {
    let enable = false;
    this.appService.userPermissions.forEach(item => {
      item.permissions.forEach(permission => {
        if (permission.resourceName.toLowerCase() === PageName.toLowerCase()) {
          if (permission.enabled) {
            enable = permission.enabled;
            return enable;
          }
        }
      });
    });
    // commented as it is not part of relase 5.6
   // return enable;
   return true;
  }
  goTo(pageName: string) {
    switch (pageName) {
      case 'general-config-business':
        this.router.navigate([config.generalconfigbusiness.name], { relativeTo: this.route });
        break;
      case 'general-config-ao':
        this.router.navigate([config.generalconfigao.name], { relativeTo: this.route });
        break;
      case 'flows-config-ao':
        this.router.navigate([config.flowsconfigao.name], { relativeTo: this.route });
        break;
      case 'force-update':
        this.router.navigate([config.forceupdate.name], { relativeTo: this.route });
        break;
      case 'menu-config':
        this.router.navigate([config.menuconfig.name], { relativeTo: this.route });
        break;
      case 'url-config':
        this.router.navigate([config.urlconfig.name], { relativeTo: this.route });
        break;
      // case 'beautify-link-config':
      //   this.router.navigate([config.beautifyLinkconfig.name], { relativeTo: this.route });
      //   break;
      case 'chat-config':
        this.router.navigate([config.chatBubbleConfig.name], { relativeTo: this.route });
        break;
      case 'gdpr-properties-config':
        this.router.navigate([config.gdprPropertiesconfig.name], { relativeTo: this.route });
        break;
      case 'bonita-config':
        this.router.navigate([config.bonitaConfig.name], { relativeTo: this.route });
        break;
      case 'schaman-config':
        this.router.navigate([config.schamanConfig.name], { relativeTo: this.route });
        break;
      case 'user-permissions':
        this.router.navigate([config.userPermissions.name], { relativeTo: this.route });
        break;
      case 'latest-builds':
        this.router.navigate([config.latestBuilds.name], { relativeTo: this.route });
        break;
      case 'top-up-decsrimination':
        this.router.navigate([config.TopUpDiscrimination.name], { relativeTo: this.route });
        break;
      case 'payment-csv':
        this.router.navigate([config.paymentCSV.name], { relativeTo: this.route });
        break;
      case 'add-new-page-deeplink':
        this.router.navigate([config.AddNewPAgeDeepLink.name], { relativeTo: this.route });
        break;
      case 'product-config':
        this.router.navigate([config.productConfig.name], { relativeTo: this.route });
        break;
      case 'chat-module-page-config':
        this.router.navigate([config.ChatModulePageConfig.name], { relativeTo: this.route });
        break;
      case 'wallet-info':
        this.router.navigate([config.walletInfo.name], { relativeTo: this.route });
        break;

    }
  }

  onClick() {
    const notification = new Notification();
    const modal = this;
    notification.bodyContent = 'Something went wrong, data wasn`t saved successfully';
    notification.primaryButtonText = 'Cancel';
    notification.secondaryButtonText = 'Sure';
    notification.primaryButtonStyle = 'btn registration';
    notification.primaryButtonClick = function () {
      modal.notificationService.notificationModal.hide();

    };
    notification.secondaryButtonClick = function () {
      modal.notificationService.notificationModal.hide();

    };
    this.notificationService.createNotification(notification);
  }
}
