
import {tap} from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

import { HttpResponse } from '@angular/common/http';
import { StorageService } from '../services/storage.service';
import { environment } from '../../../environments/environment';
import { AuthenticateService } from '../authentication/authenticate.service';
import { LOCAL_STORAGE_KEYS, appsettingsApi } from '../constants/defines';
import { API_URLS } from '../constants/routes-config';



@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {
    // using pure functions to create pipeline request
    requestInterceptors: ((req: HttpRequest<any>) => HttpRequest<any>)[] = [];
    // using pure functions to create pipeline response
    responseInterceptors: ((req: HttpResponse<any>) => HttpResponse<any>)[] = [];
    authenticateService: AuthenticateService;
    constructor(private storage: StorageService, private injector: Injector)/*private authenticateService: AuthenticateService,*/ {
        const that = this;
        setTimeout(function () {
            that.authenticateService = injector.get(AuthenticateService);
            that.responseInterceptors.push(res => {
                return res;
            });
        });

        this.requestInterceptors.push(req => {
            let newReq = req;
            if (newReq.url.indexOf(environment.middlewareBaseUrl) > -1) {
                // if (newReq.url.indexOf(API_URLS.Login.SESSION_START) === -1) {
                //     newReq = newReq.clone({
                //         headers: newReq.headers.set('app-language', 'sp')
                //     });
                // }
                if (newReq.url.indexOf(appsettingsApi.url) === -1) {
                    newReq = newReq.clone({
                        headers: newReq.headers.set('Authorization',
                            'Bearer ' + that.storage.getStorage(LOCAL_STORAGE_KEYS.ACCESS_TOKEN))
                    });
                }

                if (environment.environmentName) {
                    newReq = newReq.clone({
                        headers: newReq.headers.set('vf-target-environment', 'aws-' + environment.environmentName + '-es')
                    });
                }
            }
            return newReq;
        });
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const that = this;
        // intercept request
        const newReq = this.requestInterceptors.reduce((r, fn) => fn(r), request);
        // return request and intercept response if there
        return next.handle(newReq || request).pipe(tap(event => {
            if (event instanceof HttpResponse) {
                const newRes = this.responseInterceptors.reduce((response, fn) => fn(response), event);
            }
        }));

    }

}
