import { Component, OnInit } from '@angular/core';
import { IDropdown } from '../../common/components/sp-dropdown/IDropdown.interface';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { paymentTypes } from '../../common/constants/defines';
import { PaymentCsvService } from '../../common/services/payment-csv.service';
import { UtilsService } from '../../common/utils/utils.service';

@Component({
  selector: 'sp-export-payment-csv',
  templateUrl: './export-payment-csv.component.html',
  styleUrls: ['./export-payment-csv.component.scss']
})
export class ExportPaymentCsvComponent implements OnInit {
  paymentTypes: IDropdown[];
  exportPaymentForm: FormGroup;
  selectedPaymentType: string;

  constructor(private formBuilder: FormBuilder,
    private paymentCsvService: PaymentCsvService,
    private utiliy: UtilsService) { }

  ngOnInit() {
    this.exportPaymentForm = this.formBuilder.group({
      paymentType: ['', Validators.required],
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
    });

    this.paymentTypes = paymentTypes;
    this.selectedPaymentType = this.paymentTypes[0].value;
  }

  exportCSV() {
    if (this.exportPaymentForm.valid) {
      const formData = this.exportPaymentForm.controls;
      const type = formData['paymentType'].value;
      const startDate = this.utiliy.getFormattedDateForAPI(new Date(formData['startDate'].value));
      const endDate = this.utiliy.getFormattedDateForAPI(new Date(formData['endDate'].value));
      this.paymentCsvService.exportToCSV(type, startDate, endDate);
    }
  }
}
