import { Component, OnInit, Input, forwardRef, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, FormControl } from '@angular/forms';
/**
 * custome validateors to call every time changes happens
 * @param pattern {string} value to vaildate formComtrol against this pattern
 * @param required {boolean} value to vaildate formComtrol that it is required
 * returns null if FormControl is valid
 * returns object if FormControl is not valid
 */
export function createTextBoxValidator(pattern: string, required: boolean) {
  const _pattern = new RegExp(pattern);
  return (c: FormControl) => {
    if (_pattern && required) {
      return (c.value && _pattern.test(c.value)) ? null : { mismatch: true };
    } else if (_pattern && c.value) {
      return (_pattern.test(c.value)) ? null : { mismatch: true };
    } else {
      return null;
    }
  };
}
const fn = () => { };
@Component({
  selector: 'sp-text',
  templateUrl: './sp-text.component.html',
  styleUrls: ['./sp-text.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SpTextComponent),
      multi: true
    }
    , {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => SpTextComponent),
      multi: true
    }
  ]
})
export class SpTextComponent implements OnInit, ControlValueAccessor {

  // boolean to show or hide popup
  isShow = false;
  /**isPassword  if true type will be password otherwise will be text */
  @Input()
  public isPassword: boolean;
  /**this will appear if pattern not equal empty string and the innervalue doesn't match the pattern   */
  @Input()
  patternErrorMessage = '';
  /**this will appear if isRequired not equal false and the innervalue equal empty string   */
  @Input()
  requiredErrorMessage = '';
  /** flag to show or hide password icon */
  @Input()
  showPasswordIcon = false;
  /**flag to show or hide info icon */
  @Input()
  showInfoIcon = false;
  /**flag to show or hide copy icon */
  @Input()
  showCopyIcon = false;
  /**flag to hash value in text box if ispassword true or not hashed */
  @Input()
  disable: boolean;
  public hashed = false;
  @Input()
  placeholder = '';
  /** The text box label. */
  @Input()
  label = '';
  /**  to show information for the text box  */
  @Input()
  infoText = '';
  /** The flag which indicates if the textbox required or not. */
  @Input()
  isRequired: boolean;
  /** The flag which indicates if the textbox with pattern or not. */
  @Input()
  pattern: string;
  /** The infoIconDetails carries info message*/
  @Input()
  infoIconDetails: string;
  @Input()
  isUpperCase = false;
  /** The inner value which holds the textbox text. */
  innerValue = '';
  /** The inner value which holds the textbox text. */
  @Input()
  isMatch = false;
  /**textbox id */
  @Input()
  id = '';
  @Input()
  textType: string;
  @Input()
  disabled: boolean;
  type: string;
  /** The textTypeStatus whether it is password or normal text. */
  private TextboxType = {
    text: 'text',
    password: 'password',
    date: 'date',
    number: 'number'
  };
  /**the max length of the input text  */
  @Input()
  max: number;
  @Input()
  enforceValidation = false;
  @Output() copyIconClicked = new EventEmitter<string>();
  /** the initial inner value  */
  @Input() inputValue: string;
  /** The call back which will be called if the user focus on the textbox */
  onTouched: () => void = fn;
  /** The call back which will be called if the user change any text on the textbox */
  onChanged: (_: any) => void = fn;

  /**this function used to be eq */
  validateFn: any = () => { };
  isDateInput: boolean;
  /**
    * refresh the inner value in case of it is not like the old inner value and the new value not equal undefined.
    * @param  {any} The text value.
  */
  writeValue(value: any): void {
    if (value !== undefined) {
      this.innerValue = value;
    }
  }
  /**
  * call the onChanged to refresh the value for the parent component.
   * @param  {any} The callback value.
   */
  // tslint:disable-next-line:no-shadowed-variable
  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }
  /**
  * call the onTouched to refresh the value for the parent component.
   * @param  {any} The callback value.
     */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  /**
   * called when value changed to validate th value
   * @param {c} Form Control to be validated
   */
  validate(c: FormControl) {
    return this.validateFn(c);
  }
  /**
   * call the blur when component not foucsed
   */
  onBlur() {
    this.onChanged(this.innerValue);

  }
  /**
   * call change when value changed
   */
  Change() {
    this.onChanged(this.innerValue);
  }
  /**empty constructor */
  constructor() { }
  /**
   * check if ispassword true if true type will be password otherwise will be text
   * validateFn function to be equal custom validator createTextBoxValidator
   */
  ngOnInit() {
    if (this.isPassword) {
      this.type = this.TextboxType.password;
      this.hashed = true;
    } else if (this.textType === 'number') {
      this.type = this.TextboxType.number;
    } else if (this.textType === 'date') {
      this.type = this.TextboxType.date;
      this.isDateInput = true;
    } else {
      this.type = this.TextboxType.text;
    }
    this.validateFn = createTextBoxValidator(this.pattern, this.isRequired);
  }
  /**
   * call toggleIcon when click on passwodIcon to hashed value or not
   */
  toggleIcon() {
    if (this.type === this.TextboxType.text) {
      this.type = this.TextboxType.password;
      this.hashed = true;
    } else {
      this.type = this.TextboxType.text;
      this.hashed = false;
    }
  }
  /* function to open the popup  */
  public openPopup() {
    if (!this.isShow) {
      this.isShow = true;
    }
  }
  /* function to close the popup  */
  public closePopup() {
    if (this.isShow) {
      this.isShow = false;
    }
  }
  copyClick() {
    this.copyIconClicked.emit(this.innerValue);
  }
}
