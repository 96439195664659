<div bsModal #modal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
  aria-hidden="true" [config]="{keyboard: false,backdrop : 'static'}">
  <div class="side-menu modal-dialog" style="padding-top: 5%">
    <div class="modal-content">
      <form [formGroup]="form" novalidate>
        <fieldset>
          <span class="title">{{'home.chat-config.title'|translate}}</span>
          <div class="modal-body">
            <div class="body">

              <div class="control-container">
                <!-- if there is only one item to edit or incase creating a new item, this div should be displayed -->
                <div *ngIf="selectedItemsToEdit?.length <= 1">


                  <div class="row">
                    <div class="col-12">
                      <div class="row">
                        <sp-dropdown [selectedValue]="selectedModule" (change)="selectedModule = $event.target.value"
                          label="{{'home.chat-config.page-two.fields.module'|translate}}" [items]="modulesList">
                        </sp-dropdown>
                      </div>
                    </div>
                  </div> 
                </div>
                <div class="row section-3 align-contaner">
                  <sp-toggle-button [check]="istoggleActive" (option)="changeToggle($event)"></sp-toggle-button>
                  <span class="toggle-label">{{'home.menu-config.page-two.field-set.section5.0'|translate}}</span>
                  </div>
                <div class="section-3">
                  <span>{{'home.chat-config.page-two.criteria'|translate}}</span> <span class="hint">{{'home.chat-config.page-two.criteria-hint'|translate}}</span>
                  <div class="dropdown-groups">
                    <div class="col-12 col-md-6">
                        <sp-check-box [isDisabled]="!istoggleActive" [check]="islightboxChecked" [name]="'light'" [id]="'light'" [label]="'home.chat-config.page-two.fields.consumer-light'|translate" (option)="checkToggle($event,'light')">
                        </sp-check-box>
                    </div>
                    <div class="col-12 col-md-6">
                        <sp-check-box [isDisabled]="!istoggleActive" [check]="isfullboxChecked" [name]="'full'" [id]="'full'" [label]="'home.chat-config.page-two.fields.consumer-full'|translate" (option)="checkToggle($event,'full')">
                        </sp-check-box>
                    </div>
                    <div class="col-12 col-md-6">
                        <sp-check-box [isDisabled]="!istoggleActive" [check]="isauthorizedboxChecked" [name]="'authorized'" [id]="'authorized'"   [label]="'home.chat-config.page-two.fields.authorized'|translate" (option)="checkToggle($event,'authorized')">
                        </sp-check-box>
                    </div>
                    <div class="col-12 col-md-6">
                        <sp-check-box [isDisabled]="!istoggleActive" [check]="isemployeeboxChecked" [name]="'employee'"  [id]="'employee'" [label]="'home.chat-config.page-two.fields.business'|translate" (option)="checkToggle($event,'employee')">
                        </sp-check-box>
                    </div>
                  </div>
                </div>


              </div>
            </div>
          </div>
        </fieldset>
        <div class="buttons-form">
          <div class="form-group form-group col-md-4 offset-md-3 col-12">
            <button (click)="hideModal()" class="button gray two">{{'home.chat-config.page-two.buttons.cancel-button'|translate}}</button>
          </div>
          <div class=" form-group col-md-4 col-12 ">
            <button type="button" class="button" [ngClass]="{'red two':isValid}" [disabled]="!isValid" (click)="submit()">{{'home.chat-config.page-two.buttons.save-button'|translate}}</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>