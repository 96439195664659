import { Component, OnInit, ViewChild } from '@angular/core';
import { SpModalComponent } from './common/components/sp-modal/sp-modal.component';
import { NotificationService } from './common/services/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { LoggerService } from './common/utils/logger.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  @ViewChild('notification', { static: true }) notification: SpModalComponent;

  title = 'app';
  constructor(private notificationService: NotificationService, private translate: TranslateService, private logger: LoggerService) {
       // this language will be used as a fallback when a translation isn't found in the current language
     translate.setDefaultLang('sp');
     // the lang to use, if the lang isn't available, it will use the current loader to get them
     translate.use('sp');

  }
  ngOnInit(): void {
    this.logger.info('start app');
    this.notificationService.notificationModal = this.notification;
     //this.authenticateService.authenticate('','');
  }

}
