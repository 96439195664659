<div class="ui-autocomplete">
    <div class="form-group col-12">
        <label for="{{id}}" class="my-label">{{label}}</label>
        <input id="list" type="text" class="validate form-control" [(ngModel)]=query (keyup)=filter()>
        <span class="admin-arrow icon " [ngClass]="arrow" (click)="toggle()"></span>
    </div>
    <div class="menu col-12" *ngIf="filteredList.length > 0">
        <ul class="ul-menu col-12">
            <li class="ul-menu-item" *ngFor="let item of filteredList" (click)="select(item)">
                <a class="ui-state"><i class="icon " [ngClass]="item"  > {{item}}</i></a>
            </li>
        </ul>
    </div>
</div>