import { ConfigurationService } from './../../common/services/configuration.service';
import { Component, OnInit } from '@angular/core';
import { JSON_PATHS, PAGES } from '../../common/constants/defines';
import { NotificationService } from '../../common/services/notification.service';
import { Notification } from '../../models/notification.model';
import { Error } from '../../models/error.model';
import { ErrorHandlingService } from '../../common/services/errorHandling.service';
import { HomeService } from '../home.service';

@Component({
  selector: 'sp-general-config-ao',
  templateUrl: './general-config-ao.component.html',
  styleUrls: ['./general-config-ao.component.scss'],

})
export class GeneralConfigAoComponent implements OnInit {

  allConfigs: any;
  error: Error;
  flagsNames: string[];
  constructor(private configurationService: ConfigurationService, private homeService: HomeService,
    private notificationService: NotificationService,
    private errorHandling: ErrorHandlingService) { }

  ngOnInit() {
    this.allConfigs = this.configurationService.getConfigurations();
    this.flagsNames = Object.keys(this.allConfigs);
    this.flagsNames.splice(this.flagsNames.indexOf('networkLogin'), 1);
    this.flagsNames.splice(this.flagsNames.indexOf('showBillsLink'), 1);
    this.flagsNames.splice(this.flagsNames.indexOf('chatEnabled'), 1);
    this.flagsNames.splice(this.flagsNames.indexOf('webNewImplFlagPCI'), 1);
    this.flagsNames.splice(this.flagsNames.indexOf('androidAppVersionPCI'), 1);
    this.flagsNames.splice(this.flagsNames.indexOf('iosAppVersionPCI'), 1);
    this.flagsNames.splice(this.flagsNames.indexOf('showEverythingIsOk'), 1);
    this.flagsNames.splice(this.flagsNames.indexOf('showHappy'), 1);
    this.flagsNames.splice(this.flagsNames.indexOf('flowAvailability'), 1);
    this.flagsNames.splice(this.flagsNames.indexOf('showOneNumberSSO'), 1);
    this.flagsNames.splice(this.flagsNames.indexOf('showSecureNetSSO'), 1);
    this.flagsNames.splice(this.flagsNames.indexOf('transactionalConfig'), 1);
    this.flagsNames.splice(this.flagsNames.indexOf('paymentUnavailability'), 1);
  }
//rerun code
  saveAllSettings() {
    const form = this;
    (this.allConfigs['flowAvailability'])['transactionalJourneysAvailable'] = this.allConfigs['transactionalJourneysAvailable'];
    this.configurationService.patchConfiguration(this.allConfigs).subscribe(respone => {
      const notification = new Notification();
      notification.primaryButtonClick = function () {
        form.notificationService.notificationModal.hide();
      };
      notification.bodyContent = 'Configuration Saved Successfully';
      notification.bodyTitle = 'Update Configuration';
      notification.primaryButtonText = 'Close';
      notification.primaryButtonStyle = 'btn registration';
      this.notificationService.createNotification(notification);
    },
      error => {
        this.error = this.errorHandling.lookUpError(PAGES.CONFIG, '1001');
        if (this.error) {
          console.log('Enabled Error: ', this.error);
          const notification = new Notification();
          notification.primaryButtonClick = function () {
            form.notificationService.notificationModal.hide();
          };
          notification.bodyContent = this.error.description;
          notification.bodyTitle = this.error.title;
          notification.primaryButtonText = this.error.confirmButtonText;
          notification.primaryButtonStyle = 'btn registration';
          this.notificationService.createNotification(notification);
        }
      });
  }

  flagCheckedChanged(event: boolean, keyName: string) {
    this.homeService.flagCheckedChanged(event, keyName, this.allConfigs);
  }
  getFlagText(flagName) {
    return this.homeService.getFlagText(flagName);
  }
  getFlagTooltip(flagName) {
    return this.homeService.getFlagTooltip(flagName, this.allConfigs);
  }

}
