<div class="admin-top-title">
    <br />
    <p>{{'home.add-new-page-deeplink.title'|translate}}</p>
    <hr />
</div>
<div class="url-config">
    <div *ngIf="showError">
        <div class="error-url-modal col-lg-8 offset-md-1 col-md-10 offset-md-1 col-12">
            <span class="icon-warning"></span>{{'home.add-new-page-deeplink.page-one.duplicatedValidation'|translate}}
        </div>
    </div>
    <form [formGroup]="configsForm">
        <div class="row">
            <div class="col-lg-8 offset-md-1 col-md-10 offset-md-1 col-12 toggle-container">

                <div class="admin-top row">
                    <div class="col-md-4 col-12 title-tag">
                        <p class="title">{{'home.add-new-page-deeplink.page-one.screenName'|translate}}:</p>
                    </div>
                    <div class="col-md-8 col-12 padding-r-0">
                        <sp-text formControlName="screenName" class="input-bg"></sp-text>
                    </div>
                </div>
                <div class="admin-top row">
                    <div class="col-md-4 col-12 title-tag">
                        <p class="title">{{'home.add-new-page-deeplink.page-one.screenWebURL'|translate}}:</p>
                    </div>
                    <div class="col-md-8 col-12 padding-r-0">
                        <sp-text formControlName="screenWebURL" class="input-bg"></sp-text>
                    </div>
                </div>
                <div class="admin-top row">
                    <div class="col-md-4 col-12 title-tag">
                        <p class="title">{{'home.add-new-page-deeplink.page-one.appNativeURL'|translate}}:</p>
                    </div>
                    <div class="col-md-8 col-12 padding-r-0">
                        <sp-text formControlName="appNativeURL" class="input-bg"></sp-text>
                    </div>
                </div>
                <div class="admin-top row">
                    <div class="col-md-4 col-12 title-tag">
                        <p class="title">{{'home.add-new-page-deeplink.page-one.desktopURL'|translate}}:</p>
                    </div>
                    <div class="col-md-8 col-12 padding-r-0">
                        <sp-text formControlName="desktopURL" class="input-bg"></sp-text>
                    </div>
                </div>
                <div class="admin-top row">
                    <div class="col-md-4 col-12 title-tag">
                        <p class="title">{{'home.add-new-page-deeplink.page-one.taggingName'|translate}}:</p>
                    </div>
                    <div class="col-md-8 col-12 padding-r-0">
                        <sp-text formControlName="taggingName" class="input-bg"></sp-text>
                    </div>
                </div>
                <!--IOS version -->
                <div class="admin-top row">
                    <div class="col-md-4 col-12 title-tag">
                        <p class="title">{{'home.add-new-page-deeplink.page-one.ios'|translate}}:</p>
                    </div>
                    <div class="col-md-8 col-12 padding-r-0 from-to-contaier">
                        <span class="title title-from-to col-1">{{'home.add-new-page-deeplink.page-one.from'|translate}}:</span>
                        <sp-text formControlName="includesFromiOs" class="input-bg col-5"></sp-text>
                        <span class="title title-from-to col-1">{{'home.add-new-page-deeplink.page-one.to'|translate}}:</span>
                        <sp-text formControlName="includesToiOs" class="input-bg col-5"></sp-text>
                    </div>
                </div>
                <!--web version -->
                <div class="admin-top row">
                    <div class="col-md-4 col-12 title-tag">
                        <p class="title">{{'home.add-new-page-deeplink.page-one.web'|translate}}:</p>
                    </div>
                    <div class="col-md-8 col-12 padding-r-0 from-to-contaier">
                        <span class="title title-from-to col-1">{{'home.add-new-page-deeplink.page-one.from'|translate}}:</span>
                        <sp-text formControlName="includesFromWeb" class="input-bg col-5"></sp-text>
                        <span class="title title-from-to col-1">{{'home.add-new-page-deeplink.page-one.to'|translate}}:</span>
                        <sp-text formControlName="includesToWeb" class="input-bg col-5"></sp-text>
                    </div>
                </div>

                <!--Android version -->
                <div class="admin-top row">
                    <div class="col-md-4 col-12 title-tag">
                        <p class="title">{{'home.add-new-page-deeplink.page-one.android'|translate}}:</p>
                    </div>
                    <div class="col-md-8 col-12 padding-r-0 from-to-contaier">
                        <span class="title title-from-to col-1">{{'home.add-new-page-deeplink.page-one.from'|translate}}:</span>
                        <sp-text formControlName="includesFromAndriod" class="input-bg col-5"></sp-text>
                        <span class="title title-from-to col-1">{{'home.add-new-page-deeplink.page-one.to'|translate}}:</span>
                        <sp-text formControlName="includesToAndroid" class="input-bg col-5"></sp-text>
                    </div>
                </div>
                <div class="space"></div>
                <div class="admin-top row">
                    <div class="col-md-4 col-12 title-tag">
                        <p class="title">{{'home.add-new-page-deeplink.page-one.inAppSection'|translate}}:</p>
                    </div>
                    <div class="col-md-8 col-12 padding-r-0">
                        <sp-check-box [name]="'checkbox'" [check]="isHide" (option)="isHide = $event">
                        </sp-check-box>
                    </div>
                </div>
                <div class="admin-top row">
                    <div class="col-md-4 col-12 title-tag">
                        <p class="title">{{'home.add-new-page-deeplink.page-one.servicesList'|translate}}:</p>
                    </div>
                    <div class="col-md-8  col-12  padding-r-0">
                        <angular2-multiselect class="multiselect" [data]="servicesList" (onSelectAll)='onSelectAll()'
                            (onDeSelectAll)='onDeSelectAll()' [selectedItems]="servicesListSelected">
                        </angular2-multiselect>
                    </div>
                </div>
            </div>
            <div class="configuration-btn offset-md-1 col-12">
                <div class="configuration-btn col-lg-8 offset-md-1 col-md-10 offset-md-1 col-12">
                    <button class="blue-add" [disabled]="disabledForm()" [ngClass]="{'gray-add':disabledForm(),
                    'blue-add':!disabledForm()}" (click)='addURL()'
                        type="submit">{{'home.add-new-page-deeplink.page-one.buttons.addLink'|translate}}</button>
                </div>
            </div>
        </div>

    </form>

</div>
<!--add-deeplink end-->

<hr>
<div *ngIf="pages&&!pages.length" class="loader"></div>
<div class="row" *ngIf="pages&&pages.length>0">
    <div class="col-lg-8 offset-md-1 col-md-10 offset-md-1 col-12 toggle-container table-urls">
        <div class="search-container">
            <sp-text class="search-url" [(ngModel)]="filterChar"></sp-text>
            <span class="icon icon-search"></span>
            <div class="clearfix"></div>
        </div>
        <div *ngFor="let page of pages|search:'name':filterChar">
            <!-- details of sections -->
            <div class="url-container url-container-edit">
                <div class="row url-desc" *ngIf="page.name">
                    <div class="col-4">{{'home.add-new-page-deeplink.page-one.fields.screenName'|translate}}</div>
                    <div class="col-8 pl-0">{{page.name}}</div>
                </div>
                <div class="row url-desc" *ngIf="page.tagName">
                    <div class="col-4">{{'home.add-new-page-deeplink.page-one.fields.taggingName'|translate}}</div>
                    <div class="col-8 pl-0">{{page.tagName}}</div>
                </div>

                <div class="row url-desc" *ngIf="page.nativeURL">
                    <div class="col-4">{{'home.add-new-page-deeplink.page-one.fields.appNativeURL'|translate}}</div>
                    <div class="col-8 pl-0">{{page.nativeURL}}</div>
                </div>

                <div class="row url-desc" *ngIf="page.webURL">
                    <div class="col-4">{{'home.add-new-page-deeplink.page-one.fields.screenWebURL'|translate}}</div>
                    <div class="col-8 pl-0">{{page.webURL}}</div>
                </div>
                <div class="row url-desc" *ngIf="page.desktopURL">
                    <div class="col-4">{{'home.add-new-page-deeplink.page-one.fields.desktopURL'|translate}}</div>
                    <div class="col-8 pl-0">{{page.desktopURL}}</div>
                </div>
                <div class="row url-desc" *ngIf="page.serviceTypes.length>0">
                    <div class="col-4">{{'home.add-new-page-deeplink.page-one.fields.servicesList'|translate}}</div>
                    <div class="col-8 pl-0">{{page.serviceTypes.toString()}}</div>
                </div>
                <div class="row url-desc" *ngIf="page.hideFromDeepLink">
                    <div class="col-4">{{'home.add-new-page-deeplink.page-one.fields.inAppSection'|translate}}</div>
                    <div class="col-8 pl-0">{{page.hideFromDeepLink}}</div>
                </div>
                <div *ngIf="page.included" >
                    <!--IOS version -->
                <div class="admin-top row">
                    <div class="col-md-4 col-12 title-tag">
                        <p class="text-view">{{'home.add-new-page-deeplink.page-one.ios'|translate}}:</p>
                    </div>
                    <div class="col-md-8 col-12 padding-r-0 from-to-contaier">
                        <span class=" title-from-to-view text-view col-1">{{'home.add-new-page-deeplink.page-one.from'|translate}}:</span>
                        <span class=" title-from-to-view text-view col-1">{{page.included[0]?.version.from}}</span>
                        <span class=" title-from-to-view text-view col-1">{{'home.add-new-page-deeplink.page-one.to'|translate}}:</span>
                        <span class=" title-from-to-view text-view col-1">{{page.included[0]?.version.to}}</span>
                    </div>
                </div>
                <!--web version -->
                <div class="admin-top row">
                    <div class="col-md-4 col-12 title-tag">
                        <p class="text-view">{{'home.add-new-page-deeplink.page-one.web'|translate}}:</p>
                    </div>
                    <div class="col-md-8 col-12 padding-r-0 from-to-contaier">
                        <span class=" title-from-to-view text-view col-1">{{'home.add-new-page-deeplink.page-one.from'|translate}}:</span>
                        <span class=" title-from-to-view text-view col-1">{{page.included[2]?.version.from}}</span>
                        <span class=" title-from-to-view text-view col-1">{{'home.add-new-page-deeplink.page-one.to'|translate}}:</span>
                        <span class=" title-from-to-view text-view col-1">{{page.included[2]?.version.to}}</span>
                    </div>
                </div>

                <!--Android version -->
                <div class="admin-top row">
                    <div class="col-md-4 col-12 title-tag">
                        <p class="text-view">{{'home.add-new-page-deeplink.page-one.android'|translate}}:</p>
                    </div>
                    <div class="col-md-8 col-12 padding-r-0 from-to-contaier">
                        <span class=" title-from-to-view text-view col-1">{{'home.add-new-page-deeplink.page-one.from'|translate}}:</span>
                        <span class=" title-from-to-view text-view col-1">{{page.included[1]?.version.from}}</span>
                        <span class=" title-from-to-view text-view col-1">{{'home.add-new-page-deeplink.page-one.to'|translate}}:</span>
                        <span class=" title-from-to-view text-view col-1">{{page.included[1]?.version.to}}</span>
                    </div>
                </div>
                </div>
                <!-- </div> -->
                <div class="row url-desc url-desc-btn-container">
                    <button class="Delete-btn" (click)="deleteURL(page)">Delete</button>
                    <button class="Edit-btn" (click)="editView(page)">Edit</button>
                </div>
            </div>
        </div>
    </div>
</div>

<div bsModal #modal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
    aria-hidden="true" [config]="{keyboard: false,backdrop : 'static'}">
    <div class="small modal-dialog allow-events">
        <div class="modal-content">
            <div class="modal-header" *ngIf="showCloseIcon">
                <button type="button" class="close pull-right" aria-label="Close" (click)="hide()">
                    <span class='icon icon-close modal-close'></span>
                </button>
            </div>
        </div>
        <div class="url-config">
            <div class="modal-body modal-edit toggle-container">
                <div>
                    <div class="row title-edit center-row">
                        <div class="col-10">
                            {{'home.add-new-page-deeplink.page-two.title'|translate}}
                        </div>
                        <div class="close-edit col-2" (click)="hide()">
                            <span class="remove-parameter">
                                <i class="icon icon-close"></i>
                            </span>
                        </div>

                        <div class="clearfix"></div>
                    </div>
                    <div *ngIf="showError">
                        <div class="error-url-modal col-lg-8 offset-md-1 col-md-10 offset-md-1 col-12">
                            <span
                                class="icon-warning"></span>{{'home.url-config.page-one.duplicatedValidation'|translate}}
                        </div>
                    </div>
                    <div class="row ">
                        <div class="description-form col-12 ">
                            <form [formGroup]="configsFormEdit">
                                <div class="row">
                                    <div class="col-lg-8 offset-md-1 col-md-10 offset-md-1 col-12">
                                        <div class="admin-top row">
                                            <div class="col-md-4 col-12 title-tag">
                                                <p class="title">
                                                    {{'home.add-new-page-deeplink.page-one.screenName'|translate}}:</p>
                                            </div>
                                            <div class="col-md-8 col-12 padding-r-0">
                                                <sp-text formControlName="screenName" class="input-bg"></sp-text>
                                            </div>
                                        </div>
                                        <div class="admin-top row">
                                            <div class="col-md-4 col-12 title-tag">
                                                <p class="title">
                                                    {{'home.add-new-page-deeplink.page-one.screenWebURL'|translate}}:
                                                </p>
                                            </div>
                                            <div class="col-md-8 col-12 padding-r-0">
                                                <sp-text formControlName="screenWebURL" class="input-bg"></sp-text>
                                            </div>
                                        </div>
                                        <div class="admin-top row">
                                            <div class="col-md-4 col-12 title-tag">
                                                <p class="title">
                                                    {{'home.add-new-page-deeplink.page-one.appNativeURL'|translate}}:
                                                </p>
                                            </div>
                                            <div class="col-md-8 col-12 padding-r-0">
                                                <sp-text formControlName="appNativeURL" class="input-bg"></sp-text>
                                            </div>
                                        </div>
                                        <div class="admin-top row">
                                            <div class="col-md-4 col-12 title-tag">
                                                <p class="title">
                                                    {{'home.add-new-page-deeplink.page-one.desktopURL'|translate}}:</p>
                                            </div>
                                            <div class="col-md-8 col-12 padding-r-0">
                                                <sp-text formControlName="desktopURL" class="input-bg"></sp-text>
                                            </div>
                                        </div>
                                        <div class="admin-top row">
                                            <div class="col-md-4 col-12 title-tag">
                                                <p class="title">
                                                    {{'home.add-new-page-deeplink.page-one.taggingName'|translate}}:</p>
                                            </div>
                                            <div class="col-md-8 col-12 padding-r-0">
                                                <sp-text formControlName="taggingName" class="input-bg"></sp-text>
                                            </div>
                                        </div>
                                        <div class="admin-top row">
                                            <div class="col-md-4 col-12 title-tag">
                                                <p class="title">
                                                    {{'home.add-new-page-deeplink.page-one.inAppSection'|translate}}:
                                                </p>
                                            </div>
                                            <div class="col-md-8 col-12 padding-r-0">
                                                <sp-check-box [name]="'checkbox'" [check]="isHide"
                                                    (option)="isHide = $event">
                                                </sp-check-box>
                                            </div>
                                        </div>
                                        <div class="admin-top row">
                                            <div class="col-md-4 col-12 title-tag">
                                                <p class="title">
                                                    {{'home.add-new-page-deeplink.page-one.servicesList'|translate}}:
                                                </p>
                                            </div>
                                            <!--IOS version -->
                <div class="admin-top row">
                    <div class="col-md-4 col-12 title-tag">
                        <p class="text-view">{{'home.add-new-page-deeplink.page-one.ios'|translate}}:</p>
                    </div>
                    <div class="col-md-8 col-12 padding-r-0 from-to-contaier">
                        <span class="text-view title-from-to col-1">{{'home.add-new-page-deeplink.page-one.from'|translate}}:</span>
                        <sp-text formControlName="includesFromiOs" class="input-bg col-5"></sp-text>
                        <span class=" text-view title-from-to col-1">{{'home.add-new-page-deeplink.page-one.to'|translate}}:</span>
                        <sp-text formControlName="includesToiOs" class="input-bg col-5"></sp-text>
                    </div>
                </div>
                <!--web version -->
                <div class="admin-top row">
                    <div class="col-md-4 col-12 title-tag">
                        <p class="text-view">{{'home.add-new-page-deeplink.page-one.web'|translate}}:</p>
                    </div>
                    <div class="col-md-8 col-12 padding-r-0 from-to-contaier">
                        <span class="text-view title-from-to col-1">{{'home.add-new-page-deeplink.page-one.from'|translate}}:</span>
                        <sp-text formControlName="includesFromWeb" class="input-bg col-5"></sp-text>
                        <span class="text-view title-from-to col-1">{{'home.add-new-page-deeplink.page-one.to'|translate}}:</span>
                        <sp-text formControlName="includesToWeb" class="input-bg col-5"></sp-text>
                    </div>
                </div>

                <!--Android version -->
                <div class="admin-top row">
                    <div class="col-md-4 col-12 title-tag">
                        <p class="text-view">{{'home.add-new-page-deeplink.page-one.android'|translate}}:</p>
                    </div>
                    <div class="col-md-8 col-12 padding-r-0 from-to-contaier">
                        <span class="text-view title-from-to col-1">{{'home.add-new-page-deeplink.page-one.from'|translate}}:</span>
                        <sp-text formControlName="includesFromAndriod" class="input-bg col-5"></sp-text>
                        <span class="text-view title-from-to col-1">{{'home.add-new-page-deeplink.page-one.to'|translate}}:</span>
                        <sp-text formControlName="includesToAndroid" class="input-bg col-5"></sp-text>
                    </div>
                </div>
                <div class="space"></div>
                                            <div class="col-md-8  col-12  padding-r-0">
                                                <angular2-multiselect class="multiselect" [data]="servicesList"
                                                    (onSelectAll)='onSelectAll(true)' (onDeSelectAll)='onDeSelectAll(true)'
                                                    [selectedItems]="servicesListSelectedEdit">
                                                </angular2-multiselect>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="configuration-btn offset-md-1 col-12">
                                        <div
                                            class="configuration-btn col-lg-8 offset-md-1 col-md-10 offset-md-1 col-12">
                                            <button class="blue-add" (click)='updatePage()'
                                                type="submit">{{'home.add-new-page-deeplink.page-one.fields.buttons.EditLink'|translate}}</button>
                                        </div>
                                    </div>
                                </div>

                            </form>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>