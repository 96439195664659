import { ChatConfigService } from './../../services/chat-config.service';
import { ChatConfig } from './../../../models/chat-config.model';
import { Notification } from './../../../models/notification.model';
import { IDropdown } from '../sp-dropdown/IDropdown.interface';
import { Component, OnInit, Input, ViewChild, AfterContentChecked, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { SidemenuQuery, chatQuery } from '../../constants/defines';
import * as pageConfig from '../../../../config/pages-config';
import * as _ from 'lodash';
import { NotificationService } from '../../services/notification.service';
import { MultiSelect } from '../../../models/multi-select.model';
import { Criteria } from '../../../models/criteria.model';
import { KeyValueConfigService } from '../../services/key-value-config.service';
import { ConfigurationCategory } from '../../enums/configuration-category.enum';
import { config } from '../../../../config/pages-config';

@Component({
  selector: 'sp-chat-config-new-item',
  templateUrl: './chat-config-new-item.component.html',
  styleUrls: ['./chat-config-new-item.component.scss']
})
export class ChatConfigNewItemComponent implements OnInit, AfterContentChecked {
  @ViewChild('modal', { static: true }) public modal: ModalDirective;
  @Input() isEdit: boolean = false;
  @Input() content: ChatConfig;
  @Output() submitted = new EventEmitter<boolean>();
  selectedItemsToEdit: ChatConfig[];
  isValid: boolean;

  consumerList: MultiSelect[] = [];
  employeeList: MultiSelect[] = [];
  authorizedList: MultiSelect[] = [];

  consumerLightListSelected: MultiSelect[] = [];
  consumerFullListSelected: MultiSelect[] = [];
  employeeListSelected: MultiSelect[] = [];
  authorizedListSelected: MultiSelect[] = [];

  selectedPageTaggingName: string;
  selectedModule: string;

  pagesURLsList: IDropdown[] = [];
  modulesList: IDropdown[] = [];

  form: FormGroup;
  isHide: boolean = false;
  istoggleActive: boolean = true;
  isCheckboxChecked: boolean;
  islightboxChecked: boolean;
  isfullboxChecked: boolean;
  isauthorizedboxChecked: boolean;
  isemployeeboxChecked: boolean;
  toggleCheck: boolean = true;

  constructor(private _fb: FormBuilder, private chatConfigService: ChatConfigService,
    private keyValueConfigService: KeyValueConfigService,
    private translateService: TranslateService, private notificationService: NotificationService) {
    this.form = this._fb.group({ pageName: [''] });
  }
  ngOnInit() {

    this.loadCategories();
    this.consumerList = pageConfig.config.consumerList;
    this.employeeList = pageConfig.config.employeeList;
    this.authorizedList = pageConfig.config.authorizedList;

  }

  loadCategories() {
    this.modulesList = [];
    this.pagesURLsList = [];
    this.keyValueConfigService.getAllKeyValueConfigs().subscribe(() => {
      const allModules = this.keyValueConfigService.allData.filter(cat => cat.name && cat.name.toLowerCase() === ConfigurationCategory.chatModule.toLowerCase());
      const allPages = this.keyValueConfigService.allData.filter(cat => cat.name && cat.name.toLowerCase() === ConfigurationCategory.chatPage.toLowerCase());
      this.modulesList = allModules.map(module => {
        return { text: module.key, value: module.value };
      })
      this.pagesURLsList = allPages.map(module => {
        return { text: module.key, value: module.value };
      })
    })
  }

  submit() {
    if (this.isValid) {
      // no change this code
      if (!this.isEdit) {
        const newItem = new ChatConfig();
        // All have the same value module name is the first name of tha tagging page_name
        newItem.name = this.selectedModule ;// this.form.controls[chatQuery.pageName].value.toLowerCase();
        newItem.module = this.selectedModule ;// this.form.controls[chatQuery.pageName].value.toLowerCase();
        newItem.taggingName = this.selectedModule ; // this.form.controls[chatQuery.pageName].value.toLowerCase();
        newItem.criteria = [];
        if (this.consumerLightListSelected.length) {
          newItem.criteria.push(new Criteria(SidemenuQuery.consumer.toUpperCase(), SidemenuQuery.light.toUpperCase(),
            this.consumerLightListSelected.map(el => el.itemName)));
        }
        if (this.consumerFullListSelected.length) {
          newItem.criteria.push(new Criteria(SidemenuQuery.consumer.toUpperCase(), SidemenuQuery.complete.toUpperCase(),
            this.consumerFullListSelected.map(el => el.itemName)));
        }
        if (this.authorizedListSelected.length) {
          newItem.criteria.push(new Criteria(SidemenuQuery.authorized.toUpperCase(), SidemenuQuery.complete.toUpperCase(),
            this.authorizedListSelected.map(el => el.itemName)));
        }
        if (this.employeeListSelected.length) {
          newItem.criteria.push(new Criteria(SidemenuQuery.employee.toUpperCase(), SidemenuQuery.light.toUpperCase(),
            this.employeeListSelected.map(el => el.itemName)));
        }

        this.chatConfigService.addItem(newItem).subscribe(
          () => {
            this.hide();
            this.submitted.emit(true);
          },
          error => {
            const notification = new Notification();
            const modal = this;
            this.translateService.
              get('home.messages')
              .subscribe(data => {
                notification.bodyTitle = data['delete-message-title']
                notification.bodyContent = data['error-text'];
                notification.primaryButtonText = data['buttons']['sure'];
              });
            notification.primaryButtonStyle = 'btn registration';
            notification.primaryButtonClick = function () {
              modal.notificationService.notificationModal.hide();
            };
            this.notificationService.createNotification(notification);
          });

      } else {
        if (this.selectedItemsToEdit.length > 1) {
          /** in case more than one item to edit should change criteria only */
          this.selectedItemsToEdit.forEach(page => {
            page.criteria = [];
            if (this.consumerLightListSelected.length) {
              page.criteria.push(new Criteria(SidemenuQuery.consumer.toUpperCase(), SidemenuQuery.light.toUpperCase(),
                this.consumerLightListSelected.map(el => el.itemName)));
            }
            if (this.consumerFullListSelected.length) {
              page.criteria.push(new Criteria(SidemenuQuery.consumer.toUpperCase(), SidemenuQuery.complete.toUpperCase(),
                this.consumerFullListSelected.map(el => el.itemName)));
            }
            if (this.authorizedListSelected.length) {
              page.criteria.push(new Criteria(SidemenuQuery.authorized.toUpperCase(), SidemenuQuery.complete.toUpperCase(),
                this.authorizedListSelected.map(el => el.itemName)));
            }
            if (this.employeeListSelected.length) {
              page.criteria.push(new Criteria(SidemenuQuery.employee.toUpperCase(), SidemenuQuery.light.toUpperCase(),
                this.employeeListSelected.map(el => el.itemName)));
            }
          });
        } else if (this.selectedItemsToEdit.length === 1) {
          /** in case just one item to edit should change all its properties */
          const page = this.selectedItemsToEdit[0];
          page.name = this.selectedModule;//this.form.controls[chatQuery.pageName].value;
          page.module = this.selectedModule;
          page.taggingName = this.selectedModule //this.selectedPageTaggingName;
          page.criteria = [];
          if (this.consumerLightListSelected.length) {
            page.criteria.push(new Criteria(SidemenuQuery.consumer.toUpperCase(), SidemenuQuery.light.toUpperCase(),
              this.consumerLightListSelected.map(el => el.itemName)));
          }
          if (this.consumerFullListSelected.length) {
            page.criteria.push(new Criteria(SidemenuQuery.consumer.toUpperCase(), SidemenuQuery.complete.toUpperCase(),
              this.consumerFullListSelected.map(el => el.itemName)));
          }
          if (this.authorizedListSelected.length) {
            page.criteria.push(new Criteria(SidemenuQuery.authorized.toUpperCase(), SidemenuQuery.complete.toUpperCase(),
              this.authorizedListSelected.map(el => el.itemName)));
          }
          if (this.employeeListSelected.length) {
            page.criteria.push(new Criteria(SidemenuQuery.employee.toUpperCase(), SidemenuQuery.light.toUpperCase(),
              this.employeeListSelected.map(el => el.itemName)));
          }
        }

        this.chatConfigService.editItem(this.selectedItemsToEdit).subscribe(
          () => {
            this.hide();
            this.submitted.emit(true);
            this.clearCheckedboxFlags() // reset values
          },
          error => {
            const notification = new Notification();
            const modal = this;
            this.translateService.
              get('home.messages')
              .subscribe(data => {
                notification.bodyTitle = data['delete-message-title']
                notification.bodyContent = data['error-text'];
                notification.primaryButtonText = data['buttons']['sure'];
              });
            notification.primaryButtonStyle = 'btn registration';
            notification.primaryButtonClick = function () {
              modal.notificationService.notificationModal.hide();
            };
            this.notificationService.createNotification(notification);
            this.clearCheckedboxFlags(); // reset values
          });

      }
    }
    this.clearCheckedboxFlags();
  }

  onSelectAll(item, type) {
    switch (type) {
      case pageConfig.config.dropDownTypes.light: this.consumerLightListSelected = item.slice(); break;
      case pageConfig.config.dropDownTypes.full: this.consumerFullListSelected = item.slice(); break;
      case pageConfig.config.dropDownTypes.authorized: this.authorizedListSelected = item.slice(); break;
      case pageConfig.config.dropDownTypes.employee: this.employeeListSelected = item.slice(); break;
    }
  }
  onDeSelectAll(type: string): void {
    switch (type) {
      case pageConfig.config.dropDownTypes.light: this.consumerLightListSelected = []; break;
      case pageConfig.config.dropDownTypes.full: this.consumerFullListSelected = []; break;
      case pageConfig.config.dropDownTypes.authorized: this.authorizedListSelected = []; break;
      case pageConfig.config.dropDownTypes.employee: this.employeeListSelected = []; break;
    }
  }
  clearLists(): void {
    this.authorizedListSelected = [];
    this.consumerLightListSelected = [];
    this.consumerFullListSelected = [];
    this.employeeListSelected = [];
  }
  public show(selectedItems: ChatConfig[] = null): void {
    this.selectedItemsToEdit = selectedItems;
    this.isEdit = !!selectedItems;
    this.modal.show();
    if (!selectedItems) {
      /** add new item */
      this.form.controls[chatQuery.pageName].setValue('');
      this.selectedModule = null;
      this.selectedPageTaggingName = null;
      this.clearLists();
    } else {
      this.content = this.selectedItemsToEdit.length > 1 ? null : this.selectedItemsToEdit[0];
      this.clearLists();
      if (this.content) {
        // only item edit
        this.clearCheckedboxFlags(); // need to clear checkbox
        this.form.controls[chatQuery.pageName].setValue(this.content.name);
        this.selectedModule = this.content.module;
        this.selectedPageTaggingName = this.content.taggingName;
        this.editshowmodal();
      } else {
        // when selecting more than one module rest all the data
        this.clearCheckedboxFlags();
        this.changeToggle(true);
      }

    }
  }

  public hide(): void {
    this.isEdit = false;
    this.modal.hide();
  }

  ngAfterContentChecked(): void {
    /** here must check on the repeated page name */
    if (((!this.selectedItemsToEdit
      || (this.selectedItemsToEdit && this.selectedItemsToEdit.length === 1))
      && this.selectedModule)
      || (this.selectedItemsToEdit && this.selectedItemsToEdit.length > 1)) {
      this.isValid = true;
    } else {
      this.isValid = false;
    }
  }

  hideModal() {
    this.modal.hide();
  }


   /**
   * make tobi on/ off 
   * @param toggle new toggle value
   * @param flageName a specific flag name
   */
    changeToggle(isChecked: boolean): void {
      this.istoggleActive = isChecked;
      if (!this.istoggleActive) {
        this.clearLists();
      }
    }


  checkToggle(isChecked: boolean, type: string): void {
    this.isCheckboxChecked = isChecked;
    let list: any;
    switch (type) {
      case pageConfig.config.dropDownTypes.light: {
        list = config.consumerList;
        this.islightboxChecked = true;
      } break;
      case pageConfig.config.dropDownTypes.full: {
        list = config.consumerList;
        this.isfullboxChecked = true
      } break;
      case pageConfig.config.dropDownTypes.authorized: {
          list = config.authorizedList;
          this.isauthorizedboxChecked = true;
      }; break;
      case pageConfig.config.dropDownTypes.employee: {
        list = config.employeeList;
        this.isemployeeboxChecked = true;
      }; break;
    }

    if (isChecked) {
      this.onSelectAll(list, type)
    } else {
      this.onDeSelectAll(type)
    }

  }

  clearCheckedboxFlags(): void {
    this.islightboxChecked = false;
    this.isfullboxChecked = false;
    this.isauthorizedboxChecked = false;
    this.isemployeeboxChecked = false;
  }

  editshowmodal(): void {
    if (this.content.criteria.length > 0) {
      this.content.criteria.forEach(el => {
        let userType: string;
        if (el.userType.toLowerCase() === config.consumer.toLowerCase()) {
          el.profileType.toLowerCase() === config.light ? userType = config.light : userType = config.full;
        }
        else {
          userType = el.userType.toLowerCase();
        }
        this.checkToggle(true, userType);
        this.changeToggle(true)
      })
    } else {
      // when critiria dosen't have data show edit overlay dimmed
      this.changeToggle(false);
      this.clearCheckedboxFlags();
    }
  }
}
