import { BuildNumberInterface } from '../../home/force-update/force-update.interface';
import { Injectable } from "@angular/core";

@Injectable()
export class UtilsService {

constructor( ) { }

 public versionFormat(version: string | BuildNumberInterface): BuildNumberInterface {
    if (typeof version === 'string' || version instanceof String) {
      let verArray = version.split('.')
      return {
        major: verArray[0] ? parseInt(verArray[0]) : null,
        minor: verArray[1] ? parseInt(verArray[1]) : null,
        build: verArray[2] ? parseInt(verArray[2]) : null,
      }
    } else {
      return version
    }
  }

  public checkPropertiesNotNull(obj) {
    for (var key in obj) {
        if (obj[key] === null || obj[key] === "")
          return true;
      }
      return false;
  }

  /**
   * should return date in this format yyyy-MM-ddTHH:mm
   * @param date 
   */
  public getFormattedDateForAPI(date: Date) {
    const isoFormat = date.toISOString();
    return isoFormat.substring(0, isoFormat.lastIndexOf(':'));
  }
}