// Default Configuration to DEV
import { LoggerLevel } from '../app/common/enums/loggerLevel.enum';
export const environment = {
  production: false,
  middlewareBaseUrl: 'https://stubs.vsse.org/api/sp-test/',
  stubsFlag: true,
  environmentName: 'test',
  level: LoggerLevel.TRACE,
  CLIENT_ID: 'UzaX2E4PItMmWlPL2UKmkLAKyh8lYiU8',
  webAppURL: '',
  ENTITY_ID: '',
  firebaseDomain: 'https://w9u6f.app.goo.gl',
  nativeBundleId: 'com.tsse.spain.myvodafone'
};

