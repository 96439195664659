import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ConfigurationService } from '../../common/services/configuration.service';
import { LatestBuildsService } from './latest-builds.service';
import { LatestBuildModel } from '../../models/latest-build.model';
import { UtilsService } from '../../common/utils/utils.service';

@Component({
  selector: 'sp-latest-builds',
  templateUrl: './latest-builds.component.html',
  styleUrls: ['./latest-builds.component.scss']
})
export class LatestBuildsComponent implements OnInit {
  latestBuildForm: any;
  latestBuildData: LatestBuildModel;
  latestAndroidData: any;
  latestIosData: any;


  constructor(private _fb: FormBuilder,
    private latestBuildsService: LatestBuildsService,
    private configurationService: ConfigurationService,
    private utilsService: UtilsService) {
    this.latestBuildForm = _fb.group(
      {
        iosMajor: ['', Validators.required],
        iosMinor: ['', Validators.required],
        iosBuild: ['', Validators.required],
        androidMajor: ['', Validators.required],
        androidMinor: ['', Validators.required],
        androidBuild: ['', Validators.required],
      });
  }

  ngOnInit() {
    this.latestBuildData = this.configurationService.getLatestBuilds();
    this.updateVersionValue();
  }

  updateVersionValue(): any {
    this.latestAndroidData = this.utilsService.versionFormat(this.latestBuildData.android);
    this.latestIosData = this.utilsService.versionFormat(this.latestBuildData.ios);
    this.setFormInitialValues();
  }

  setFormInitialValues() {
    this.latestBuildForm.controls['iosMajor'].setValue(this.latestIosData.major);
    this.latestBuildForm.controls['iosMinor'].setValue(this.latestIosData.minor);
    this.latestBuildForm.controls['iosBuild'].setValue(this.latestIosData.build);
    this.latestBuildForm.controls['androidMajor'].setValue(this.latestAndroidData.major);
    this.latestBuildForm.controls['androidMinor'].setValue(this.latestAndroidData.minor);
    this.latestBuildForm.controls['androidBuild'].setValue(this.latestAndroidData.build);
  }

  saveAllSettings() {
    if ((this.latestBuildForm.dirty || this.latestBuildForm.touched) && this.latestBuildForm.valid) {
      /// patch
      this.latestBuildData = {
        ios: `${this.latestBuildForm.value.iosMajor}.${this.latestBuildForm.value.iosMinor}.${this.latestBuildForm.value.iosBuild}`,
        android: `${this.latestBuildForm.value.androidMajor}.${this.latestBuildForm.value.androidMinor}.${this.latestBuildForm.value.androidBuild}`
      };
      this.latestBuildsService.updateBuildData(this.latestBuildData).subscribe((res) => {
        this.configurationService.load();
        this.updateVersionValue();
      });
    }
  }

}
