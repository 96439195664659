<div class="admin-top">
    <br />
    <p>{{'home.menu-item.9'|translate}}</p>
    <hr />
</div>
<form [formGroup]="form">
    <div class="row">
        <div class="col-lg-8 offset-md-1 col-md-10 offset-md-1 col-12 toggle-container">
                <div class="warning toggle" *ngIf="isAboveNineCharacters || isWrongPatternFormat || shouldPatternStartWithSixOrSeven ">
                        <div class="col-1 icon-cont">
                            <i class="icon icon-warning"></i>
                        </div>
                        <div class="col-11 p-cont" *ngIf="shouldPatternStartWithSixOrSeven">
                            <p>{{'home.messages.top-up-descrimination-should-be-six-or-seven-alert'|translate}}</p>
                        </div>
                        <div class="col-11 p-cont" *ngIf="isAboveNineCharacters">
                            <p>{{'home.messages.top-up-descrimination-above-nine-characters'|translate}}</p>
                        </div>
                        <div class="col-11 p-cont" *ngIf="isWrongPatternFormat">
                            <p>{{'home.messages.top-up-descrimination-wrong-format-entered'|translate}}</p>
                        </div>
             
                    </div>
            <div class="toggle">
                <p>{{'home.top-up-decsrimination-config.newPciButtonLabel'|translate}}</p>
                <div class="toggle-btn">
                    <sp-toggle-button (option)="flagCheckedChanged($event)" [check]="topUpDiscriminationConfigurations.topupNewPCI"></sp-toggle-button>
                </div>
            </div>
            <div class="toggle">
                <div class="row text-container">
                    <div class="col-md-2 pattern-text">
                        <p>{{'home.top-up-decsrimination-config.textFieldLabel'|translate}}</p>
                    </div>
                    <div class="toggle-btn col-md-10">
                        <sp-text [disabled]="!topUpDiscriminationConfigurations.topupNewPCI"
                            formControlName="msidnPattern" (change)="checkmsidnPattern($event)"></sp-text>
                    </div>
                </div>
            </div>
            <div class="toggle">
                <div class="row text-container">
                    <div class="col-md-2 pattern-text">
                        <p>{{'home.top-up-decsrimination-config.smartpayTextFieldLabel'|translate}}</p>
                    </div>
                    <div class="toggle-btn col-md-10">
                        <sp-text [disabled]="!topUpDiscriminationConfigurations.topupNewPCI"
                            formControlName="smartpayMsidnPattern" (change)="checkmsidnSmartpayPattern($event)"></sp-text>
                    </div>
                </div>
            </div>
        
            <div class="clearfix"></div>
            <div class="configuration-btn">
                <button [ngClass]="{'buttonNotActive':topUpDiscriminationConfigurations.topupNewPCI && (isWrongPatternFormat || isAboveNineCharacters || shouldPatternStartWithSixOrSeven)}" [disabled]="topUpDiscriminationConfigurations.topupNewPCI && (isWrongPatternFormat || isAboveNineCharacters || shouldPatternStartWithSixOrSeven)" (click)="updateTopUpDiscrimination()">{{'home.general-config.buttons.text'|translate}}</button>
            </div>
        </div>
    </div>
</form>
