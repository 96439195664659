
import {map} from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Notification } from './../../models/notification.model';
import { JSON_PATHS } from './../constants/defines';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { API_URLS } from '../constants/routes-config';
import * as jsonQuery from 'jsonpath';
import { ChatConfig } from '../../models/chat-config.model';
import { NotificationService } from './notification.service';


@Injectable()
export class ChatConfigService {
  public chatConfigItems: ChatConfig[];
  public allModulesChatConfig: string[] = [];
  constructor(private http: HttpClient, private translate: TranslateService,
    private notificationService: NotificationService) { }

  loadChatConfiguration() {
    let headers = new HttpHeaders();
    headers = headers.append('content-type', 'application/json');
    headers = headers.append('accept', 'application/json');
    const options = {
      headers: headers
    };
    return this.http.get(API_URLS.ChatBubbleConfig.GET_ITEMS, options).pipe(map((response: any[]) => {
      if (response) {
        this.chatConfigItems = [];
        if (response['items']) {
          response['items'].forEach(item => {
            this.chatConfigItems.push(this.mapItem(item));
          })
        }
        return this.chatConfigItems;
      }
    }));
  }
  private mapItem(item: ChatConfig) {
    const result: ChatConfig = new ChatConfig();
    result.id = jsonQuery.value(item, JSON_PATHS.CHATCONFIG.ID) || null;
    result.name = jsonQuery.value(item, JSON_PATHS.CHATCONFIG.NAME) || null;
    result.taggingName = jsonQuery.value(item, JSON_PATHS.CHATCONFIG.TAGGINGNAME) || null;
    result.module = jsonQuery.value(item, JSON_PATHS.CHATCONFIG.MODULE) || null;
    result.criteria = jsonQuery.value(item, JSON_PATHS.CHATCONFIG.CRITERIA) || [];
    return result;
  }
  editItem(items: ChatConfig[]) {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/merge-patch+json');
    headers = headers.append('accept', 'application/json');
    const options = {
      headers: headers
    };
    return this.http.patch(API_URLS.ChatBubbleConfig.PATCH_ITEM, { items }, options).pipe(map(() => { }));
  }
  addItem(item: ChatConfig) {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/merge-patch+json');
    headers = headers.append('Accept', 'application/json');
    const options = {
      headers: headers
    };
    return this.http.patch(API_URLS.ChatBubbleConfig.POST_ITEM, { items: [item] }, options).pipe(map(() => { }));
  }
  deleteItem(item: ChatConfig) {
    let headers = new HttpHeaders();
    headers = headers.append('content-type', 'application/json');
    headers = headers.append('accept', 'application/json');
    const options = {
      headers: headers
    };
    return this.http.delete(API_URLS.ChatBubbleConfig.DELETE_ITEM.replace('{id}', item.id), options).pipe(map(() => { }));
  }
  createError(errorKeyName: string) {
    const notification = new Notification();
    const modal = this;
    this.translate.
      get('home.messages')
      .subscribe(data => {
        notification.bodyTitle = data['delete-message-title'];
        notification.bodyContent = data[errorKeyName];
        notification.primaryButtonText = data['buttons']['sure'];
      });
    notification.primaryButtonStyle = 'btn registration';
    notification.primaryButtonClick = function () {
      modal.notificationService.notificationModal.hide();
    };
    this.notificationService.createNotification(notification);
  }


  exportToExcel() {
    let headers = new HttpHeaders();
    headers = headers.append('Accept', 'application/x-ms-excel');
    const options = {
      headers: headers,
      // DON"T REFACTOR!!!
      // This explicit type casting is due to typescript's type inference behaviour and the way angular
      // 4 determines the observable type to be returned from get methods, only change this if
      // angular improves this in the future
      responseType: 'blob' as 'blob',
      observe: 'response'
    };
    // DON"T REFACTOR!!
    // This is handled this way to solve browser's problem with un-trusted events
    const downloadWindow = window.open('', '_blank');
    this.http.get(API_URLS.ChatBubbleConfig.DOWNLOAD_EXCEL, {
      headers: headers,
      responseType: 'blob' as 'blob',
      observe: 'response'
    }).subscribe(response => {
      // Type of Excel .xlsx
      const header = response.headers;
      const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
      const blob = new Blob([response.body], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const objectUrl = URL.createObjectURL(blob);
      const contentHeader = response.headers.get('content-disposition');
      const fileName = contentHeader ? (contentHeader.split('filename=')[1]).replace(/['"]+/g, '') : 'Chat-Config-Export';
      a.href = objectUrl;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
    });
  }

  getAllModulesChatConfig(): string[] {
    this.chatConfigItems.forEach(el => this.allModulesChatConfig.push(el.module));
    return this.allModulesChatConfig;
  }
}

