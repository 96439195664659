
import {map} from 'rxjs/operators';
import { UserProfile } from './../../models/user-profile.model';
import { Observable } from 'rxjs';
import { CONFIG, JSON_PATHS, GRANT_TYPE } from './../constants/defines';
import { API_URLS } from './../constants/routes-config';
import * as pagesConfig from './../../../config/pages-config';
import { JWTHelper } from './../services/jwt.helper';
import { StorageService } from './../services/storage.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import * as JsonQuery from 'jsonpath';
import { HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { LOCAL_STORAGE_KEYS } from '../constants/defines';
import { environment } from '../../../environments/environment';



@Injectable()
export class AuthenticateService {
    constructor(
        private storageService: StorageService,
        private jwtHelper: JWTHelper,
        private http: HttpClient,
        public router: Router) {

    }

    /**
     * Get shared items in two arrys
     * @param arr1
     * @param arr2
     */
    private intersect(arr1, arr2): any[] {
        let temp;
        if (arr2.length > arr1.length) {
            temp = arr2, arr2 = arr1, arr1 = temp; // indexOf to loop over shorter
        }
        arr1.map(function (x) { return x.toLowerCase(); })
        return arr1.filter(function (item) {
            return arr2.indexOf(item.toLowerCase()) > -1;
        });
    }

    /**
     * Check if the user already have tokens and userProfile
     * @return boolean indicated if the user Authenticated or not
     */
    public isAuthenticated(): boolean {
        return this.storageService.userProfile ? true : false;
    }

    logout() {
        // clear the localStorage and global objects in the service
        // this.storageService.empty();
        this.storageService.remove(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
        this.storageService.remove(LOCAL_STORAGE_KEYS.ACCESS_TOKEN_EXP);
        this.storageService.remove(LOCAL_STORAGE_KEYS.REFRESH_TOKEN);
        this.storageService.remove(LOCAL_STORAGE_KEYS.REFRESH_TOKEN_EXP);
        this.storageService.remove(LOCAL_STORAGE_KEYS.JWT);
        this.storageService.remove(LOCAL_STORAGE_KEYS.USERNAME);
        this.storageService.userProfile = null;
        this.storageService.jwt = null;
        this.storageService.accessExpirationTime = null;
        this.storageService.accessToken = null;
        this.storageService.refreshExpirationTime = null;
        this.storageService.refreshToken = null;
    }
    public isAccessTokenExist(): boolean {
        if (!this.storageService.getStorage(LOCAL_STORAGE_KEYS.ACCESS_TOKEN) ||
            !this.storageService.getStorage(LOCAL_STORAGE_KEYS.ACCESS_TOKEN_EXP)) {
            return false;
        }
        return true;
    }
    public isAccessTokenExpired(): boolean {
        const expirationDate = new Date(this.storageService.getStorage(LOCAL_STORAGE_KEYS.ACCESS_TOKEN_EXP));
        if (expirationDate < new Date()) {
            return true;
        }
        return false;
    }
    public isRefreshTokenExist(): boolean {
        if (!this.storageService.getStorage(LOCAL_STORAGE_KEYS.REFRESH_TOKEN) ||
            !this.storageService.getStorage(LOCAL_STORAGE_KEYS.REFRESH_TOKEN_EXP)) {
            return false;
        }
        return true;
    }
    public isRefreshTokenExpired(): boolean {
        if (!this.storageService.getStorage(LOCAL_STORAGE_KEYS.REFRESH_TOKEN) ||
            !this.storageService.getStorage(LOCAL_STORAGE_KEYS.REFRESH_TOKEN_EXP)) {
            return true;
        }
        const expirationDate = new Date(this.storageService.getStorage(LOCAL_STORAGE_KEYS.REFRESH_TOKEN_EXP));
        if (expirationDate < new Date()) {
            return true;
        }
        return false;
    }

    /**
     * Create Request and handle the Token storage retrieved from server
     * @param config the options passed to the request like headers
     * @param body the request body passed to the service
     * @param url the remote url
     */
    private createRequest(config: Object, body: Object, url: string) {
        return this.http
            .post(url, body, config).pipe(map((response: any) => {

                // // Saving Tokens in LocalStorage  jwt-access-refresh
                // this.storageService.setStorage(LOCAL_STORAGE_KEYS.JWT, JsonQuery.query(response, JSON_PATHS.LOGIN.SUCCESS.JWT));
                // this.storageService.setStorage(LOCAL_STORAGE_KEYS.ACCESS_TOKEN,
                //     JsonQuery.value(response, JSON_PATHS.LOGIN.SUCCESS.ACCESS_TOKEN));
                // this.storageService.setStorage(LOCAL_STORAGE_KEYS.REFRESH_TOKEN,
                //     JsonQuery.value(response, JSON_PATHS.LOGIN.SUCCESS.REFRESH_TOKEN));

                // // converting from seconds to milli seconds and storing it
                // const accessTokenExpireDate = new Date(JsonQuery.value(response, JSON_PATHS.LOGIN.SUCCESS.EXPIRES_AT) * 1000);
                // const refreshTokenExpireDate = new Date(JsonQuery.value(response, JSON_PATHS.LOGIN.SUCCESS.REFRESH_TOKEN_EXPIRES) * 1000);
                // this.storageService.accessToken = JsonQuery.query(response, JSON_PATHS.LOGIN.SUCCESS.ACCESS_TOKEN);
                // if (accessTokenExpireDate) {
                //     this.storageService.accessExpirationTime = accessTokenExpireDate;
                //     this.storageService.setStorage(LOCAL_STORAGE_KEYS.ACCESS_TOKEN_EXP, accessTokenExpireDate);
                // }
                // if (refreshTokenExpireDate) {
                //     this.storageService.refreshExpirationTime = refreshTokenExpireDate;
                //     this.storageService.setStorage(LOCAL_STORAGE_KEYS.REFRESH_TOKEN_EXP, refreshTokenExpireDate);
                // }
                // Profile
                this.getUserProfile(JsonQuery.value(response, JSON_PATHS.LOGIN.SUCCESS.JWT));
                return response;
            }));
    }

    getUserProfile(jwt: string): UserProfile {
        const userProfile: UserProfile =
            this.jwtHelper.getUserProfileFromJWT(jwt);
        this.storageService.userProfile = userProfile;
        this.storageService.setStorage(LOCAL_STORAGE_KEYS.USERNAME, userProfile.name);
        return userProfile;
    }
    /**
     * authenticate the user
     * @param username
     * @param password
     */
    authenticate(jws: string, accessToken: string) {
        this.storageService.setStorage(LOCAL_STORAGE_KEYS.JWT, jws);
        this.storageService.setStorage(LOCAL_STORAGE_KEYS.ACCESS_TOKEN, accessToken);
        // Set Profile
        this.getUserProfile(jws);
    }
    /**
     * refresh user tokens if the refresh token expired
     */
    refreshTokens(): Observable<object> {
        let headers = new HttpHeaders();
        headers = headers.append('vf-country-code', CONFIG.COUNTRY_CODE);
        headers = headers.append('Content-Type', 'application/x-www-form-urlencoded');
        const config = {
            headers: headers
        };
        const body = new FormData();
        body.append('client_id', environment.CLIENT_ID);
        body.append('refresh_token', this.storageService.refreshToken);
        body.append('scope', JSON.stringify(CONFIG.SCOPES));
        body.append('grant_type', GRANT_TYPE.REFRESH_TOKEN);
        return this.createRequest(config, body, API_URLS.Login.SESSION_START);
    }

    public getUserFromLocalJWT() {
        if (this.storageService.getStorage(LOCAL_STORAGE_KEYS.JWT)) {
            const jwt = this.storageService.getStorage(LOCAL_STORAGE_KEYS.JWT)[0];
            this.getUserProfile(jwt);
            return (this.storageService.userProfile);
        }
    }

    public getSAMLRequest() {
        return this.http.get<any>(API_URLS.Login.SAML_PROVIDER.replace('{0}', window.location.origin));
    }
}
