import { config } from '../../../config/pages-config';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { URLConfig } from '../../models/url.model';
import { UrlConfigService } from '../../common/services/url-config.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Component, ViewChild, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Notification } from '../../models/notification.model';
import { NotificationService } from '../../common/services/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { shortURL } from '../../common/constants/defines';
import { UrlType } from '../../common/enums/url-type.enum';
import { environment } from '../../../environments/environment';


@Component({
  selector: 'sp-ugly-to-good-link',
  templateUrl: './ugly-to-good-link.component.html',
  styleUrls: ['./ugly-to-good-link.component.scss']
})
export class UglyToGoodLinkComponent implements OnDestroy {
  filterChar: string;
  showError: boolean;
  shortUrl: string = shortURL.url;
  urls: URLConfig[] = [];
  showEditError: boolean = false;
  editURL: URLConfig;
  deletedLink: URLConfig;
  appUrl;
  public showCloseIcon = false;
  @ViewChild('modal', { static: true }) public modal: ModalDirective;
  @ViewChild('confirmationmodal') public confirmationmodal: ModalDirective;
  @ViewChild('savedmodal') public savedmodal: ModalDirective;
  public states: any[] = config.internalRoutesList;
  webSectionNames: string[];
  configsForm: FormGroup;
  editForm: FormGroup;
  coptInput: string;
  constructor(private fb: FormBuilder, private ref: ChangeDetectorRef, public URLConfig: UrlConfigService,
    private translateService: TranslateService,
    private notificationService: NotificationService) {
    this.appUrl = environment.webAppURL;
    this.URLConfig.urls.map((el) => {
       // To be removed later  
      // if (el.urlType.toLocaleLowerCase() === UrlType.firebase.toLocaleLowerCase()) {
      //   this.urls.push(el);
      // }
    });
    this.configsForm = fb.group({
      LinkName: [''],
      FirebaseLink: [''],
      EnhancedLink: [''],
    });
    this.editForm = this.fb.group({
      NameEdit: [''],
      EnhancedLinkEdit: [''],
      FirebaseLinkEdit: [''],
    });

  }

  delete(value) {
    const notification = new Notification();
    this.translateService.
      get('delmessages')
      .subscribe(data => {
        notification.bodyContent = data['delete-text'];
        notification.primaryButtonText = data['buttons']['cancel'];
        notification.secondaryButtonText = data['buttons']['sure'];
      });
    notification.primaryButtonStyle = 'btn registration';
    notification.primaryButtonClick = () => {
      this.notificationService.notificationModal.hide();
    };
    notification.secondaryButtonClick = () => {
      this.URLConfig.deleteURL(value).subscribe(() => {
        const url = this.urls.find(el => el.id === value.id);
        if (url) {
          const index = this.urls.indexOf(url);
          this.urls.splice(index, 1);
        }
        this.notificationService.notificationModal.hide();
      });
    }
    this.notificationService.createNotification(notification);
  }

  edit(value: URLConfig) {
    this.showEditError = false;
    this.editURL = value;
    this.editForm = this.fb.group({
      NameEdit: [value.name],
      EnhancedLinkEdit: [value.sourceLink],
      FirebaseLinkEdit: [value.destination],
    });
    this.modal.show();
  }
  hide() {
    this.modal.hide();
    this.confirmationmodal.hide();
    this.savedmodal.hide();
  }
  addURL(event) {
    this.showError = false;
    const url = new URLConfig();
    url.destination = this.configsForm.controls['FirebaseLink'].value;
    url.sourceLink = this.appUrl + this.configsForm.controls['EnhancedLink'].value;
    url.name = this.configsForm.controls['LinkName'].value;
    url.urlType = UrlType.firebase;

    if (url.name && url.destination &&
      url.sourceLink && this.configsForm.controls['EnhancedLink'].value !== this.configsForm.controls['FirebaseLink'].value) {
      if (this.urls.length <= 0) {
        this.saveItem(url)
      } else if (!this.urls.find(el => el.name === this.configsForm.controls['LinkName'].value) &&
        !this.urls.find(el => el.destination === this.configsForm.controls['FirebaseLink'].value) &&
        !this.urls.find(el => el.sourceLink === this.configsForm.controls['EnhancedLink'].value)) {
        this.saveItem(url)
      } else {
        this.showError = true;
      }
    }
    else {
      this.showError = true;
    }
  }
  saveItem(url) {
    this.URLConfig.addNewURL(url).subscribe(() => {
      this.urls.push(url);
      this.ref.detectChanges();
      const notification = new Notification();
      this.translateService.
        get('savedItem')
        .subscribe(data => {
          notification.bodyContent = data['save-text'];
          notification.primaryButtonText = data['buttons']['cancel'];
        });
      notification.primaryButtonClick = () => {
        this.notificationService.notificationModal.hide();
      };
      this.notificationService.createNotification(notification);
      this.configsForm.controls['FirebaseLink'].setValue('');
      this.configsForm.controls['EnhancedLink'].setValue('');
      this.configsForm.controls['LinkName'].setValue('');
    }, (error) => {
      const notification = new Notification();
      notification.bodyContent = "Something Went Wrong";
      notification.primaryButtonText = 'cancel';
      notification.primaryButtonClick = () => {
        this.notificationService.notificationModal.hide();
      };
      this.notificationService.createNotification(notification);
    });

  }
  EditURL() {
    this.showEditError = false;
    if (this.editURL) {
      const firebaseLink = !this.editForm.controls['FirebaseLinkEdit'].pristine ? this.urls.find(el => el.destination === this.editForm.controls['FirebaseLinkEdit'].value) : false;
      const name = !this.editForm.controls['NameEdit'].pristine ? this.urls.find(el => el.name.toLocaleLowerCase() === this.editForm.controls['NameEdit'].value.toString().toLocaleLowerCase()) : false;
      const enhancedLink = !this.editForm.controls['EnhancedLinkEdit'].pristine ? this.urls.find(el => el.sourceLink === this.editForm.controls['EnhancedLinkEdit'].value) : false;
      if (firebaseLink || name || enhancedLink) {
        this.showEditError = true;
        this.modal.hide();
      }
      else {
        this.editURL.name = this.editForm.controls['NameEdit'].value;
        this.editURL.sourceLink = this.editForm.controls['EnhancedLinkEdit'].value;
        this.editURL.destination = this.editForm.controls['FirebaseLinkEdit'].value;
        this.URLConfig.updateURL(this.editURL).subscribe(() => {
          this.urls.forEach(el => {
            if (el.id == this.editURL.id) {

              el = this.editURL;
            }
          })
          this.modal.hide();

        });
      }
      for (let property in this.editForm.controls) {
        if (this.editForm.controls.hasOwnProperty(property)) {
          this.editForm.controls[property].markAsPristine();
        }
      }
    }
  }

  copyToClipbored(copyInputValue: string) {
    let textArea = document.createElement('textarea');
    textArea.value = this.appUrl + copyInputValue;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
  }

  ngOnDestroy() {
    this.configsForm.controls['FirebaseLink'].setValue('');
    this.configsForm.controls['EnhancedLink'].setValue('');
    this.configsForm.controls['LinkName'].setValue('');
  }


}
