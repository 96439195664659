<div class="admin-top">
  <br />
  <p>{{'home.menu-item.7'|translate}}</p>
  <hr />
</div>
<div class="row">
  <div class="col-lg-12 col-12 table-upper-container ">
    <div class="add-cont-cont">
      <div class="add-container">
        <span  (click)="showForceUpdateEditModal()" class="icon-add-or-plus"></span>
      </div>
    </div>
    <table class="table table-bordered"> 
      <thead class="table-headers">
        <tr>
          <th class="table-titles" *ngFor="let header of tableHeaders" scope="col">{{header}}</th>
        </tr>
      </thead> 
      <tbody>
        <tr *ngFor="let build of buildList" [ngClass]="{'dim-row':build?.status.toLowerCase() === status}" >
          <td class="table-data">{{formatVersion(build?.version)}}</td>
          <td class="table-data">{{build?.osType}}</td>
          <td class="table-data">{{build?.force}}</td>
          <td class="table-data">{{build?.date?.creation}}</td>
          <td class="table-data">{{build?.date?.modification}}</td>
          <td class="table-data">{{build?.date?.expiry}}</td>
          <td class="table-data">{{build?.user}}</td>
          <td class="table-icons">
            <span (click)="showForceUpdateEditModal(build)" class="update-icon icon-edit"></span>
            <span (click)="deleteTableRow(build)" class="delete-icon icon-delete"></span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<!--Add Modal -->
<div *ngIf="showForceUpdateEditModel" class="modal in-modal-latest-build" id="myModal" role="dialog">
  <div class="modal-dialog">

    <!-- Modal content-->
    <div class="modal-content">
      <form [formGroup]="form" (ngSubmit)="save()">

        <div class="modal-header">
          <p *ngIf="showForceUpdateEditModel" class="modal-title">{{modalText}}</p>
    
        </div>
        <div class="modal-body">
          <div class="row"  *ngIf="( form?.get('version')?.hasError('wrongRange')  || form?.get('version')?.hasError('versionRule') || form?.hasError('biggerThanLatestBuild') )"  >
            <div class="col-10 offset-1 warning" >
              <div class="col-1 icon-cont">
                <i class="icon icon-warning"></i>
              </div>
              <div class="col-11 p-cont"  *ngIf="form?.get('version')?.hasError('wrongRange')" >
                      <p> {{'force-update-modal-values.bigger-then-error'|translate}}</p>
              </div>
              <div class="col-11 p-cont"  *ngIf="form?.get('version')?.hasError('versionRule')"  >
                      <p>  {{'force-update-modal-values.input-version-error'|translate}} </p>
              </div>        
              <div class="col-11 p-cont"  *ngIf="form?.hasError('biggerThanLatestBuild')"  >
                      <p> {{'force-update-modal-values.version-does-not-exist-error'|translate}}  </p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-10 offset-1 label-txt-cont">
              <p class="label-txt">{{'force-update-modal-values.platform'|translate}}</p>
              <sp-dropdown  formControlName="osType" [selectedValue]="form?.get('osType')?.value" [items]="platform" class="dpd-platform"></sp-dropdown>
            </div>
          </div>
          <div formGroupName="version">
          <div class="row">
            <div class="col-10 offset-1 label-txt-cont" formGroupName="from" [ngClass]="{'fill': form?.get('version')?.get('from')?.hasError('fillAll') }" >
              <p class="label-txt">{{'force-update-modal-values.from-version'|translate}}
                <div class="row">
                  <div class="col-md-6 col-12 col-lg-4 padding-0" >
                    <sp-text textType="number" formControlName="major" pattern="{{patterns.nonNegative}}"  ></sp-text>
                  </div>
                  <div class="col-md-6 col-12 col-lg-4 padding-0">
                    <sp-text textType="number" formControlName="minor" pattern="{{patterns.nonNegative}}" ></sp-text>
                  </div>
                  <div class="col-md-6 col-12 col-lg-4 padding-0">
                    <sp-text textType="number" formControlName="build" pattern="{{patterns.nonNegative}}" ></sp-text>
                  </div>
                </div>              
            </div>
          </div>
          <div class="row">
            <div class="col-10 offset-1 label-txt-cont"  formGroupName="to"   [ngClass]="{'fill': form?.get('version')?.get('to')?.hasError('fillAll') }" >
              <p class="label-txt">To Version :</p>
              <div class="row">
                <div class="col-md-6 col-12 col-lg-4 padding-0" >
                  <sp-text textType="number" formControlName="major"  pattern="{{patterns.nonNegative}}"  ></sp-text>
                </div>
                <div class="col-md-6 col-12 col-lg-4 padding-0">
                  <sp-text textType="number" formControlName="minor"pattern="{{patterns.nonNegative}}" ></sp-text>
                </div>
                <div class="col-md-6 col-12 col-lg-4 padding-0">
                  <sp-text textType="number" formControlName="build"  pattern="{{patterns.nonNegative}}" ></sp-text>
                </div>
              </div>
            </div>
          </div>
        </div>
          <div class="row">
            <div class="col-10 offset-1 label-txt-cont">
              <p class="label-txt label-txt-normal">{{'force-update-modal-values.update-type'|translate}}</p>
              <div class="col-10 padding-0 radio-cont">
                <input type="radio" checked="checked" formControlName="force" name="force" value="{{ForceType.Optional}}" >
                <label class="radio-label"> {{'force-update-modal-values.optional'|translate}} </label>
              </div>
              <div class="col-10 padding-0 radio-cont">
                <input type="radio" formControlName="force" value="{{ForceType.Enforced}}"  name="force">
                <label class="radio-label"> {{'force-update-modal-values.enforced'|translate}}</label>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button"  class="gray-btn" (click)="close()" >{{'force-update-modal-values.cancel-modal'|translate}}</button>
          <button type="submit" class="red-btn"  [disabled]='form.invalid || dimmSubmit' >{{'force-update-modal-values.save-modal'|translate}}</button>
        </div>
      </form>
    </div>

  </div>
</div>

