<div class="admin-top">
  <br />
  <p>{{'home.gdprProperties.title'|translate}}</p>
  <hr />
</div>
<!-- Card -->
<div class="friendly-url">
  <form (ngSubmit)="addGDPR()" [formGroup]="gdbrForm" >
    <div class="row">
      <div class="col-lg-8 offset-md-1 col-md-10 offset-md-1 col-12 toggle-container">
        <div class="admin-top">
          <sp-dropdown  formControlName="userType"  label="User Type"
          [selectedValue]="selectedUserType"
            [items]="userTypes"
            (selectedValueChanged)="selectedValueChanged($event)">
          </sp-dropdown>
        </div>
        <div class="admin-top">
          <p>Start Date</p>
          <div class="container">
            <input formControlName='startDate' type="date" class="form-control" />
          </div>
        </div>
        <div class="admin-top">
          <p>End Date</p>
          <div class="container">
            <input type="date"  class="form-control"  formControlName="endDate"/>
          </div>
        </div>

        <div class="admin-top">
          <p>Frequency</p>
          <div class="container">
              <input type="number"  class="form-control"  formControlName="Frequency"/>
            </div>
        </div>

      </div>
      <div class="configuration-btn col-lg-8 offset-md-1 col-md-10 offset-md-1 col-12">
        <button type="submit">Save</button>
      </div>
    </div>
  </form>
</div>