import { StorageService } from './../services/storage.service';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GroupNames } from '../enums/group-names.enum';
import { UserRoles } from '../enums/user-roles.enum';

@Injectable()
export class UserPermissionGuard implements CanActivate, CanActivateChild {
    groups = new Array<string>();
    constructor(private storageService: StorageService) {
    }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
        for (const group in GroupNames) {
            if (group) {
                this.groups.push(GroupNames[group]);
            }
        }
        const userGroups = this.storageService.userProfile.groups.filter(value =>  (this.groups.indexOf(value) !== -1) );
        const userRoles = this.storageService.userProfile.roles.filter(value =>  value.toLowerCase() === UserRoles.Admin.toLowerCase() );
        if (userGroups.length > 0 || userRoles.length > 0) {
            return true;

        }else {
            return false;
        }
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
        return this.canActivate(childRoute, state);
    }
}
