<div class="admin-top">
    <br />
    <p>{{'home.url-config.title'|translate}}</p>
    <hr />
</div>
<div *ngIf="showError" class="row">

    <div class="error-url col-lg-8 offset-md-1 col-md-10 offset-md-1 col-12"><span class="icon-warning"></span>
        {{'home.url-config.page-one.duplicatedValidation'|translate}}</div>
</div>
<div class="url-config">
    <form [formGroup]="configsForm">
        <div class="row">
            <div class="col-lg-8 offset-md-1 col-md-10 offset-md-1 col-12 toggle-container">
                <div class="admin-top row">
                    <div class="col-md-3 col-12">
                        <p class="title">{{'home.url-config.page-one.name'|translate}}:</p>
                    </div>
                    <div class="col-md-8 col-12 padding-r-0">
                        <sp-text formControlName="titleName" class="input-bg"></sp-text>
                    </div>
                </div>
                <div class="row center-row">
                    <div class="col-md-3 col-12">
                        <label class="container label-new-url">
                            <input (click)="checkUrl(true)" [checked]="!isNewURL" type="radio">
                            {{'home.url-config.page-one.oldURL'|translate}}
                            <span class="checkmark"></span>
                        </label>
                    </div>
                    <div class="col-md-8 col-12 padding-r-0">
                        <sp-text formControlName="oldURL" [disabled]="isNewURL" class="input-bg"></sp-text>
                    </div>
                </div>

                <div class="row center-row">
                    <div class="col-md-3 col-12">
                        <label class="container label-new-url">
                            <input (click)="checkUrl(false)" [checked]="isNewURL" type="radio">
                            {{'home.url-config.page-one.newURL'|translate}}
                            <span class="checkmark"></span>
                        </label>
                    </div>
                    <div class="col-md-8 col-12 padding-r-0">
                        <sp-text [showCopyIcon]="true" (copyIconClicked)="copyToClipbored($event)" formControlName="newURL"
                            class="input-bg new-short-url" [disabled]="isOldURL">
                        </sp-text>
                    </div>

                </div>

                <div class="admin-top row parameters-pos" *ngIf="!isOldURL">
                    <div class="col-md-3 col-12">
                        <p class="title">Parameters:</p>
                    </div>
                    <div class="col-5 col-md-4 parameter-width">
                        <input type="text" placeholder="Parameter Name" formControlName="parameterName" class="parameter-inputs">
                    </div>
                    <div class="col-5 col-md-4 parameter-width Parameter-value-margin">
                        <input type="text" placeholder="Parameter Value" formControlName="parameterValue" class="parameter-inputs">
                    </div>
                    <div class="add-parameter-container col-1">
                        <button *ngIf="!isOldURL" class="add-parameter" (click)="addParameter()" [disabled]="!(configsForm.controls['parameterName'].value&&configsForm.controls['parameterValue'].value)">
                            <i class=" icon icon-add-or-plus"></i>
                        </button>
                    </div>
                </div>

                <div class="row center-row">
                    <div *ngIf="!isOldURL" class="col-12 parameter-form add-parameter-padding" formArrayName="parameters">
                        <div class="row offset-md-3">
                            <div *ngFor="let parameterForm of configsForm.get('parameters')['controls'];let i=index"
                                [formGroupName]="i" class="col-4 parameter-added-margin">
                                <input type="text" readonly placeholder="Parameter Name" class="form-control parameter-control"
                                    [value]="parameterForm.value.ParameterName">

                                <div class="remove-parameter-container col-1">
                                    <span class="add-parameter remove-parameter" (click)="removeParameter(i)">
                                        <i class=" icon icon-close"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="admin-top row">
                    <sp-dropdown [selectedValue]="webSection" (change)="checkStore($event.target.value,this.configsForm)" label="{{'home.url-config.page-one.appSection'|translate}}"
                        [items]="webSectionNames" class="app-section">
                    </sp-dropdown>
                </div>

                <div class="admin-top row" *ngIf="!isOldURL">
                    <sp-dropdown [selectedValue]="selectedKey" (change)="selectedKey = $event.target.value" label="{{'home.url-config.page-one.if-not-installed'|translate}}"
                        [items]="choosePackageUrl" class="app-section" [isDisabled]="isWebPortalChecked||storeOnly">
                    </sp-dropdown>
                </div>

                <div class="admin-top row" *ngIf="!isOldURL">
                    <div class="col-md-3 col-12">
                        <p class="title">{{'home.url-config.page-one.desktopURL'|translate}}</p>
                    </div>
                    <div class="col-md-8 col-12 padding-r-0">
                        <sp-text formControlName="desktopURL" class="input-bg"></sp-text>
                    </div>
                </div>

                <div class="admin-top col-8 offset-md-3 checkbox-style" *ngIf="!storeOnly&&!isOldURL">
                    <label>
                        <input (change)="isWebPortalChecked = $event.target.checked" formControlName="isWebOnly" type="checkbox">
                        <span class="new-url">{{'home.url-config.page-one.notes.note1'|translate}}</span>
                    </label>

                </div>
                <div class="admin-top" *ngIf="storeOnly&&!isOldURL" class="hint">
                    <p>{{'home.url-config.page-one.notes.note2'|translate}}</p>
                </div>
                <div class="clearfix"></div>
                <div class="configuration-btn offset-md-1 col-12">
                    <button type="button" (click)="resetForm()">Reset</button>
                    <button class="blue-add" [disabled]="disabledForm()" (click)="addURL()" [ngClass]="{'gray-add':disabledForm(),
                    'blue-add':!disabledForm()}"
                        type="submit">{{'home.url-config.page-one.buttons.addLink'|translate}}</button>



                </div>
            </div>
            <!-- <div class="configuration-btn col-lg-8 offset-md-1 col-md-10 offset-md-1 col-12">
                <button [disabled]="!((configsForm.controls['titleName'].value||configsForm.controls['isWebOnly'].value  ||configsForm.controls['oldURL'].value || configsForm.controls['newURL'].value)||configsForm.controls['webSection'].value)||!selectedKey"
                    [ngClass]="{'gray-add':!((configsForm.controls['oldURL'].value || configsForm.controls['newURL'].value)&&configsForm.controls['webSection'].value)}"
                    type="submit">{{'home.url-config.page-one.buttons.addLink'|translate}}</button>
            </div> -->
        </div>
    </form>
    <div class="row" *ngIf="urls&&urls.length>0">
        <div class="col-lg-8 offset-md-1 col-md-10 offset-md-1 col-12 toggle-container table-urls">
            <div class="search-container">

                <sp-text class="search-url" [(ngModel)]="filterChar"></sp-text>
                <span class="icon icon-search"></span>
                <div class="clearfix"></div>
            </div>
            <div *ngFor="let url of urls|filter:filterChar" (click)="selectUrl(url)">

                <div *ngIf="url.sourceLink.includes('m.vodafone.es/') && selectedURL !== url" class="url-container">
                    <div class="row url-desc">
                        <div class="col-4">{{'home.url-config.page-one.fields.sectionName'|translate}}</div>
                        <div class="col-8 pl-0">{{url.name}}</div>
                    </div>
                    <div class="row url-desc">
                        <div class="col-4">{{'home.url-config.page-one.fields.newURL'|translate}}</div>
                        <div class="col-8 pl-0">{{url.sourceLink}}</div>

                    </div>
                    <div class="row url-desc">
                        <div class="col-4">{{'home.url-config.page-one.fields.appPortal'|translate}}</div>
                        <div class="col-8 pl-0">{{url.altUrl}}</div>


                    </div>
                    <div class="row url-desc">
                        <div class="col-4">{{'home.url-config.page-one.fields.alwaysWeb'|translate}}</div>
                        <div class="col-8 pl-0">{{url.alwaysWeb}}</div>


                    </div>

                </div>
                <!-- parsmeters section  -->
                <div class="url-container" *ngIf="selectedURL !== url">
                    <div *ngIf="!url.altUrl&&!url.alwaysWeb">
                        <!-- in case old url this Form will appear -->
                        <div class="row url-desc">
                            <div class="col-4">Name: </div>
                            <div class="col-8 pl-0">{{url.name}}</div>
                        </div>
                        <div class="row url-desc">
                            <div class="col-4">{{'home.url-config.page-one.fields.oldURL'|translate}}</div>
                            <div class="col-8 pl-0">{{url.sourceLink}}</div>


                        </div>
                        <div class="row url-desc">
                            <div class="col-4">{{'home.url-config.page-one.fields.sectionWeb'|translate}}</div>
                            <div class="col-8 pl-0">{{url.name}}</div>

                        </div>
                    </div>
                    <!-- in case New url this Form will appear -->
                    <div *ngIf="(url.altUrl||url.alwaysWeb)&& selectedURL !== url">
                        <div class="row url-desc">
                            <div class="col-4">Name: </div>
                            <div class="col-8 pl-0">{{url.name}}</div>
                        </div>
                        <div class="row url-desc">
                            <div class="col-4">New Short Link:</div>
                            <div class="col-8 pl-0">{{url.sourceLink}}</div>
                        </div>
                    </div>
                </div>
                <!-- details of sections -->
                <div *ngIf="selectedURL === url" class="url-container url-container-edit">
                    <div class="row url-desc" *ngIf="url.name">
                        <div class="col-4">Name: </div>
                        <div class="col-8 pl-0">{{url.name}}</div>
                    </div>
                    <div class="row url-desc" *ngIf="url.sourceLink">
                        <div class="col-4">{{'home.url-config.page-one.fields.oldURL'|translate}}</div>
                        <div class="col-8 pl-0">{{url.sourceLink}}</div>
                    </div>
                    <div class="row url-desc">
                        <div *ngIf="urlParamters.length">
                            <div class="col-4"> New short Link : </div>
                            <div class="col-8 pl-0 Showz">URL/short name <a class="blue-txt-parameters" (click)="showLinkParam()">{{isShowLinkParm?'Hide':'Show'}}
                                    Parameters</a></div>
                        </div>
                        <div class="row url-desc" *ngIf="isShowLinkParm">
                            <div class="col-9">
                                <div *ngFor="let parameter of urlParamters;let i=index" class="col-4 parameter-added-margin pl-0 mt-10">
                                    <input type="text" readonly placeholder="Parameter Name" class="form-control parameter-control"
                                        [value]="parameter.value.ParameterName">
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row url-desc">
                        <div class="col-4">{{'home.url-config.page-one.fields.sectionWeb'|translate}}</div>
                        <div class="col-8 pl-0">{{url.sectionWeb}}</div>

                    </div>
                    <div class="row url-desc">
                        <div class="col-4">{{'home.url-config.page-one.fields.if-not-installed'|translate}}</div>
                        <div class="col-8 pl-0">{{url.altUrl}}</div>

                    </div>
                    <div class="row url-desc">
                        <div class="col-4">{{'home.url-config.page-two.alwaysWeb'|translate}}</div>
                        <div class="col-8 pl-0 blue-txt">{{url.alwaysWeb}}</div>

                    </div>
                    <div class="row url-desc url-desc-btn-container">
                        <button class="Delete-btn" (click)="deleteURL(url)">Delete</button>
                        <button class="Edit-btn" (click)="editView(url)">Edit</button>
                    </div>
                    <!-- </div> -->

                </div>
                <!-- details of sections end -->
            </div>
        </div>
    </div>
    <div bsModal #modal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
        aria-hidden="true" [config]="{keyboard: false,backdrop : 'static'}">
        <div class="small modal-dialog allow-events">
            <div class="modal-content">
                <div class="modal-header" *ngIf="showCloseIcon">
                    <button type="button" class="close pull-right" aria-label="Close" (click)="hide()">
                        <span class='icon icon-close modal-close'></span>
                    </button>
                </div>
            </div>
            <div class="url-config">
                <div class="modal-body modal-edit toggle-container">
                    <div>
                        <div class="row title-edit center-row">
                            <div class="col-10">
                                {{'home.url-config.page-two.title'|translate}}
                            </div>
                            <div class="close-edit col-2" (click)="hide()">
                                <span class="remove-parameter">
                                    <i class="icon icon-close"></i>
                                </span>
                            </div>

                            <div class="clearfix"></div>
                        </div>
                        <div *ngIf="showError" class="row">

                            <div class="error-url col-lg-8 offset-md-1 col-md-10 offset-md-1 col-12"><span class="icon-warning"></span>
                                {{'home.url-config.page-one.duplicatedValidation'|translate}}</div>
                        </div>
                        <div class="row ">
                            <div class="description-form col-12 ">
                              <form [formGroup]="configsForm">
                                <div class="row">
                                    <div>
                                        <div class="admin-top row">
                                            <div class="col-md-3 col-12">
                                                <p class="title">{{'home.url-config.page-one.name'|translate}}:</p>
                                            </div>
                                            <div class="col-md-8 col-12 padding-r-0">
                                                <sp-text formControlName="titleName" class="input-bg"></sp-text>
                                            </div>
                                        </div>
                                        <div class="row center-row">
                                            <div class="col-md-3 col-12">
                                                <label class="container label-new-url">
                                                    <input [checked]="isOldURL" (click)="checkUrl(true)" formControlName="URLCheck" type="radio">
                                                    {{'home.url-config.page-one.oldURL'|translate}}
                                                    <span class="checkmark"></span>
                                                </label>
                                            </div>
                                            <div class="col-md-8 col-12 padding-r-0">
                                                <sp-text formControlName="oldURL" [disabled]="isNewURL" class="input-bg"></sp-text>
                                            </div>
                                        </div>

                                        <div class="row center-row">
                                            <div class="col-md-3 col-12">
                                                <label class="container label-new-url">
                                                    <input [checked]="isNewURL" (click)="checkUrl(false)" formControlName="URLCheck" type="radio">
                                                    {{'home.url-config.page-one.newURL'|translate}}
                                                    <span class="checkmark"></span>
                                                </label>
                                            </div>
                                            <div class="col-md-8 col-12 padding-r-0">
                                                <sp-text [showCopyIcon]="true" (copyIconClicked)="copyToClipbored($event)" formControlName="newURL"
                                                    class="input-bg new-short-url" [disabled]="isOldURL">
                                                </sp-text>
                                            </div>

                                        </div>

                                        <div class="admin-top row parameters-pos" *ngIf="!isOldURL">
                                            <div class="col-md-3 col-12">
                                                <p class="title">Parameters:</p>
                                            </div>
                                            <div class="col-5 col-md-4 parameter-width">
                                                <input type="text" placeholder="Parameter Name" formControlName="parameterName" class="parameter-inputs">
                                            </div>
                                            <div class="col-5 col-md-4 parameter-width Parameter-value-margin">
                                                <input type="text" placeholder="Parameter Value" formControlName="parameterValue" class="parameter-inputs">
                                            </div>
                                            <div class="add-parameter-container col-1">
                                                <button *ngIf="!isOldURL" class="add-parameter" (click)="addParameter()" [disabled]="!(configsForm.controls['parameterName'].value&&configsForm.controls['parameterValue'].value)">
                                                    <i class=" icon icon-add-or-plus"></i>
                                                </button>
                                            </div>
                                        </div>

                                        <div class="row center-row">
                                            <div *ngIf="!isOldURL" class="col-12 parameter-form add-parameter-padding" formArrayName="parameters">
                                                <div class="row offset-md-3">
                                                    <div *ngFor="let parameterForm of configsForm.get('parameters')['controls'];let i=index"
                                                        [formGroupName]="i" class="col-4 parameter-added-margin">
                                                        <input type="text" readonly placeholder="Parameter Name" class="form-control parameter-control"
                                                            [value]="parameterForm.value.ParameterName">

                                                        <div class="remove-parameter-container col-1">
                                                            <span class="add-parameter remove-parameter" (click)="removeParameter(i)">
                                                                <i class=" icon icon-close"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="admin-top row">
                                            <sp-dropdown [selectedValue]="webSectionEdit" (change)="checkStore($event.target.value,this.configsForm)" label="{{'home.url-config.page-one.appSection'|translate}}"
                                                [items]="webSectionNames" class="app-section">
                                            </sp-dropdown>
                                        </div>

                                        <div class="admin-top row" *ngIf="!isOldURL">
                                            <sp-dropdown [selectedValue]="editSelectedKey" (change)="editSelectedKey = $event.target.value" label="{{'home.url-config.page-one.if-not-installed'|translate}}"
                                                [items]="choosePackageUrl" class="app-section" [isDisabled]="isWebPortalChecked||storeOnly">
                                            </sp-dropdown>
                                        </div>

                                        <div class="admin-top row" *ngIf="!isOldURL">
                                            <div class="col-md-3 col-12">
                                                <p class="title">{{'home.url-config.page-one.desktopURL'|translate}}</p>
                                            </div>
                                            <div class="col-md-8 col-12 padding-r-0">
                                                <sp-text formControlName="desktopURL" class="input-bg"></sp-text>
                                            </div>
                                        </div>

                                        <div class="admin-top col-8 offset-md-3 checkbox-style" *ngIf="!storeOnly&&!isOldURL">
                                            <label>
                                                <input [checked]="isWebPortalCheckedEdit" (change)="isWebPortalCheckedEdit = $event.target.checked" formControlName="isWebOnly" type="checkbox">
                                                <span class="new-url">{{'home.url-config.page-one.notes.note1'|translate}}</span>
                                            </label>

                                        </div>
                                        <div class="admin-top" *ngIf="storeOnly&&!isOldURL" class="hint">
                                            <p>{{'home.url-config.page-one.notes.note2'|translate}}</p>
                                        </div>
                                        <div class="clearfix"></div>
                                        <div class="configuration-btn offset-md-1 col-12">
                                            <button type="button" (click)="resetForm()">Reset</button>
                                            <button class="blue-add" [disabled]="disabledForm()" (click)="updateURL()" [ngClass]="{'gray-add':disabledForm(),
                                            'blue-add':!disabledForm()}"
                                                type="submit">{{'home.url-config.page-two.buttons.save'|translate}}</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
