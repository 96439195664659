<div class="cuppa-dropdown" (clickOutside)="closeDropdown()">
    <div class="selected-list">
        <label>{{label}}</label>
        <div  class="c-btn" (click)="toggleDropdown()" type="button">
            <span >{{settings.text}}</span>
            <span class="icon" [ngClass]="icon"></span>
            <span *ngIf="settings.singleSelection">
                <span *ngFor="let item of selectedItems;trackBy: trackByFn;">
                    {{item.itemName}}
                </span>
            </span>
            <span *ngIf="selectedItems.length > settings.badgeShowLimit">+{{selectedItems.length - settings.badgeShowLimit }}</span>
            <span class="fa" [ngClass]="{'fa-angle-down': !isActive,'fa-angle-up':isActive}"></span>
        </div>
    </div>
    <div class="dropdown-list" [hidden]="!isActive">
        <div class="arrow-up"></div>
        <div class="list-area">
            <div class="pure-checkbox select-all" *ngIf="settings.enableCheckAll && !settings.singleSelection && !settings.limitSelection"
                (click)="toggleSelectAll()">
                <input type="checkbox" [checked]="areAllSelected()" [disabled]="settings.limitSelection == selectedItems.length" />
                <label>
                <span [hidden]="isSelectAll">{{settings.selectAllText}}</span>
                <span [hidden]="!isSelectAll">{{settings.unSelectAllText}}</span>
            </label>
            </div>
            <div class="list-filter" *ngIf="settings.enableSearchFilter">
                <span class="fa fa-search"></span>
                <input type="text" placeholder="Search" [(ngModel)]="filter.itemName">
            </div>
            <ul [style.maxHeight]="settings.maxHeight+'px'">
                <li *ngFor="let item of data | listFilter:filter; let i = index;" (click)="onItemClick(item,i)" class="pure-checkbox">
                    <input type="checkbox" [checked]="isSelected(item)" [disabled]="settings.limitSelection == selectedItems.length && !isSelected(item)"
                    />
                    <label>{{item.itemName}}</label>
                </li>
            </ul>
        </div>
    </div>
</div>
