
import {map} from 'rxjs/operators';
import { Observable } from 'rxjs';
import { NotificationService } from './notification.service';
import { TranslateService } from '@ngx-translate/core';
import { Notification } from './../../models/notification.model';
import { JSON_PATHS } from './../constants/defines';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ISideMenuItem, MenuItemsGroup } from './../../models/sidemenuItem.model';
import { Injectable, enableProdMode } from '@angular/core';
import * as Routes from '../constants/routes-config';
import { groupBy, sortBy, Dictionary } from 'lodash';
import * as jsonQuery from 'jsonpath';


@Injectable()
export class SidemenuConfigService {
  public menuItemsGroups: MenuItemsGroup = { AccountItems: [], ServiceItems: [], GeneralItems: [] };
  public selectedItem: ISideMenuItem;
  private accountItems: ISideMenuItem[] = [];
  private serviceItems: ISideMenuItem[] = [];
  private generalItems: ISideMenuItem[] = [];
  public isFiltered = false;
  private menuCategories: Record<'account' | 'service' | 'general', string> = {
    account: 'account',
    service: 'service',
    general: 'general'
  }

  constructor(private http: HttpClient,
    private translateService: TranslateService,
    private notificationService: NotificationService) { }

  loadMenuItems(): Observable<any> {
    this.resetArrays();
    let headers = new HttpHeaders();
    headers = headers.append('content-type', 'application/json');
    headers = headers.append('accept', 'application/json');
    const options:  Record<'headers', HttpHeaders> = {
      headers: headers
    };
    return this.http.get(Routes.API_URLS.SideMenuConfig.GET_ITEMS, options).pipe(map((response: any[]) => {
      if (response) {
        /** flag to inform me (add item or not) */
        let hasSubItems: boolean;
        /** sorting for the array by order property */
        const sortedItems = sortBy(response['items'], 'order');
        /** grouping the array by category and parentId propertis */
        const myGroupedItems: Dictionary<ISideMenuItem[]> = groupBy(sortedItems, function (item) {
          if (item.parentId !== undefined && item.parentId !== null) {
            return [item['category'], item.parentId];
          } else {
            return item['category'];
          }
        });
        for (const i in myGroupedItems) {
          for (let z = 0; z < myGroupedItems[i].length; z++) {
            const item = myGroupedItems[i][z];

            /** check if the current item is in the main root (parent or just in root alone) */
            if (!item.parentId) {
              hasSubItems = false;
              /** map the object into sp menu items structure to pass it to the custom component (sidemenu) */
              const spMenuItem: ISideMenuItem = {
                id: jsonQuery.value(item, JSON_PATHS.MENUITEM.ID),
                name: jsonQuery.value(item, JSON_PATHS.MENUITEM.NAME),
                title: jsonQuery.value(item, JSON_PATHS.MENUITEM.TITLE),
                enabled: jsonQuery.value(item, JSON_PATHS.MENUITEM.ENABLED),
                description: jsonQuery.value(item, JSON_PATHS.MENUITEM.DESC),
                iconName: item[JSON_PATHS.MENUITEM.ICON_NAME],
                category: jsonQuery.value(item, JSON_PATHS.MENUITEM.CATEGORY),
                order: jsonQuery.value(item, JSON_PATHS.MENUITEM.ORDER),
                apps: jsonQuery.value(item, JSON_PATHS.MENUITEM.APPS),
                navigation: jsonQuery.value(item, JSON_PATHS.MENUITEM.NAVIGATION),
                account: jsonQuery.value(item, JSON_PATHS.MENUITEM.ACCOUNT),
                segments: jsonQuery.value(item, JSON_PATHS.MENUITEM.SEGMENT)
              };


              /** check if current item is Active, so load its childs : else do nothing */
              /** check if item is enabled (active) then show it, otherwise don't show it */
              let currentItemChilds;
              const spSubItems: ISideMenuItem[] = [];
              for (const key in myGroupedItems) {
                /** search for the first children of the current item */
                /** and we are sure that this key (array of items) is all item's children (because I grouped it in a sigle group) */
                currentItemChilds = myGroupedItems[key].filter(x => x.parentId === item.id);
                /** check if I get the item or not */
                if (currentItemChilds !== undefined && currentItemChilds.length > 0) {
                  hasSubItems = true;
                  /** loop on myGroupedItems[key] group to create it as a sub items of current item */
                  for (const subItem of myGroupedItems[key]) {
                    /** and map these items to sub items */
                    const spSubItem: ISideMenuItem = {
                      parentId: subItem[JSON_PATHS.MENUITEM.PARENT_ID],
                      id: jsonQuery.value(subItem, JSON_PATHS.MENUITEM.ID),
                      name: jsonQuery.value(subItem, JSON_PATHS.MENUITEM.NAME),
                      title: jsonQuery.value(subItem, JSON_PATHS.MENUITEM.TITLE),
                      enabled: jsonQuery.value(subItem, JSON_PATHS.MENUITEM.ENABLED),
                      description: jsonQuery.value(subItem, JSON_PATHS.MENUITEM.DESC),
                      iconName: subItem[JSON_PATHS.MENUITEM.ICON_NAME],
                      category: jsonQuery.value(subItem, JSON_PATHS.MENUITEM.CATEGORY),
                      order: jsonQuery.value(subItem, JSON_PATHS.MENUITEM.ORDER),
                      apps: jsonQuery.value(subItem, JSON_PATHS.MENUITEM.APPS),
                      navigation: jsonQuery.value(subItem, JSON_PATHS.MENUITEM.NAVIGATION),
                      account: jsonQuery.value(subItem, JSON_PATHS.MENUITEM.ACCOUNT),
                      segments: jsonQuery.value(subItem, JSON_PATHS.MENUITEM.SEGMENT)
                    };
                    /** pushing current sub item into the subItems array */
                    spSubItems.push(spSubItem);
                  } /** end of sub item loop */
                  /** push sub items into the item */

                  /** remove all items of this key because I am sure that this key is one group */
                  /** reduce the count of elements in the array to make search more fast (loops with checks) */
                  myGroupedItems[key].slice();
                  break;
                }
              } /** end of key loop */
              /** check if you have no sub items*/
              switch (spMenuItem.category.toLowerCase()) {
                case this.menuCategories.account.toLowerCase():
                  this.accountItems.push(spMenuItem);
                  this.accountItems.push(...spSubItems);
                  break;
                case this.menuCategories.service.toLowerCase():
                  this.serviceItems.push(spMenuItem);
                  this.serviceItems.push(...spSubItems);
                  break;
                case this.menuCategories.general.toLowerCase():
                  this.generalItems.push(spMenuItem);
                  this.generalItems.push(...spSubItems);
                  break;
              }
            } else {
              /** if this item has parentId so I will skip this hole array (current key) */
              /** because I will handle it inside "if(!item.parentId)" part */
              break;
            }
          } /** end of item loop */
        } /** end of dictionary loop */
        this.copyLists();
      }
      return this.menuItemsGroups;

    }));
  }
  makeItemParent(childItem: ISideMenuItem) {
    if (this.isChild(childItem)) {
      // let index = this.getIndexOfItem(childItem);
      let arr: ISideMenuItem[] = this.getItemArray(childItem);
      let newParentItem = this.createNewItemReference(childItem);
      let indexOfNextParent = this.getIndexOfNextParent(childItem);
      if (indexOfNextParent === -1) { /** then child item's parent is last parent */
        newParentItem.order = arr[arr.length - 1].order + 1;
        return this.editItem(newParentItem).pipe(map(() => { }));
      } else {
        newParentItem.order = arr[indexOfNextParent].order;
        return this.editItem(newParentItem).pipe(map(() => {
          this.resortArray(arr, indexOfNextParent).subscribe();
        }));
      }
    }
  }
  makeItemChild(parentItem: ISideMenuItem) {
    if (this.isParent(parentItem) && this.getChildsOfParent(parentItem).length === 0) {
      let index: number = this.getIndexOfItem(parentItem);
      if (index > 0) {
        let arr = this.getItemArray(parentItem);
        let newChildItem = this.createNewItemReference(parentItem);
        let indexOfPreviousParent = this.getIndexOfPreviousParent(parentItem);
        newChildItem.order = this.getNextOrderOfChilds(arr[indexOfPreviousParent]);
        newChildItem.parentId = arr[indexOfPreviousParent].id;
        return this.editItem(newChildItem).pipe(map(() => { }));
      }
    }
  }
  private editItemHelper(item: ISideMenuItem, newCategory: string) {
    if (this.isParent(item)) {
      let childs = this.getChildsOfParent(item);
      item.category = newCategory;
      childs.forEach(x => {
        this.editItem(x, newCategory);
      });
    } else {
      item.category = newCategory;
    }
  }
  editItem(item: ISideMenuItem, newCategory = '') {
    /** check if category changed */
    if (newCategory !== '' && item.category !== newCategory) {
      this.editItemHelper(item, newCategory);
    }
    /** patch the item into API */
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/merge-patch+json');
    headers = headers.append('accept', 'application/json');
    const options = {
      headers: headers
    };
    return this.http.patch(Routes.API_URLS.SideMenuConfig.PATCH_ITEM.replace('{id}', item.id), item, options).pipe(map(() => { }));
  }
  addItem(item: ISideMenuItem) {
    const arr: ISideMenuItem[] = this.getItemArray(item);
    item.order = arr.length > 0 ? arr[arr.length - 1].order + 1 : 1;
    /** add the item into API */
    let headers = new HttpHeaders();
    headers = headers.append('content-type', 'application/json');
    headers = headers.append('accept', 'application/json');
    const options = {
      headers: headers
    };
    return this.http.post(Routes.API_URLS.SideMenuConfig.POST_ITEM, item, options).pipe(map(() => { }));
  }
  deleteItem(item: ISideMenuItem) {
    /** delete the item using API */
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('content-type', 'application/json');
    headers = headers.append('accept', 'application/json');
    const options = {
      headers: headers
    };
    return this.http.delete(Routes.API_URLS.SideMenuConfig.DELETE_ITEM.replace('{id}', item.id), options).pipe(map(() => { }));
  }
  /** the method will be called when press on down reorder button on the screen */
  swapWithNextItem(item: ISideMenuItem) {
    return this.swap(item).pipe(map(() => { }));
  }
  /** the method will be called when press on up reorder button on the screen */
  swapWithPreviousItem(item: ISideMenuItem) {
    return this.swap(item, 'previous').pipe(map(() => { }));
  }
  filterByAccountAndService(accountType: string, serviceType: string) {
    this.menuItemsGroups.AccountItems = serviceType.toLowerCase() === 'all' ? this.accountItems :
      this.accountItems.filter(x => this.filterItem(x, accountType, serviceType)).slice();

    this.menuItemsGroups.ServiceItems = serviceType.toLowerCase() === 'all' ? this.serviceItems :
      this.serviceItems.filter(x => this.filterItem(x, accountType, serviceType)).slice();

    this.menuItemsGroups.GeneralItems = serviceType.toLowerCase() === 'all' ? this.generalItems :
      this.generalItems.filter(x => this.filterItem(x, accountType, serviceType)).slice();
  }
  copyLists() {
    this.copyAccount();
    this.copyService();
    this.copyGeneral();
  }
  private copyAccount() { if (!this.isFiltered) { this.menuItemsGroups.AccountItems = this.accountItems.slice(); } }
  private copyService() { if (!this.isFiltered) { this.menuItemsGroups.ServiceItems = this.serviceItems.slice(); } }
  private copyGeneral() { if (!this.isFiltered) { this.menuItemsGroups.GeneralItems = this.generalItems.slice(); } }
  private isChild(item: ISideMenuItem) {
    return item.parentId;
  }
  private isParent(item: ISideMenuItem) {
    return !item.parentId;
  }
  private getItemArray(item: ISideMenuItem): ISideMenuItem[] {
    let result: ISideMenuItem[] = [];
    switch (item.category.toLowerCase()) {
      case this.menuCategories.account.toLowerCase():
        result = this.isFiltered ? this.menuItemsGroups.AccountItems : this.accountItems;
        break;
      case this.menuCategories.service.toLowerCase():
        result = this.isFiltered ? this.menuItemsGroups.ServiceItems : this.serviceItems;
        break;
      case this.menuCategories.general.toLowerCase():
        result = this.isFiltered ? this.menuItemsGroups.GeneralItems : this.generalItems;
        break;
    }
    return result;
  }
  private getItemById(id: string, array: ISideMenuItem[]) {
    let result: ISideMenuItem;
    result = array.find(x => x.id === id);
    return result;
  }
  private getItemsByParentId(id: string, array: ISideMenuItem[]) {
    return array.filter(x => x.parentId === id);
  }
  private getChildsOfParent(parentItem: ISideMenuItem) {
    let arr = this.getItemArray(parentItem);
    return this.getItemsByParentId(parentItem.id, arr);
  }
  private getIndexOfItem(item: ISideMenuItem) {
    let arr = this.getItemArray(item);
    return arr.indexOf(item);
  }
  private getIndexOfNextParent(childItem: ISideMenuItem) {
    let result = -1;
    let arr = this.getItemArray(childItem);
    let i = this.getIndexOfItem(childItem) + 1;
    while (result === -1 && i < arr.length) {
      if (this.isParent(arr[i])) {
        result = i;
      }
      i++;
    }
    return result;
  }
  private getIndexOfPreviousParent(parentItem: ISideMenuItem) {
    let result = -1;
    let arr = this.getItemArray(parentItem);
    let i = this.getIndexOfItem(parentItem) - 1;
    while (result === -1 && i >= 0) {
      if (this.isParent(arr[i])) {
        result = i;
      }
      i--;
    }
    return result;
  }
  private createNewItemReference(item: ISideMenuItem) {
    let newItem: ISideMenuItem = {
      id: item.id,
      name: item.name,
      title: item.title,
      account: item.account,
      apps: item.apps,
      category: item.category,
      description: item.description,
      enabled: item.enabled,
      navigation: item.navigation,
      segments: item.segments,
      iconName: item.iconName,
      parentId: null
    };
    /** all without parent id and order */
    return newItem;
  }
  private removeItemFromArray(item: ISideMenuItem) {
    let arr = this.getItemArray(item);
    let index = this.getIndexOfItem(item);
    if (index !== -1) {
      arr.splice(index, 1);
    }
  }
  private resortArray(array: ISideMenuItem[], startIndex: number, endIndex: number = -1) {
    let newOrders: { order: number, id: string }[] = [];
    if (endIndex === -1) {
      for (let i = startIndex; i < array.length; i++) {
        newOrders.push({ order: array[i].order + 1, id: array[i].id })
      }
    } else {
      for (let i = startIndex; i <= endIndex; i++) {
        newOrders.push({ order: array[i].order + 1, id: array[i].id })
      }
    }
    let headers = new HttpHeaders();
    headers = headers.append('content-type', 'application/merge-patch+json');
    headers = headers.append('accept', 'application/json');
    const options = {
      headers: headers
    };
    return this.http.patch(Routes.API_URLS.SideMenuConfig.PATCH_ORDER, newOrders, options).pipe(map(() => { }));
  }
  createError(errorKeyName: string) {
    const notification = new Notification();
    const modal = this;
    this.translateService.
      get('home.messages')
      .subscribe(data => {
        notification.bodyTitle = data['delete-message-title']
        notification.bodyContent = data[errorKeyName];
        notification.primaryButtonText = data['buttons']['sure'];
      });
    notification.primaryButtonStyle = 'btn registration';
    notification.primaryButtonClick = function () {
      modal.notificationService.notificationModal.hide();
    };
    this.notificationService.createNotification(notification);
  }





  private swap(item: ISideMenuItem, withWhat = 'next') {
    return this.swapHelper(item, withWhat).pipe(map(() => { }))
  }
  private swapHelper(item: ISideMenuItem, withWhat = 'next') {
    let index = this.getIndexOfItem(item);
    let arr = this.getItemArray(item);
    /** index of the item that will swapped with [index] item */
    let targetedIndex = -1;
    if (withWhat === 'next') {
      if (index !== arr.length - 1) {
        if ((this.isChild(arr[index]) && arr[index + 1] && this.isChild(arr[index + 1]))
          || (this.isParent(arr[index]) && this.getIndexOfNextParent(arr[index]) !== -1
            && this.getChildsOfParent(arr[index]).length === 0
            && this.getChildsOfParent(arr[this.getIndexOfNextParent(arr[index])]).length === 0)) {
          targetedIndex = index + 1;
        }
        else if (this.isParent(arr[index])) {
          targetedIndex = this.getIndexOfNextParent(arr[index]);
        }
      }
    } else {
      if (index !== 0) {
        if ((this.isChild(arr[index]) && arr[index - 1] && this.isChild(arr[index - 1]))
          || (this.isParent(arr[index]) && this.getIndexOfPreviousParent(arr[index]) !== -1
            && this.getChildsOfParent(arr[index]).length === 0
            && this.getChildsOfParent(arr[this.getIndexOfPreviousParent(arr[index])]).length === 0)) {

          targetedIndex = index - 1;
        }
        else if (this.isParent(arr[index])) {
          targetedIndex = this.getIndexOfPreviousParent(arr[index]);
        }
      }
    }
    if (targetedIndex !== -1) {
      const orderArray: ISideMenuItem[] = [];
      orderArray.push(arr[index]);
      orderArray.push(arr[targetedIndex]);
      const tempOrder = orderArray[0].order;
      orderArray[0].order = orderArray[1].order;
      orderArray[1].order = tempOrder;
      return this.resortArray(orderArray, 0).pipe(map(() => { }));
    } else {
      return new Observable<void>();
    }
  }
  private filterItem(item: any, customerType, serviceType) {
    let result = false;

    let trueConditionCount = 0;

    /** check on customer type (1) */
    let index = 0;
    while (!result && index < item.account.accountTypes.length) {
      trueConditionCount += customerType.toLowerCase() ===
        item.account.accountTypes[index].type.toString().toLowerCase() ? 1 : 0;
      if (trueConditionCount === 1) {
        /** check on service type (2) */
        let newIndex = 0;
        while (!result && newIndex < item.account.accountTypes[index].serviceTypes.length) {
          trueConditionCount += (item.account.accountTypes[index].serviceTypes[newIndex].type.toLowerCase()
            === serviceType.toLowerCase()) ? 1 : 0;
          newIndex++;
          result = trueConditionCount === 2;
        }
      }
      trueConditionCount = 0;
      index++;
    }

    /** return true/false to push/not this item to the array */
    return result;
  }
  private resetArrays() {
    this.accountItems = [];
    this.serviceItems = [];
    this.generalItems = [];
    this.menuItemsGroups.AccountItems = [];
    this.menuItemsGroups.ServiceItems = [];
    this.menuItemsGroups.GeneralItems = [];
  }
  private getNextOrderOfChilds(parentItem: ISideMenuItem) {
    const parentChilds = this.getChildsOfParent(parentItem);
    if (parentChilds.length) {
      return parentChilds[parentChilds.length - 1].order + 1;
    }
    return 1;
  }
}
