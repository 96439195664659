
import {map} from 'rxjs/operators';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { FormGroup, FormBuilder, FormArray, Validators, FormControl } from '@angular/forms';
import { UrlConfigService } from '../../common/services/url-config.service';
import { NotificationService } from '../../common/services/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { AddNewPageDeepLinkService } from '../../common/services/add-new-page-deep-link.service';
import { environment } from '../../../environments/environment';
import { newURLConfig, ModificationDate } from '../../models/url.model';
import { Notification } from './../../models/notification.model';
import { universalDeeplinksHeaders, universalLinkStatus, patterns, EXPIRATION_DATE, FORM_TYPE_ACTION } from '../../common/constants/defines';
import { IDropdown } from '../../common/components/sp-dropdown/IDropdown.interface';
import { DeepLinkNewPage } from '../../models/add-new-page-deepLink.model';
import { Observable } from 'rxjs';
import { Angular5Csv } from 'angular5-csv/dist/Angular5-csv';

@Component({
  selector: 'sp-add-new-deeplink',
  templateUrl: './add-new-deeplink.component.html',
  styleUrls: ['./add-new-deeplink.component.scss']
})
export class AddNewDeeplinkComponent implements OnInit {

  @ViewChild('modal', { static: true }) public modal: ModalDirective;
  public addDeepLinkForm: FormGroup;
  editDeepLinkForm: FormGroup;
  environmentURL: string = environment.webAppURL;
  webSectionNames: IDropdown[];

  webSection: string;

  selectedPage: DeepLinkNewPage;
  selectedKey: string;

  /** collection for segments in destination url */
  segmentsArray: string[];

  isEditMode: boolean = false;

  constructor(
    private fb: FormBuilder,
    public uRLConfig: UrlConfigService,
    public notificationService: NotificationService,
    private translateService: TranslateService,
    private addnewpageDeepLinkservice: AddNewPageDeepLinkService
  ) {
    this.webSectionNames = [];

  }

  ngOnInit() {

    this.addnewpageDeepLinkservice.pages.filter(el => !el.hideFromDeepLink).map(el => {
      this.webSectionNames.push({ text: el.name, value: el.destination });

    });
    const segments = new FormArray([]);
    this.addDeepLinkForm = this.fb.group({
      destination: ['', [Validators.required]],
      sourceLink: ['', [Validators.required]],
      description: [''],
      gotoMarket: [false],
      universalLinkStatus: [true],
      screenId: ['', [Validators.required]],
      servicesTypes: [[]],
      /** default Exp. date */
      expiration: [EXPIRATION_DATE],
      includes: [[]],
      analyticParameters: ['', [Validators.pattern(patterns.urlParameters)]],
      segments
    });
  }
  /**
    * Add new deeplink from the form submitted by user .
    */
  addDeepLink() {
    if (this.addDeepLinkForm.valid) {
      let url = new newURLConfig();
      url.name = this.addDeepLinkForm.value['description'];
      url.destination = this.addDeepLinkForm.value['destination'];
      const params: string = this.addDeepLinkForm.value['analyticParameters'];

      if (params) {
        url.destination = url.destination + params;
      }

      url.sourceLink = this.addDeepLinkForm.value['sourceLink'];
      url.gotomarket = this.addDeepLinkForm.value['gotoMarket'] || false;
      if (this.addDeepLinkForm.value['universalLinkStatus']) {
        url.status = universalLinkStatus.ACT;
      } else {
        url.status = universalLinkStatus.DES;
      }
      url.screenId = this.addDeepLinkForm.value['screenId'];
      url.serviceTypes = this.addDeepLinkForm.value['servicesTypes'];
      url.modifications = new ModificationDate();
      url.modifications.expiration = new Date(this.addDeepLinkForm.controls['expiration'].value).toISOString();
      url.included = this.addDeepLinkForm.value['includes'];
      this.preconfirmSubmit(this.addDeeplinkAndRefreshDeeplinks(url));
    }

  }
  /**
   * add new deeplink and update/refresh all deeplinks from DB
   * @param url new deeplink data
   */
  addDeeplinkAndRefreshDeeplinks(url) {
    return this.uRLConfig.addURL(url).pipe(map(() => {
      this.resetForm();
      this.uRLConfig.getAllURLS().subscribe(() => {
      });
    },
      (error) => {
        this.errorHandling();
      }));
  }
  /**
   * Preconfirmation.
   * @param primaryFunction Observable<Object>
   */
  private preconfirmSubmit(primaryFunction: Observable<void>): void {
    const notification: Notification = new Notification();
    this.translateService.get('home.preconfirm').subscribe(data => {
        notification.bodyTitle = data.title;
        notification.primaryButtonText = data.yes;
        notification.secondaryButtonText = data.no;
      });
    notification.primaryButtonClick = () => primaryFunction.subscribe(
      () => {
        this.notificationService.notificationModal.hide();
        this.successHandling();
      },
      () => {
        this.notificationService.notificationModal.hide();
        this.errorHandling();
      }
    );
    notification.secondaryButtonClick = () => this.notificationService.notificationModal.hide();
    this.notificationService.createNotification(notification);
  }
  /**
    * Handling Error.
    * @param {Boolean} isDelete is the notification for delete item (default = false)
    */
  private errorHandling(isDelete = false) {
    const notification: Notification = new Notification();
    const modal = this;
    this.translateService.
      get('home.messages')
      .subscribe(data => {
        notification.bodyTitle = data['error-title'];
        notification.bodyContent = data[isDelete ? 'menu-item-deleting-error' : 'error-text'];
        notification.primaryButtonText = data['buttons']['sure'];
      });
    notification.primaryButtonStyle = 'btn registration';
    notification.primaryButtonClick = function () {
      modal.notificationService.notificationModal.hide();
    };
    this.notificationService.createNotification(notification);
  }
  /**
    * Handling Success.
    */
  private successHandling(): void {
    const notification: Notification = new Notification();
    notification.modelImg = '/assets/images/selection.png'
    notification.primaryButtonStyle = 'btn registration';
    notification.primaryButtonText = this.translateService.instant('home.successNotification.buttons.ok');
    notification.primaryButtonClick = () => this.notificationService.notificationModal.hide();
    this.notificationService.createNotification(notification);
  }

  /**
   * Change toggle flags dynamically
   * @param toggle new toggle value
   * @param flageName a specific flag name
   */
  changeToggle(toggle, flageName,form : FormGroup = this.addDeepLinkForm) {
    form.controls[flageName].setValue(toggle);

  }
  /**
   * Method to split url and check if it has a url segment or not and return an array with the final result
   * @param {string} url that we need to check if it have segment or not  
   */
  getURLSegments(url: string) {
    let resultArr = [];
    // URL='example.com/event/{service_id}?p=10';
    var arr = url.split('?')[0].split('/');
    //arr[0]='example.com'
    //arr[1]='event'
    //arr[2]='{service_id}'
    arr.forEach(item => {
      if (item.includes("{")) {
        resultArr.push(item.split('{')[1].split('}')[0]);
        //resultArr[0]='service_id'
      }
    })
    return resultArr;
  }
  /**
   * Select specific item from dropdown and do segment logic if exists 
   * @param selectedValue selected item from destination drop down
   * @param form the form we are using
   */
  selectDestination(selectedValue, form: FormGroup) {
    form.get('segments')['controls'] = [];
    this.selectedPage = this.addnewpageDeepLinkservice.pages.find(el => el.destination.toLowerCase() === selectedValue.toLowerCase());
    this.webSection = selectedValue;
    this.segmentsArray = this.getURLSegments(this.selectedPage.destination);
    if (this.segmentsArray.length > 0) {
      this.segmentsArray.forEach(item => {
        (<FormArray>form.get('segments')).push(
          new FormGroup({
            'segmentName': new FormControl(item),
            'segmentValue': new FormControl('')
          })
        );
      })
    }
    form.controls['screenId'].setValue(selectedValue);
    form.controls['destination'].setValue(this.selectedPage.destination);
    form.controls['servicesTypes'].setValue(this.selectedPage.serviceTypes);
    form.controls['includes'].setValue(this.selectedPage.included);
  }
  /**
   * Change specific segment value dynamically
   * @param val the new value for the segment
   * @param {string} segmentName a specific segment name
   */
  changeSegmentValue(val, segmentName: string,form : FormGroup = this.addDeepLinkForm) {
    this.selectedPage.destination = this.selectedPage.destination.replace('{' + segmentName + '}', val.target.value);
    form.controls['destination'].setValue(this.selectedPage.destination);
  }
  /**
    * Generate and Download CSV file.
    * @return {Blob} Blob represent CSV file of the universal deeplinks.
    */
  exportCSV() {
    const options = {
      fieldSeparator: ';',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      headers: [...universalDeeplinksHeaders],
      useBom: false,
    };
    let arr = this.uRLConfig.urls.map(item => {
      return {
        'id': item.id,
        'name': item.name,
        'sourceLink': item.sourceLink,
        'destination': item.destination,
        'gotomarket': item.gotomarket,
        'universalLink': item.status,
        'expiration': item.modifications.expiration
      }
    })
    new Angular5Csv(arr, 'DeepLinks', options);
  }
  /**
 * Resetting the Form and segments dynamic control
 */
  resetForm(): void {
    this.addDeepLinkForm.reset();
    this.addDeepLinkForm.get('segments')['controls'] = [];
    this.addDeepLinkForm.controls['universalLinkStatus'].setValue(true);
    this.addDeepLinkForm.controls['expiration'].setValue(EXPIRATION_DATE);
    this.webSection = null;
    this.isEditMode = false;
    this.segmentsArray = [];
  }

  /**
   * delet url from DB
   * @param url the url that we need to delete
   */
  deleteURL(url) {
    const notification: Notification = new Notification();
    this.translateService.
      get('home.messages')
      .subscribe(data => {
        notification.bodyTitle = data['delete-message-title'];
        notification.bodyContent = data['delete-text'];
        notification.primaryButtonText = data['buttons']['sure'];
        notification.secondaryButtonText = data['buttons']['cancel'];
      });
    notification.primaryButtonClick = () => {
      this.uRLConfig.deleteURL(url).subscribe(() => {
        this.uRLConfig.getAllURLS().subscribe(() => {
        });
      },
        () => {
          this.errorHandling(true);
        });
      this.notificationService.notificationModal.hide();
    };
    notification.secondaryButtonClick = () => {
      this.notificationService.notificationModal.hide();
    };
    this.notificationService.createNotification(notification);
  }

  editView(value) {
    const segments = new FormArray([]);

    this.isEditMode = true;
    const analyticParameters: string = value.destination.split('?')[1] ? `?${value.destination.split('?')[1]}` : '';

    this.editDeepLinkForm = this.fb.group({
      url: [value],
      destination: [value.destination.split('?')[0], [Validators.required]],
      sourceLink: [value.sourceLink, [Validators.required]],
      description: [value.name],
      gotoMarket: [value.gotomarket ? true : false],
      universalLinkStatus: [value.status == universalLinkStatus.ACT ? true : false],
      screenId: [value.destination.split('?')[0], [Validators.required]],
      servicesTypes: [value.servicesTypes],
      expirationEdit: [new Date(value.modifications.expiration).toISOString().split('T')[0], [Validators.required]],
      includes: [value.included],
      analyticParameters: [analyticParameters, [Validators.pattern(patterns.urlParameters)]],
      segments
    });
    this.modal.show();
  }

  editDeepLink() {
    if (this.editDeepLinkForm.valid) {
      let url = this.editDeepLinkForm.value['url'];
      url.name = this.editDeepLinkForm.value['description'];
      url.destination = this.editDeepLinkForm.value['destination'];
      const params: string = this.editDeepLinkForm.value['analyticParameters'];

      if (params) {
        url.destination = url.destination + params;
      }

      url.sourceLink = this.editDeepLinkForm.value['sourceLink'];
      url.gotomarket = this.editDeepLinkForm.value['gotoMarket'];
      if (this.editDeepLinkForm.value['universalLinkStatus']) {
        url.status = universalLinkStatus.ACT;
      } else {
        url.status = universalLinkStatus.DES;
      }
      url.screenId = this.editDeepLinkForm.value['screenId'];
      url.serviceTypes = this.editDeepLinkForm.value['servicesTypes'];
      url.modifications = new ModificationDate();
      url.modifications.expiration = new Date(this.editDeepLinkForm.value['expirationEdit']).toISOString();
      url.included = this.editDeepLinkForm.value['includes'];
      this.preconfirmSubmit(this.editDeeplinkAndRefreshDeeplinks(url));
    }
  }

  /**
   * add new deeplink and update/refresh all deeplinks from DB
   * @param url new deeplink data
   */
  editDeeplinkAndRefreshDeeplinks(url) {
    return this.uRLConfig.updateURL(url).pipe(map(() => {
      this.modal.hide();
      this.resetForm()
      this.uRLConfig.getAllURLS().subscribe(() => {
      });
    },
      (error) => {
        this.errorHandling();
      }));
  }

  hideEditView(): void {
    this.resetForm();
    this.modal.hide();
  }


}
