<div class="form-group col-12  dropdown-form">
  <div class="dropdown">
      <label for="{{id}}">{{label}}
          <span [ngClass]="{'required': required}" *ngIf="required" style="color:red">*</span>
      </label>
      <select [disabled]="isDisabled" class="selectpicker form-control" name="{{name}}" id="{{id}}" [(ngModel)]="selected">
            <option style="display: none;" value="" *ngIf="defaultOptionText">{{defaultOptionText}}</option>
        <option value="{{item.value}}" *ngFor="let item of items">
          <div class="form-group col-12 check-box text-align-large">
            <div>
                <input id="checkbox" class="col-1" type="checkbox" value="{{item}}" />
                <label for="checkbox"></label>
            </div>
            <span class="col-11">{{item}}</span>
        </div>
        </option>
      </select>
  </div>
</div>