
import {map} from 'rxjs/operators';
import { UserPermissions, Permission } from './../../models/user-permissions.model';
import { config } from './../../../config/pages-config';
import { urls } from './../../../config/urls-config';
import { URLConfig } from './../../models/url.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as Routes from '../constants/routes-config';
import { JSON_PATHS, locationHeader, CONFIG } from '../constants/defines';
import * as jsonQuery from 'jsonpath';
import { HttpHeaders } from '@angular/common/http';

@Injectable()
export class UserPermissionsService {
    userPermissions: Array<UserPermissions>;
    constructor(private http: HttpClient) {
    }
    getUserPermissions() {
        this.userPermissions = new Array<UserPermissions>();
        const url = Routes.API_URLS.userPermissions.GET_PERMISSIONS;
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        const options = {
            headers: headers
        };

        return this.http.get(url, options).pipe(map((res: Array<any>) => {
            const items = jsonQuery.value(res, JSON_PATHS.USERPERMISSIONS.ITEMS);
            if (items) {
                items.map(item => {
                    const userPermission = new UserPermissions();
                    userPermission.groupName = jsonQuery.value(item, JSON_PATHS.USERPERMISSIONS.GROUPNAME) || null;
                    userPermission.permissions = new Array<Permission>();
                    userPermission.permissions = jsonQuery.value(item, JSON_PATHS.USERPERMISSIONS.PERMISSIONS) || null;
                    this.userPermissions.push(userPermission);
                });
            }
            return res;
        }));
    }
    editUserPermissions(body) {
        const requestBody = { 'items': body };
        const url = Routes.API_URLS.userPermissions.POST_PERMISSIONS;
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        return this.http.put(url, requestBody, {
            headers: headers, responseType: 'text'
        });
    }
}
