import { Component, Input, OnInit, forwardRef, AfterViewInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, FormControl } from '@angular/forms';
/**
 * create dropdown validator for the form control
 * @param {required: boolean} indicates the required is (true = required) or not (false = not required)
 */
export function createDropdownValidator(required: boolean) {
  return (c: FormControl) => {
    if (required) {
      return c.value === '' ? null : {};
    } else {
      return null;
    }
  };
}

@Component({
  selector: 'app-check-box-drop-down',
  templateUrl: './check-box-drop-down.component.html',
  styleUrls: ['./check-box-drop-down.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckBoxDropDownComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CheckBoxDropDownComponent),
      multi: true
    }
  ]
})
export class CheckBoxDropDownComponent implements OnInit {

 /** the dropdown label */
 @Input() label: string;
 /** the dropdown id */
 @Input() id: string;
 /** the dropdown name */
 @Input() name: string;
 /** the default selected value item may be recieved from user*/
 @Input() selectedValue = '';
 /** the dropdown list items */
 @Input() items: any[];
 /** The flag which indicates if the dropdown required or not. */
 @Input() required: boolean;
 /** The flag which indicates if the dropdown disabled or not. */
 @Input() isDisabled = false;
 /** The default option text of dropdown */
 @Input() defaultOptionText = '';
 /** will recieved the validator function on ngOnInit event */
 private validateFn: any;
 /** The call back which will be called if the user change the selected item */
 private onChangeCallback: Function = function () { };

 ngOnInit() {
   this.validateFn = createDropdownValidator(this.required);
   /** check if the selectedItem is not in the items list and assign an empty string to selectedValue,
    * to show the default text option
    */
   let i = 0;
   while (i < this.items.length && this.items[i].value !== this.selectedValue) {
     i++;
   }
   if (i === this.items.length) {
     this.selectedValue = '';
   }
 }
 /** to handle if the user sent a selectedValue to the custom component will make the form is
  * valid call onChangeCallback with any changes on the selectedValue */
 ngAfterContentChecked(): void {
   this.onChangeCallback(this.selectedValue);
 }
 /** will be fired after every changes, we use this function because we use NG_VALIDATORS */
 validate(c: FormControl) {
   return this.validateFn(c);
 }
 /** getter and setter properties for ngModel (2 way binding), and changes the selectedValue*/
 get selected(): any {
   return this.selectedValue;
 }
 set selected(v: any) {
   if (v !== this.selectedValue) {
     this.selectedValue = v;
   }
 }

 /** these function implemented from ControlValueAccessor inteface*/
 writeValue(obj: any): void { }
 registerOnTouched(fn: any): void { }
 setDisabledState(isDisabled: boolean): void { }
 /** copy the onChange function to the variable onChangeCallback and call it later*/
 registerOnChange(fn: any): void {
   this.onChangeCallback = fn;
 }
}




