import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { WalletInfoCSVService } from '../../common/services/walletInfo-CSV.service';
import { UtilsService } from '../../common/utils/utils.service';


@Component({
  selector: 'sp-export-wallet-info',
  templateUrl: './export-wallet-info.component.html',
  styleUrls: ['./export-wallet-info.component.scss']
})
export class ExportWalletInfoComponent implements OnInit {
  exportWalletInfoForm: FormGroup;
  constructor(private formBuilder: FormBuilder,
    private walletCSVservice: WalletInfoCSVService,
    private utiliy: UtilsService) { }

  ngOnInit() {
    this.exportWalletInfoForm = this.formBuilder.group({
      startSaveDate: [''],
      endSaveDate: [''],
      startExpiryDate: [''],
      endExpiryDate: [''],
    });
  }

  /**Export wallet info in CSV file */
  exportWalletInfo(): void {
    const startSaveDate: string = this.exportWalletInfoForm.value.startSaveDate ?
    this.utiliy.getFormattedDateForAPI(new Date(this.exportWalletInfoForm.value.startSaveDate)) : '';
    const endSaveDate: string = this.exportWalletInfoForm.value.endSaveDate ?
    this.utiliy.getFormattedDateForAPI(new Date(this.exportWalletInfoForm.value.endSaveDate)) : '' ;
    const startExpiryDate: string = this.exportWalletInfoForm.value.startExpiryDate ?
    this.utiliy.getFormattedDateForAPI(new Date(this.exportWalletInfoForm.value.startExpiryDate)) : '';
    const endExpiryDate: string = this.exportWalletInfoForm.value.endExpiryDate ?
    this.utiliy.getFormattedDateForAPI(new Date(this.exportWalletInfoForm.value.endExpiryDate)) : '';

    this.walletCSVservice.exportToCSV(startSaveDate, endSaveDate, startExpiryDate, endExpiryDate);
  }

}
