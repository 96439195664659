<div class="admin-top">
  <br />
  <p>{{'home.paymentCSV.title'|translate}}</p>
  <hr />
</div>

<div class="friendly-url">
  <form (ngSubmit)="exportCSV()" [formGroup]="exportPaymentForm">
    <div class="row">
      <div class="col-lg-8 offset-md-1 col-md-10 offset-md-1 col-12 toggle-container">
        <div class="admin-top">
          <sp-dropdown formControlName="paymentType" label="{{'home.paymentCSV.payment-type' | translate}}"
            [selectedValue]="selectedPaymentType" [items]="paymentTypes"
            (selectedValueChanged)="selectedPaymentType = $event">
          </sp-dropdown>
        </div>
        <div class="admin-top">
          <p>{{'home.paymentCSV.start-date' | translate}}</p>
          <div class="container">
            <input formControlName='startDate' type="date" class="form-control" />
          </div>
        </div>
        <div class="admin-top">
          <p>{{'home.paymentCSV.end-date' | translate}}</p>
          <div class="container">
            <input type="date" class="form-control" formControlName="endDate" />
          </div>
        </div>

      </div>
      <div class="configuration-btn col-lg-8 offset-md-1 col-md-10 offset-md-1 col-12">
        <button type="submit">{{'home.paymentCSV.export-btn' | translate}}</button>
      </div>
    </div>
  </form>
</div>