import { Component, OnInit } from '@angular/core';
import { ForceUpdateService } from './force-update.service';
import { platform, patterns, rowStatus } from '../../common/constants/defines';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ForceUpdateData, BuildNumberInterface } from './force-update.interface';
import { BuildStatus, ForceType } from '../../common/enums/force-update.enum';
import { BuildRangeAndVersionRule, isBiggerOrEqual, ValidateBuildNumber, isBiggerOrEqualThanLatestBuild } from './force-update.validator';
import { Notification } from '../../models/notification.model';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '../../common/services/notification.service';
import { ConfigurationService } from '../../common/services/configuration.service';
import { UtilsService } from '../../common/utils/utils.service';


@Component({
  selector: 'sp-force-update',
  templateUrl: './force-update.component.html',
  styleUrls: ['./force-update.component.scss']
})

export class ForceUpdateComponent implements OnInit {
  showForceUpdateAddModal: boolean = false;
  showForceUpdateEditModel: boolean = false;
  form: FormGroup;
  versionBuildNumber: any;
  ForceType = ForceType;
  platform = platform;
  buildList: ForceUpdateData[];
  create: boolean;
  tableHeaders: any;
  modalText: String;
  platformText: String;
  patterns = patterns;
  status = '';
  dimmSubmit:boolean = false;
  constructor(private forceUpdateService: ForceUpdateService, private _fb: FormBuilder, private translateService: TranslateService,
    private notificationService: NotificationService, private configurationService: ConfigurationService,
    private utilsService: UtilsService) {
     
    this.form = _fb.group({
      version: _fb.group({
        from: _fb.group(
          {
            major: [''],
            minor: [''],
            build: [''],
          }, { validator: ValidateBuildNumber }),
        to: _fb.group(
          {
            major: [''],
            minor: [''],
            build: [''],
          }, { validator: ValidateBuildNumber }),
      }, { validator: BuildRangeAndVersionRule }),
      force: [ForceType.Optional, Validators.required],
      osType: ['', Validators.required],
      id: ['']
    }, { validator: isBiggerOrEqualThanLatestBuild(this.configurationService.getLatestBuilds()) }
    );
  }

  ngOnInit() {
    this.status = rowStatus.status;
    this.getInitialGridData();
    this.translateService.get('force-update-table-configurations').subscribe(data => {
      this.tableHeaders = data;
    });
  }

  getInitialGridData() {
    this.forceUpdateService.getGridData()
      .subscribe((res: any) => {
        this.buildList = res;
      }, (error) => {
        this.forceUpdateService.createForceUpdateError('fail-msg');
      });
  }

  deleteTableRow(build) {
    const notification = new Notification();
    const modal = this;
    this.translateService.
      get('home.messages')
      .subscribe(data => {
        notification.bodyContent = data['delete-text'];
        notification.primaryButtonText = data['buttons']['cancel'];
        notification.secondaryButtonText = data['buttons']['sure'];
      });
    notification.primaryButtonStyle = 'btn registration';
    notification.primaryButtonClick = function () {
      modal.notificationService.notificationModal.hide();
    };
    notification.secondaryButtonClick = () => {
      this.forceUpdateService.deleteBuildData(build).subscribe(data => {
        build.status = this.status;
        modal.notificationService.notificationModal.hide();
      });
    };
    this.notificationService.createNotification(notification);
  }

  showForceUpdateEditModal(selectedData?: ForceUpdateData) {
    // send selectedData to model as form value 
    if (selectedData && selectedData.version) {
      const selected = JSON.parse(JSON.stringify(selectedData));
      selected.version.from = this.utilsService.versionFormat(selected.version.from)
      selected.version.to = this.utilsService.versionFormat(selected.version.to)
      selected.force = selected.force.toString()
      this.translateService.get('force-update-modal-values').subscribe((data) => {
        this.modalText = data['edit-modal-label'].replace('{0}', this.formatVersion(selectedData.version));
      });
      this.form.patchValue(selected);
    } else {
      this.create = true;
      this.translateService.get('force-update-modal-values').subscribe((data) => {
        this.modalText = data['add-modal-label'];
      });
    }
    this.showForceUpdateEditModel = true;
  }

  close() {
    this.create = null;
    this.dimmSubmit = false;
    this.form.reset();
    this.showForceUpdateEditModel = false;
  }

  formatVersion(version): string {
    const symbol = version.from ? (version.to ? ":" : "<") : ">";
    if (typeof version.from !== "string") this.getVersionString(version.from);
    if (typeof version.to !== "string") this.getVersionString(version.to);

    return version.from + symbol + version.to
  }

  getVersionString(buildNumber: BuildNumberInterface): any {
    // if value contains null we have to return empty string 
    if (this.utilsService.checkPropertiesNotNull(buildNumber)) return ''
    return `${buildNumber.major}.${buildNumber.minor}.${buildNumber.build}`
  }

  sendNewBuildDetails() {
    this.sendBuildData(this.form.value).subscribe((res) => {
      this.forceUpdateService.getGridData().subscribe((data: any) => {
        this.buildList = data;
        this.close();
      })
    }, (error) => {
      this.showForceUpdateEditModel = false;
      this.forceUpdateService.createForceUpdateError('error-text');
    })
  }

  save() {
    // validation 
    if (!this.isDataOverlapping(this.form.value)) {
      this.dimmSubmit = true;
      this.sendNewBuildDetails();
    } else {
      const notification = new Notification();
      this.translateService.
        get('home.messages')
        .subscribe(data => {
          notification.bodyContent = data['force-update-items-overlap'];
          notification.secondaryButtonText = data['buttons']['sure'];
          notification.primaryButtonText = data['buttons']['cancel']
        });
      notification.primaryButtonClick = () => {
        this.notificationService.notificationModal.hide();
      };
      notification.secondaryButtonClick = () => {
        this.sendNewBuildDetails();
        this.notificationService.notificationModal.hide();
      };
      this.notificationService.createNotification(notification);
    }
  }

  sendBuildData(form): any {
    form.version.from = this.getVersionString(form.version.from);
    form.version.to = this.getVersionString(form.version.to);
    form.force = form.force.toLowerCase() === ForceType.Enforced.toLowerCase() ? true : false;
    return this.create ? this.forceUpdateService.addBuildData(form) : this.forceUpdateService.updateBuildData(form);
  }

  isDataOverlapping(data: ForceUpdateData): boolean {
    // Checking that active ( not expired) record exists with the same Type (Enforced/Optional) and overlapping range.
    // check if the buildList do not have any overlapping build data  
    return this.buildList.filter((build) => {
      // return just build with status active 
      // active ( not expired)
      return (build.status.toLocaleLowerCase() === BuildStatus.active.toLocaleLowerCase())
        && (build.osType.toLocaleLowerCase() === data.osType.toLocaleLowerCase());
    }).some((item: ForceUpdateData, idx: number, arr) => {
      // overlapping range
      /*
        // example  
        -                      | from-----item-----to  |
        - from-----data-----to |         |             |  from-----data-----to       // true
        -                      |         |from-------data-------to                   // false 
      */
      if (isBiggerOrEqual(this.utilsService.versionFormat(item.version.from), this.utilsService.versionFormat(data.version.from))) {
        if (isBiggerOrEqual(this.utilsService.versionFormat(item.version.to), this.utilsService.versionFormat(data.version.from))) {
          return false;  // not overlapping
        } else {
          return true;   // overlapping
        }
      } else {
        if (isBiggerOrEqual(this.utilsService.versionFormat(data.version.to), this.utilsService.versionFormat(item.version.from))) {
          return false;
        } else {
          return true;
        }
      }
    });
  }


}





