<sp-sidemenu-new-item></sp-sidemenu-new-item>
<div class="container login-container">
    <div class="row">
        <div class="col-lg-6 offset-md-3 col-md-8 offset-md-2 col-12">
            <div class="alert-container" *ngIf="isLoginError">
                <div class="alert-content">
                    <div class="alert-icon">
                        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                            x="0px" y="0px" width="45px" height="64px" viewBox="0 0 64 64" enable-background="new 0 0 64 64"
                            xml:space="preserve">
                            <g>
                                <defs>
                                    <rect id="SVGID_1_" width="64" height="64" />
                                </defs>
                                <clipPath id="SVGID_2_">
                                    <use xlink:href="#SVGID_1_" overflow="visible" />
                                </clipPath>
                                <path clip-path="url(#SVGID_2_)" fill="#333333" d="M51.081,56.46h-38.16c-2.99,0-5.28-1.12-6.46-3.14
                            c-1.22-2.08-1.02-4.852,0.53-7.59l19.06-35.03c1.56-2.68,3.74-4.2,6.01-4.2h0.048c2.272,0.02,4.34,1.51,5.83,4.19l19.072,35.04
                            c1.55,2.738,1.74,5.51,0.53,7.59C56.359,55.34,54.071,56.46,51.081,56.46 M32.061,8.49c-1.54,0-3.09,1.16-4.27,3.18L8.731,46.7
                            c-1.2,2.12-1.4,4.16-0.55,5.608c0.81,1.392,2.49,2.152,4.74,2.152h38.15c2.25,0,3.93-0.76,4.74-2.152
                            c0.85-1.448,0.65-3.488-0.542-5.598l-0.008-0.01L36.189,11.65c-1.128-2.02-2.58-3.14-4.098-3.16H32.061z" />
                                <path clip-path="url(#SVGID_2_)" fill="#333333" d="M32,40.619c-0.55,0-1-0.45-1-1v-18.83c0-0.548,0.45-1,1-1c0.55,0,1,0.452,1,1
                            v18.83C33,40.179,32.55,40.619,32,40.619" />
                                <path clip-path="url(#SVGID_2_)" fill="#333333" d="M32,47.789c-0.92,0-1.67-0.75-1.67-1.67c0-0.92,0.75-1.67,1.67-1.67
                            c0.92,0,1.67,0.75,1.67,1.67C33.67,47.039,32.92,47.789,32,47.789" />
                            </g>
                        </svg>
                    </div>
                    <div class="alert-description">
                        <p>{{'login.warning.text1'|translate}}
                            <a class="admin">{{'login.warning.text2'|translate}}</a></p>
                    </div>
                </div>
            </div>

            <div class="admin-login-form">
                <form (ngSubmit)="cavLogin()" novalidate="true">
                    <fieldset>
                        <div class="button-container">
                            <button type="submit" class="my-btn btn">{{'login.buttons.text'|translate}}
                            </button>
                        </div>
                    </fieldset>
                </form>

            </div>
        </div>
    </div>
</div>

<form ngNoForm action="{{cavUrl}}" class="no-display" method="POST" enctype="multipart/form-data;">
    <input id="RelayState" name="RelayState" type="hidden" [(ngModel)]="relayState" />
    <input id="SAMLRequest" name="SAMLRequest" type="hidden" [(ngModel)]="SAMLRequest" />
    <button #loginBtn class="invisible" name="loginBtn" type="submit">Login</button>
</form>