<div class="col-lg-12 offset-md-1 col-md-10 offset-md-1 col-12">
  <div class="form-container">
    <form [formGroup]="productForm" (ngSubmit)="submit()">
      <div formArrayName="productArray" *ngFor="let product of currentConfig; let i = index;">
        <div [formGroupName]="i">
            <h3 class="feature-title">{{product.featureID}}</h3>
            <div class="feature-container">
              <div class="feature-control">
                <input [id]="product.featureID+'ios'" type="checkbox" formControlName="ios" value="{{product.ios}}">
                <label [for]="product.featureID+'ios'">{{'home.products-config.ios' | translate}}</label>
              </div>
              <div class="feature-control">
                <input [id]="product.featureID+'android'" type="checkbox" formControlName="android" value="{{product.android}}">
                <label [for]="product.featureID+'android'">{{'home.products-config.android' | translate}}</label>
              </div>
              <div class="feature-control">
                <input [id]="product.featureID+'web'" type="checkbox" formControlName="web" value="{{product.web}}">
                <label [for]="product.featureID+'web'">{{'home.products-config.web' | translate}}</label>
              </div>
              <div class="feature-control">
                <input [id]="product.featureID+'dxl'" type="checkbox" formControlName="dxl" value="{{product.dxl}}">
                <label [for]="product.featureID+'dxl'">{{'home.products-config.dxl' | translate}}</label>
              </div>
              <div class="feature-control">
                <input [id]="product.featureID+'adobe'" type="checkbox" formControlName="adobe" value="{{product.adobe}}">
                <label [for]="product.featureID+'adobe'">{{'home.products-config.adobe' | translate}}</label>
              </div>
            </div>
            <hr>
        </div>
      </div>
      <button type="submit" class="form-button">{{'home.messages.buttons.save' | translate}}</button>
    </form>
  </div>
</div>