import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as Routes from '../../common/constants/routes-config';

@Injectable()
export class LatestBuildsService {

  constructor(private http: HttpClient) { }

  updateBuildData(objectToBeSent) {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/merge-patch+json');
    headers = headers.append('Accept', 'application/json');
    const options = {
      headers: headers
    };
    const body = {
    latestVersion: objectToBeSent
    };
    return this.http.patch(Routes.API_URLS.latestBuilds.PATCH_LATEST_BUILDS, body, options);
  }

}

