import { URLConfig } from './../../models/url.model';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter',
  pure: false
})
export class FilterPipe implements PipeTransform {

  transform(urls: URLConfig[], str: string = '') {
    if (urls) {
      return urls.filter(e => e.sourceLink.toLowerCase().indexOf(str.toLowerCase()) > -1 ||
        (e.name && e.name.toLowerCase().indexOf(str.toLowerCase()) > -1));
    }
    return '';
  }

}
