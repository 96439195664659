<div class="admin-top">
    <br />
    <p>{{'home.menu-config.title'|translate}}</p>
    <hr />
</div>
<div class="row">
    <form [formGroup]="form" class="flex-1">
        <div *ngIf="(accountItems ||serviceItems || generalItems)" class="row dropdown-group col-12 offset-lg-2 col-lg-8 col-md-10 offset-md-1">
            <sp-dropdown class="custom-dropdown" [selectedValue]="selectedType" (change)="typeChanged($event.target.value)" [items]='accountTypeList' label="{{'home.menu-config.page-one.filter-field.0'|translate}}">
            </sp-dropdown>
        </div>
        <div *ngIf="(accountItems ||serviceItems || generalItems)" class="row dropdown-group col-12 offset-lg-2 col-lg-8 col-md-10 offset-md-1">
            <sp-dropdown  class="custom-dropdown" [selectedValue]="selectedService" (change)="serviceChanged($event.target.value)" [items]='filteredServiceList'
                label="{{'home.menu-config.page-one.filter-field.1'|translate}}"></sp-dropdown>
        </div>
        <div class="container col-12 offset-lg-2 col-lg-8 col-md-10 offset-md-1">

            <div class="header">
                <div class="icons-group">
                    <button><span class="icon icon-add-or-plus" (click)="addNew()"></span></button>
                    <button [ngClass]="{'opacity':!isEdit||!(accountItems ||serviceItems || generalItems)}" [disabled]="!isEdit||!(accountItems ||serviceItems || generalItems)"
                        (click)="swapWithNextItem()"><span class="icon icon-chevron-down"></span></button>
                    <button [ngClass]="{'opacity':!isEdit||!(accountItems ||serviceItems || generalItems)}" [disabled]="!isEdit||!(accountItems ||serviceItems || generalItems)"
                        (click)="swapWithPreviousItem()"><span class="icon icon-chevron-up"></span></button>
                    <button [ngClass]="{'opacity':!right || !isEdit||!(accountItems ||serviceItems || generalItems)}" [disabled]="!right || !isEdit||!(accountItems ||serviceItems || generalItems)"
                        (click)="makeItemChild()"><span class="icon icon-arrow-right"></span></button>
                    <button [ngClass]="{'opacity':!left || !isEdit||!(accountItems ||serviceItems || generalItems)}" [disabled]="!left || !isEdit||!(accountItems ||serviceItems || generalItems)"
                        (click)="makeItemParent()"><span class="icon icon-arrow-left"></span></button>
                </div>

            </div>
            <div *ngIf="!(accountItems ||serviceItems || generalItems) || (accountItems.length == 0 && serviceItems.length == 0 && generalItems.length == 0)"
                class="empty-body">
                <span>
                    {{'home.menu-config.page-one.info-text'|translate}}
                   </span>
            </div>
            <div *ngIf="accountItems ||serviceItems || generalItems" class="body">
                <div class="orang" *ngIf="accountItems.length>0">
                    <sp-radio-button [selectedItem]="currentItem" [edit]="'true'" [delete]="'true'" [dataSource]="accountItems" [groupName]="'service'" (editClick)="editItem($event)"
                        (radioClick)="radioClick($event)" (deleteClick)="deleteItem($event)">
                    </sp-radio-button>
                    <span>{{'home.menu-config.page-one.services-categories.0'|translate}}</span>
                </div>
                <div class="blue" *ngIf="serviceItems.length>0">
                    <sp-radio-button [selectedItem]="currentItem" [edit]="'true'" [delete]="'true'" [dataSource]="serviceItems" [groupName]="'service'" (editClick)="editItem($event)"
                        (radioClick)="radioClick($event)" (deleteClick)="deleteItem($event)">
                    </sp-radio-button>
                    <span>{{'home.menu-config.page-one.services-categories.1'|translate}}</span>
                </div>
                <div class="green" *ngIf="generalItems.length>0">
                    <sp-radio-button [selectedItem]="currentItem" [edit]="'true'" [delete]="'true'" [dataSource]="generalItems" [groupName]="'service'" (editClick)="editItem($event)"
                        (radioClick)="radioClick($event)" (deleteClick)="deleteItem($event)">
                    </sp-radio-button>
                    <span>{{'home.menu-config.page-one.services-categories.2'|translate}}</span>
                </div>
            </div>
        </div>
    </form>
</div>
<sp-sidemenu-new-item #notification [isEdit]="isEdit" (submitted)="refreshMenu($event)"></sp-sidemenu-new-item>
